import React from 'react';
import PropTypes from 'prop-types';
import { Scrollbars } from 'react-custom-scrollbars';
import Typography from '@material-ui/core/Typography';
import ConfigureMicroservice from './ConfigureMicroservice';

// Component for the third step in client setup
const MicroserviceSelection = props => {
  const {
    handleUpdateMicroservice,
    handleMoveUp,
    handleMoveDown,
    selectedClient,
    minScrollbarSize,
    classes,
  } = props;
  const len = selectedClient.microservices.length;
  return (
    <div className={classes.msList}>
      <div className={classes.msListItem}>
        <div className={classes.titleDiv}><Typography variant='title'>Microservice Offering</Typography></div>
        <div className={classes.indexDiv}><Typography variant='title'>Order</Typography></div>
        <div className={classes.stateDiv}><Typography variant='title'>Status</Typography></div>
      </div>
      <Scrollbars
        autoHeight
        autoHeightMin={`${minScrollbarSize}px`}
        renderThumbVertical={props => <div className={classes.scrollbar}/>}
        renderThumbHorizontal={props => <div className={classes.noScrollbar}/>}
      >
        {selectedClient.microservices.map((microservice, index) => (
          <ConfigureMicroservice
            handleUpdateMicroservice={handleUpdateMicroservice}
            handleMoveUp={handleMoveUp}
            handleMoveDown={handleMoveDown}
            microservice={microservice}
            index={index}
            length={len}
            classes={classes}
          />
        ))}
      </Scrollbars>
    </div>
  );
};

MicroserviceSelection.propTypes = {
  handleUpdateMicroservice: PropTypes.func.isRequired,
  handleMoveUp: PropTypes.func.isRequired,
  handleMoveDown: PropTypes.func.isRequired,
  selectedClient: PropTypes.object.isRequired,
  minScrollbarSize: PropTypes.number.isRequired,
  classes: PropTypes.object.isRequired,
};

export default MicroserviceSelection;