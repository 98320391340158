import React from 'react';
import PropTypes from 'prop-types';
import { Scrollbars } from 'react-custom-scrollbars';
import Paper from '@material-ui/core/Paper';
import Input from '@material-ui/core/Input';
import FormControl from '@material-ui/core/FormControl';
import Typography from '@material-ui/core/Typography';
import InputLabel from '@material-ui/core/InputLabel';
import FormHelperText from '@material-ui/core/FormHelperText';
import ExpansionPanel from '@material-ui/core/ExpansionPanel';
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary';
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails';
import Icon from '@material-ui/core/Icon';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import Button from '@material-ui/core/Button';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import Modal from '@material-ui/core/Modal';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormLabel from '@material-ui/core/FormLabel';
import Tooltip from '@material-ui/core/Tooltip';
import Link from '@material-ui/core/Link';
import { withStyles } from '@material-ui/core/styles';
import firebase from 'firebase/app';
import 'firebase/auth';
import Loading from '../app/components/Loading';
import ErrorPage from '../app/components/ErrorPage';
import LoadingModal from '../app/components/LoadingModal';
import ListText from '../app/components/ListText';

const generateID = () => {
  return (
    String.fromCharCode(65 + Math.floor(Math.random() * 26)) +
    Math.floor(Date.now() * Math.random()).toString(8)
  );
};

const styles = (theme) => ({
  outerDiv: {
    flexGrow: 1,
    display: 'flex',
    justifyContent: 'center',
    marginTop: '25px',
    marginBottom: '25px',
  },
  form: {
    flexGrow: 1,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    alignItems: 'center',
  },
  paper: {
    flexGrow: 1,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    padding: '25px',
    width: '90%',
  },
  settingsContainer: {
    flexGrow: 1,
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-start',
    width: '100%',
    marginBottom: '25px',
  },
  submitDiv: {
    width: '100%',
    height: '36px',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  reorderSubmitDiv: {
    width: '100%',
    height: '36px',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginTop: '25px',
  },
  errorSubmitDiv: {
    width: '100%',
    height: '36px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    marginTop: '25px',
  },
  listDiv: {
    display: 'flex',
    justifyContent: 'flex-start',
    flexDirection: 'column',
    alignItems: 'center',
    paddingRight: '25px',
    height: '100%',
  },
  msOptions: {
    flexGrow: 1,
    display: 'flex',
    justifyContent: 'flex-start',
    flexDirection: 'column',
    alignItems: 'center',
    height: '100%',
    paddingLeft: '25px',
  },
  scrollbarChild: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'flex-start',
    flexGrow: 1,
    height: '100%',
    width: '100%',
  },
  inputRow: {
    width: '100%',
    display: 'flex',
    flexDirection: 'row',
  },
  inputWithHelp: {
    flexGrow: 1,
  },
  helpIcon: {
    alignSelf: 'center',
    color: theme.palette.primary.main,
  },
  msChildList: {
    flexGrow: 1,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    alignItems: 'center',
    width: '80%',
  },
  saveButton: {
    height: '36px',
    background: theme.palette.primary.main,
    color: 'white',
    marginLeft: '25px',
  },
  button: {
    height: '36px',
    background: theme.palette.primary.main,
    color: 'white',
  },
  reorderButton: {
    background: theme.palette.primary.main,
    color: 'white',
    marginLeft: '15px',
  },
  disableButton: {
    background: '#ff9411',
    color: 'white',
    marginRight: '15px',
  },
  deleteButton: {
    background: theme.palette.error.main,
    color: 'white',
    marginRight: '15px',
  },
  removeButton: {
    color: 'black',
    marginLeft: '10px',
    fontSize: '13px',
    background: 'white',
    padding: '0px',
  },
  downButton: {
    color: 'black',
    fontSize: '18px',
    background: 'white',
    padding: '0px',
  },
  upButton: {
    color: 'black',
    marginRight: '10px',
    fontSize: '18px',
    background: 'white',
    padding: '0px',
  },
  onTheLeftButton: {
    height: '36px',
    background: theme.palette.primary.main,
    color: 'white',
    marginLeft: '15px',
  },
  msListItem: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-evenly',
    alignItems: 'center',
    height: '36px',
    width: '100%',
    marginBottom: '5px',
    marginTop: '10px',
  },
  dashboardListItem: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-evenly',
    alignItems: 'center',
    height: '68px',
    width: '100%',
    marginBottom: '1px',
    marginTop: '1px',
  },
  dashboardListItemChild: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    alignItems: 'center',
    width: '100%',
    marginBottom: '16px',
    marginTop: '16px',
  },

  childScrollbarChild: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-end',
    alignItems: 'center',
    flexGrow: 1,
    height: '100%',
    width: '100%',
  },
  dashboardLinkRequired: {
    color: '#f44336',
    fontSize: '14px',
  },
  wideForm: {
    width: '80%',
  },
  addContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-end',
    alignItems: 'center',
    height: '36px',
    width: '100%',
    marginTop: '25px',
  },
  column: {
    maxWidth: '33.33%',
    flexBasis: '33.33%',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'flex-start',
    marginRight: theme.sizes.itemSeparation,
  },
  smallColumn: {
    maxWidth: '28.33%',
    flexBasis: '28.33%',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'flex-start',
    marginRight: theme.sizes.itemSeparation,
  },
  bigColumn: {
    maxWidth: '38.33%',
    flexBasis: '38.33%',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'flex-start',
    marginRight: theme.sizes.itemSeparation,
  },
  expansionPanel: {
    width: '100%',
  },
  iconHome: {
    color: theme.palette.primary.main,
    marginRight: '16px',
  },
  scrollbar: {
    backgroundColor: theme.palette.primary.main,
    opacity: '0.4',
  },
  buttonDiv: {
    flexGrow: 1,
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-end',
    alignItems: 'center',
  },
  reorderModalPaper: {
    position: 'absolute',
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[5],
    padding: theme.spacing.unit * 4,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
  },
  list: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'flex-start',
    height: '100%',
  },
  reorderScrollbarChild: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'flex-start',
    height: '100%',
    marginTop: '15px',
  },
  listItem: {
    height: theme.sizes.listItemHeight,
    width: '95%',
    whiteSpace: 'nowrap',
  },
  titleDiv: {
    display: 'flex',
    justifyContent: 'center',
    flexDirection: 'row',
    alignItems: 'center',
    marginBottom: '15px',
  },
  formControl: {
    display: 'flex',
    flexGrow: 1,
  },
  childFormControl: {
    display: 'flex',
    width: '80%',
  },
  group: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-around',
    flexGrow: 1,
    margin: `${theme.spacing.unit}px`,
  },
  indexDiv: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: '100%',
    width: '140px',
  },
  msConfigButtonsContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    height: '36px',
    width: '80%',
    marginTop: '5px',
  },
  msConfigAddButtonContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-end',
    alignItems: 'center',
    height: '36px',
    width: '80%',
    marginTop: '70px',
  },
  msConfigArrayListContainer: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    alignItems: 'center',
    width: '100%',
    marginBottom: '10px',
    marginTop: '5px',
  },
  msConfigArrayListItem: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    height: '68px',
    width: '90%',
    marginBottom: '1px',
    marginTop: '1px',
  },
});

const MultiDataPoint = (props) => {
  const {
    handleUpdateDataField,
    handleMoveDataPointUp,
    handleMoveDataPointDown,
    handleRemoveDataPoint,
    handleAddDataPoint,
    selectedMicroservice,
    msIndex,
    childIndex,
    dataIndex,
    dataLength,
    formErrors,
    dataPoint,
    classes,
  } = props;
  // console.log('in MultiDataPoint for: ', selectedMicroservice.title);
  // console.log(selectedMicroservice);
  const dataPointFields = Object.keys(dataPoint);
  // console.log('dataPointFields');
  // console.log(dataPointFields);
  return (
    <div className={classes.msConfigArrayListContainer} key={dataPoint.id}>
      <div className={classes.msConfigArrayListItem}>
        {dataPointFields.map((dataPointField, dpIndex) => {
          // console.log('on dataPoint: ', dataPointField);
          if (dataPointField !== 'id') {
            const fieldRef = dataPoint.id + dataPointField;
            return (
              <FormControl
                className={classes.dataPointInput}
                error={!!formErrors[fieldRef]}
                key={fieldRef}
              >
                <InputLabel>{dataPointField}</InputLabel>
                <Input
                  value={selectedMicroservice.data[dataIndex][dataPointField]}
                  onChange={(e) => {
                    handleUpdateDataField(
                      e.target.value,
                      dataPointField,
                      dataIndex,
                      childIndex
                    );
                  }}
                  name={dataPointField}
                  type='text'
                />
                <FormHelperText id={`${fieldRef}-error-text`}>
                  {formErrors[fieldRef]}
                </FormHelperText>
              </FormControl>
            );
          } else {
            return null;
          }
        })}
      </div>
      <div className={classes.msConfigButtonsContainer}>
        <div className={classes.indexDiv}>
          <Button
            onClick={() => handleMoveDataPointUp(msIndex, dataIndex)}
            color='primary'
            className={classes.upButton}
            variant='raised'
            label='↑'
            disabled={dataIndex === 0}
            size='small'
          >
            ↑
          </Button>
          <Button
            onClick={() => handleMoveDataPointDown(msIndex, dataIndex)}
            color='primary'
            className={classes.downButton}
            variant='raised'
            label='↓'
            disabled={dataIndex === dataLength - 1}
            size='small'
          >
            ↓
          </Button>
        </div>
        <Button
          onClick={() => handleRemoveDataPoint(dataIndex, childIndex)}
          color='primary'
          className={classes.onTheLeftButton}
          variant='raised'
          label='Remove'
          size='small'
          disabled={dataLength === 1}
        >
          Remove
        </Button>
      </div>
      {dataIndex === dataLength - 1 && (
        <div className={classes.msConfigAddButtonContainer}>
          <Button
            onClick={() =>
              handleAddDataPoint(
                {
                  ...dataPointFields.reduce((acc, elem) => {
                    acc[elem] = '';
                    return acc;
                  }, {}),
                  id: generateID(),
                },
                msIndex,
                childIndex
              )
            }
            color='primary'
            className={classes.onTheLeftButton}
            variant='raised'
            label='Add'
            size='small'
          >
            Add Another
          </Button>
        </div>
      )}
    </div>
  );
};

class MicroserviceManagement extends React.Component {
  state = {
    userClaims: {},
    microservices: [],
    preReorderMicroservices: [],
    originalMicroservices: [],
    loading: false,
    error: false,
    errorCode: null,
    expanded: false,
    selectedMSIndex: null,
    errorOpen: false,
    reorderOpen: false,
    modalOpen: false,
    modalText: '',
    modalState: 'loading',
    formTouched: [],
    formErrors: [],
    isSubmitting: false,
    isReordered: false,
    isModified: false,
    help: false,
  };

  validate = (field, newValue, childIndex = false) => {
    this.setState((prevState) => {
      const { selectedMSIndex, formErrors } = prevState;
      const newErrors = JSON.parse(JSON.stringify(formErrors));
      // console.log(`In validate for ${field} with value '${newValue}' and child ${childIndex}`);
      if (field === 'title') {
        // console.log('in title');
        if (!newValue && childIndex !== false) {
          // console.log('in child');
          newErrors[selectedMSIndex].children[childIndex].title =
            'Title is required';
        } else if (!newValue) {
          newErrors[selectedMSIndex].title = 'Title is required';
        } else if (newValue.length > 0 && childIndex !== false) {
          // console.log('in child delete');
          newErrors[selectedMSIndex].children[childIndex].title = false;
        } else if (newValue.length > 0) {
          newErrors[selectedMSIndex].title = false;
        }
      } else if (field === 'icon') {
        if (!newValue && childIndex !== false) {
          newErrors[selectedMSIndex].children[childIndex].icon =
            'Icon is required';
        } else if (!newValue) {
          newErrors[selectedMSIndex].icon = 'Icon is required';
        } else if (newValue.length > 0 && childIndex !== false) {
          newErrors[selectedMSIndex].children[childIndex].icon = false;
        } else if (newValue.length > 0) {
          newErrors[selectedMSIndex].icon = false;
        }
      } else if (field === 'path') {
        // console.log('in path');
        if (!newValue && childIndex !== false) {
          // console.log('1');
          newErrors[selectedMSIndex].children[childIndex].path =
            'Path is required';
        } else if (!newValue) {
          // console.log('2');
          newErrors[selectedMSIndex].path = 'Path is required';
        } else if (newValue.substring(0, 1) !== '/' && childIndex !== false) {
          // console.log('3');
          newErrors[selectedMSIndex].children[childIndex].path =
            "Path must start with ' / '";
        } else if (newValue.substring(0, 1) !== '/') {
          // console.log('4');
          newErrors[selectedMSIndex].path = "Path must start with '/'";
        } else if (newValue.length < 2 && childIndex !== false) {
          // console.log('5');
          newErrors[selectedMSIndex].children[childIndex].path =
            'Path must be at least one character';
        } else if (newValue.length < 2) {
          // console.log('6');
          newErrors[selectedMSIndex].path =
            'Path must be at least one character';
        } else if (
          /[^\w/]/.test(newValue.substring(1)) > 0 &&
          childIndex !== false
        ) {
          // console.log('9');
          newErrors[selectedMSIndex].children[childIndex].path =
            'Path should not contain punctuation or symbols';
        } else if (/[^\w/]/.test(newValue.substring(1)) > 0) {
          // console.log('9');
          newErrors[selectedMSIndex].path =
            'Path should not contain punctuation or symbols';
        } else if (newValue.length > 1 && childIndex === false) {
          // console.log('8');
          newErrors[selectedMSIndex].path = false;
        } else if (newValue.length > 1 && childIndex !== false) {
          // console.log('7');
          newErrors[selectedMSIndex].children[childIndex].path = false;
        }
      } else if (field === 'componentName') {
        if (!newValue && childIndex !== false) {
          newErrors[selectedMSIndex].childen[childIndex].componentName =
            'Component Type is required';
        } else if (!newValue) {
          newErrors[selectedMSIndex].componentName =
            'Component Type is required';
        } else if (
          newValue.length > 0 &&
          childIndex !== false &&
          newValue !== 'TableauContainer' &&
          newValue !== 'Presentations'
        ) {
          newErrors[selectedMSIndex].children[childIndex].componentName = false;
          newErrors[selectedMSIndex].children[childIndex].data = false;
        } else if (
          newValue.length > 0 &&
          newValue !== 'TableauContainer' &&
          newValue !== 'Presentations'
        ) {
          newErrors[selectedMSIndex].componentName = false;
          newErrors[selectedMSIndex].data = false;
        } else if (newValue.length > 0 && childIndex !== false) {
          newErrors[selectedMSIndex].children[childIndex].componentName = false;
        } else if (newValue.length > 0) {
          newErrors[selectedMSIndex].componentName = false;
        }
      } /* else if (field === 'data') {
        const newValueLen = Math.min(newValue.length, 37);
        if (childIndex) {
          if (!newValue) {
            newErrors[selectedMSIndex].children[childIndex].data[dataIndex][dataPointField] = newValue;
          } else if (newValue && newValue !== '' && newValue.substring(0, newValueLen) !== 'https://docs.google.com/presentation/'.substring(0, newValueLen)) {
            newErrors[field] = 'Link should start with "https://docs.google.com/presentation/"';
          } else {
            delete newErrors[field];
          }
        } else {
          if (!newValue) {
          
          } else if (newValue && newValue !== '' && newValue.substring(0, newValueLen) !== 'https://docs.google.com/presentation/'.substring(0, newValueLen)) {

          }
        }
      }*/
      // console.log('newErrors');
      // console.log(newErrors);
      return { formErrors: newErrors };
    });
  };

  handleUpdateField = (field, newValue, childIndex = false) => {
    this.setState((prevState) => {
      const { microservices, selectedMSIndex, formTouched } = prevState;
      // console.log(`Updaing index ${selectedMSIndex}'s ${field} to '${newValue}' with child: ${childIndex}`);
      const newMicroservices = JSON.parse(JSON.stringify(microservices));
      const newTouched = JSON.parse(JSON.stringify(formTouched));
      if (childIndex !== false) {
        newTouched[selectedMSIndex].children[childIndex][field] = true;
        const oldValue =
          newMicroservices[selectedMSIndex].children[childIndex][field];
        newMicroservices[selectedMSIndex].children[childIndex][field] =
          newValue;
        if (field === 'componentName') {
          if (
            (newValue === 'DataQualityReport' ||
              newValue === 'Presentations') &&
            typeof newMicroservices[selectedMSIndex].children[childIndex]
              .data === 'string'
          ) {
            // Change data to an array
            newMicroservices[selectedMSIndex].children[childIndex].data = [
              {
                data: newMicroservices[selectedMSIndex].children[childIndex]
                  .data,
                id: generateID(),
                name: '',
              },
            ];
          } else if (
            newValue !== 'DataQualityReport' &&
            newValue !== 'Presentations' &&
            (oldValue === 'DataQualityReport' || oldValue === 'Presentations')
          ) {
            // Change data to a string
            if (
              Array.isArray(
                newMicroservices[selectedMSIndex].children[childIndex].data
              ) &&
              newMicroservices[selectedMSIndex].children[childIndex].data.length
            ) {
              newMicroservices[selectedMSIndex].children[childIndex].data =
                newMicroservices[selectedMSIndex].children[
                  childIndex
                ].data[0].data;
            } else {
              newMicroservices[selectedMSIndex].children[childIndex].data = '';
            }
          }
        }
      } else {
        newTouched[selectedMSIndex][field] = true;
        const oldValue = newMicroservices[selectedMSIndex][field];
        newMicroservices[selectedMSIndex][field] = newValue;
        if (field === 'title') {
          newMicroservices[selectedMSIndex].primary = newValue;
        } else if (field === 'subtitle') {
          newMicroservices[selectedMSIndex].secondary = newValue;
        } else if (field === 'componentName') {
          if (
            (newValue === 'DataQualityReport' ||
              newValue === 'Presentations') &&
            typeof newMicroservices[selectedMSIndex].data === 'string'
          ) {
            // Change data to an array
            newMicroservices[selectedMSIndex].data = [
              {
                data: newMicroservices[selectedMSIndex].data,
                id: generateID(),
                name: '',
              },
            ];
          } else if (
            newValue !== 'DataQualityReport' &&
            newValue !== 'Presentations' &&
            (oldValue === 'DataQualityReport' || oldValue === 'Presentations')
          ) {
            // Change data to a string
            if (
              Array.isArray(newMicroservices[selectedMSIndex].data) &&
              newMicroservices[selectedMSIndex].data.length
            ) {
              newMicroservices[selectedMSIndex].data =
                newMicroservices[selectedMSIndex].data[0].data;
            } else {
              newMicroservices[selectedMSIndex].data = '';
            }
          }
        }
      }
      this.validate(field, newValue, childIndex);
      return { microservices: newMicroservices, formTouched: newTouched };
    });
  };

  handleUpdateDataField = (
    newValue,
    dataPointField,
    dataIndex,
    childIndex = false
  ) => {
    // console.log(`newValue ${newValue}, dataPointField: ${dataPointField}, dataIndex: ${dataIndex}, childIndex: ${childIndex}`);
    this.setState((prevState) => {
      const { microservices, selectedMSIndex, formTouched } = prevState;
      const newMicroservices = JSON.parse(JSON.stringify(microservices));
      const newTouched = JSON.parse(JSON.stringify(formTouched));
      if (childIndex) {
        newMicroservices[selectedMSIndex].children[childIndex].data[dataIndex][
          dataPointField
        ] = newValue;
        newTouched[selectedMSIndex].children[childIndex].data[dataIndex][
          dataPointField
        ] = true;
      } else {
        // console.log(newTouched[selectedMSIndex]);
        newMicroservices[selectedMSIndex].data[dataIndex][dataPointField] =
          newValue;
        newTouched[selectedMSIndex].data[dataIndex][dataPointField] = true;
      }
      // this.validate('data', newValue, childIndex);
      return { microservices: newMicroservices, formTouched: newTouched };
    });
  };

  handleDeleteMicroservice = (index, childIndex = false) => {
    const { microservices, selectedMSIndex } = this.state;
    if (childIndex !== false) {
      const microserviceHasTitle =
        microservices[selectedMSIndex].children[childIndex].title !== '';
      if (
        !microserviceHasTitle ||
        window.confirm(
          `Are you sure you want to delete ${microservices[selectedMSIndex].children[childIndex].title}?`
        )
      ) {
        this.setState((prevState) => {
          const { microservices, formTouched, formErrors } = prevState;
          const newMicroservices = JSON.parse(JSON.stringify(microservices));
          const newTouched = JSON.parse(JSON.stringify(formTouched));
          const newErrors = JSON.parse(JSON.stringify(formErrors));
          newMicroservices[index].children.splice(childIndex, 1);
          newTouched[index].children.splice(childIndex, 1);
          newErrors[index].children.splice(childIndex, 1);
          return {
            microservices: newMicroservices,
            formTouched: newTouched,
            formErrors: newErrors,
            isModified: true,
          };
        });
      }
    } else {
      const microserviceHasTitle = microservices[selectedMSIndex].title !== '';
      if (
        !microserviceHasTitle ||
        window.confirm(
          `Are you sure you want to delete ${microservices[selectedMSIndex].title}?`
        )
      ) {
        this.setState((prevState) => {
          const { microservices, formTouched, formErrors } = prevState;
          const newMicroservices = JSON.parse(JSON.stringify(microservices));
          const newTouched = JSON.parse(JSON.stringify(formTouched));
          const newErrors = JSON.parse(JSON.stringify(formErrors));
          newMicroservices.splice(index, 1);
          newTouched.splice(index, 1);
          newErrors.splice(index, 1);
          return {
            microservices: newMicroservices,
            formTouched: newTouched,
            formErrors: newErrors,
            selectedMSIndex: null,
            isModified: true,
          };
        });
      }
    }
  };

  handleAddChild = () => {
    this.setState((prevState) => {
      const { microservices, selectedMSIndex, formTouched, formErrors } =
        prevState;
      const newMicroservices = JSON.parse(JSON.stringify(microservices));
      const newTouched = JSON.parse(JSON.stringify(formTouched));
      const newErrors = JSON.parse(JSON.stringify(formErrors));
      newMicroservices[selectedMSIndex].children.push({
        title: '',
        subtitle: '',
        path: '',
        icon: '',
        componentName: '',
        data: '',
        isSaved: false,
        disabled: false,
        id: generateID(),
      });
      const touchedMS = {
        componentName: false,
        data: false,
        icon: false,
        path: false,
        title: false,
      };
      const errorMS = {
        componentName: true,
        data: true,
        icon: true,
        path: true,
        title: true,
      };
      newTouched[selectedMSIndex].children.push(touchedMS);
      newErrors[selectedMSIndex].children.push(errorMS);
      return {
        microservices: newMicroservices,
        formTouched: newTouched,
        formErrors: newErrors,
      };
    });
  };

  handleErrorModal = (open) => {
    this.setState({ errorOpen: open });
  };

  handleOpenReorder = () => {
    const { microservices, formTouched, formErrors } = this.state;
    const preReorderMicroservices = JSON.parse(JSON.stringify(microservices));
    const preReorderTouched = JSON.parse(JSON.stringify(formTouched));
    const preReorderErrors = JSON.parse(JSON.stringify(formErrors));
    this.setState({
      reorderOpen: true,
      preReorderMicroservices,
      preReorderTouched,
      preReorderErrors,
    });
  };

  handleCloseReorder = () => {
    const { microservices, originalMicroservices, preReorderMicroservices } =
      this.state;
    // Check if order has changed
    if (
      JSON.stringify(microservices) !==
        JSON.stringify(preReorderMicroservices) &&
      JSON.stringify(microservices) !== JSON.stringify(originalMicroservices)
    ) {
      this.setState({
        reorderOpen: false,
        preReorderMicroservices: [],
        preReorderTouched: [],
        preReorderErrors: [],
        isReordered: true,
      });
    } else {
      this.setState({
        reorderOpen: false,
        preReorderMicroservices: [],
        preReorderTouched: [],
        preReorderErrors: [],
        isReordered: false,
      });
    }
    // const newMicroservices = JSON.parse(JSON.stringify(microservices));
  };

  handleCancelReorder = () => {
    const { preReorderMicroservices } = this.state;
    const microservices = JSON.parse(JSON.stringify(preReorderMicroservices));
    this.setState({
      microservices,
      reorderOpen: false,
      preReorderMicroservices: [],
    });
  };

  handleMoveItem = (index, direction, childIndex = false) => {
    const { microservices, formTouched, formErrors } = this.state;
    // console.log(microservices);
    const newMicroservices = JSON.parse(JSON.stringify(microservices));
    const newTouched = JSON.parse(JSON.stringify(formTouched));
    const newErrors = JSON.parse(JSON.stringify(formErrors));
    const moveUp = (array, shouldMoveChild) => {
      if (shouldMoveChild) {
        const temp = array[index].children[childIndex - 1];
        array[index].children[childIndex - 1] =
          array[index].children[childIndex];
        array[index].children[childIndex] = temp;
      } else {
        const temp = array[index - 1];
        array[index - 1] = array[index];
        array[index] = temp;
      }
    };
    const moveDown = (array, shouldMoveChild) => {
      if (shouldMoveChild) {
        const temp = array[index].children[childIndex + 1];
        array[index].children[childIndex + 1] =
          array[index].children[childIndex];
        array[index].children[childIndex] = temp;
      } else {
        const temp = array[index + 1];
        array[index + 1] = array[index];
        array[index] = temp;
      }
    };
    if (direction === 'up') {
      moveUp(newMicroservices, childIndex !== false);
      moveUp(newTouched, childIndex !== false);
      moveUp(newErrors, childIndex !== false);
    } else if (direction === 'down') {
      moveDown(newMicroservices, childIndex !== false);
      moveDown(newTouched, childIndex !== false);
      moveDown(newErrors, childIndex !== false);
    }
    // console.log(newMicroservices);
    this.setState({
      microservices: newMicroservices,
      formTouched: newTouched,
      formErrors: newErrors,
    });
  };

  handleSetItemPosition = (index, newIndex, childIndex = false) => {
    const { microservices, selectedMSIndex, formTouched, formErrors } =
      this.state;
    // console.log(microservices);
    const newMicroservices = JSON.parse(JSON.stringify(microservices));
    const newTouched = JSON.parse(JSON.stringify(formTouched));
    const newErrors = JSON.parse(JSON.stringify(formErrors));
    const setPosition = (array) => {
      // Copy element to its new position
      array.splice(newIndex, 0, array[index]);
      // Remove elem at index+1 if newIndex < index or index if newIndex > index
      if (newIndex < index) {
        array.splice(index + 1, 1);
      } else {
        array.splice(index, 1);
      }
    };
    if (childIndex) {
      setPosition(newMicroservices[selectedMSIndex].children);
      setPosition(newTouched[selectedMSIndex].children);
      setPosition(newErrors[selectedMSIndex].children);
    } else {
      setPosition(newMicroservices);
      setPosition(newTouched);
      setPosition(newErrors);
    }
    // console.log(newMicroservices);
    this.setState({
      microservices: newMicroservices,
      formTouched: newTouched,
      formErrors: newErrors,
    });
  };

  handleMoveDataPointUp = (index, childIndex = false) => {
    this.setState((prevState) => {
      const { microservices, selectedMSIndex, formTouched, formErrors } =
        prevState;
      const newMicroservices = JSON.parse(JSON.stringify(microservices));
      const newTouched = JSON.parse(JSON.stringify(formTouched));
      const newErrors = JSON.parse(JSON.stringify(formErrors));
      const moveUp = (array) => {
        if (childIndex) {
          const temp =
            array[selectedMSIndex].children[childIndex].data[index - 1];
          array[selectedMSIndex].children[childIndex].data[index - 1] =
            array[selectedMSIndex].children[childIndex].data[index];
          array[selectedMSIndex].children[childIndex].data[index] = temp;
        } else {
          const temp = array[selectedMSIndex].data[index - 1];
          array[selectedMSIndex].data[index - 1] =
            array[selectedMSIndex].data[index];
          array[selectedMSIndex].data[index] = temp;
        }
      };
      moveUp(newMicroservices);
      moveUp(newTouched);
      moveUp(newErrors);
      return {
        microservices: newMicroservices,
        formTouched: newTouched,
        formErrors: newErrors,
      };
    });
  };

  handleMoveDataPointDown = (index, childIndex = false) => {
    this.setState((prevState) => {
      const { microservices, selectedMSIndex, formTouched, formErrors } =
        prevState;
      const newMicroservices = JSON.parse(JSON.stringify(microservices));
      const newTouched = JSON.parse(JSON.stringify(formTouched));
      const newErrors = JSON.parse(JSON.stringify(formErrors));
      const moveDown = (array) => {
        if (childIndex) {
          const temp =
            array[selectedMSIndex].children[childIndex].data[index + 1];
          array[selectedMSIndex].children[childIndex].data[index + 1] =
            array[selectedMSIndex].children[childIndex].data[index];
          array[selectedMSIndex].children[childIndex].data[index] = temp;
        } else {
          const temp = array[selectedMSIndex].data[index + 1];
          array[selectedMSIndex].data[index + 1] =
            array[selectedMSIndex].data[index];
          array[selectedMSIndex].data[index] = temp;
        }
      };
      moveDown(newMicroservices);
      moveDown(newTouched);
      moveDown(newErrors);
      return {
        microservices: newMicroservices,
        formTouched: newTouched,
        formErrors: newErrors,
      };
    });
  };

  handleAddDataPoint = (data, childIndex = false) => {
    this.setState((prevState) => {
      const { microservices, selectedMSIndex, formTouched, formErrors } =
        prevState;
      const newMicroservices = JSON.parse(JSON.stringify(microservices));
      const newTouched = JSON.parse(JSON.stringify(formTouched));
      const newErrors = JSON.parse(JSON.stringify(formErrors));
      // console.log("data");
      // console.log(data);
      const dataCheckerSchema = {
        ...Object.keys(data).reduce((acc, elem) => {
          if (elem !== 'id') {
            acc[elem] = false;
          }
          return acc;
        }, {}),
      };
      // console.log('dataCheckerSchema');
      // console.log(dataCheckerSchema);
      if (childIndex) {
        newMicroservices[selectedMSIndex].childen[childIndex].data.push(data);
        newTouched[selectedMSIndex].childen[childIndex].data.push(
          dataCheckerSchema
        );
        newErrors[selectedMSIndex].childen[childIndex].data.push(
          dataCheckerSchema
        );
      } else {
        newMicroservices[selectedMSIndex].data.push(data);
        newTouched[selectedMSIndex].data.push(dataCheckerSchema);
        newErrors[selectedMSIndex].data.push(dataCheckerSchema);
      }
      return {
        microservices: newMicroservices,
        formTouched: newTouched,
        formErrors: newErrors,
      };
    });
  };

  handleRemoveDataPoint = (dataIndex, childIndex = false) => {
    this.setState((prevState) => {
      const { microservices, selectedMSIndex, formTouched, formErrors } =
        prevState;
      const newMicroservices = JSON.parse(JSON.stringify(microservices));
      const newTouched = JSON.parse(JSON.stringify(formTouched));
      const newErrors = JSON.parse(JSON.stringify(formErrors));
      if (childIndex) {
        newMicroservices[selectedMSIndex].childen[childIndex].data.splice(
          dataIndex,
          1
        );
        newTouched[selectedMSIndex].childen[childIndex].data.splice(
          dataIndex,
          1
        );
        newErrors[selectedMSIndex].childen[childIndex].data.splice(
          dataIndex,
          1
        );
      } else {
        newMicroservices[selectedMSIndex].data.splice(dataIndex, 1);
        newTouched[selectedMSIndex].data.splice(dataIndex, 1);
        newErrors[selectedMSIndex].data.splice(dataIndex, 1);
      }
      return {
        microservices: newMicroservices,
        formTouched: newTouched,
        formErrors: newErrors,
      };
    });
  };

  handleUpdateSelectedMicroservice = (newIndex) => {
    const { microservices, selectedMSIndex, formTouched, formErrors } =
      this.state;
    const newMicroservices = JSON.parse(JSON.stringify(microservices));
    const newTouched = JSON.parse(JSON.stringify(formTouched));
    const newErrors = JSON.parse(JSON.stringify(formErrors));
    // Check if the currently selected microservice is blank, and if so, remove it
    // console.log('in handleUpdateSelectedMicroservice');
    // console.log(selectedMSIndex === 1);
    // console.log(!microservices[1].isSaved);
    // console.log(microservices[1].title === '');
    // console.log(microservices[1].subtitle === '');
    if (
      selectedMSIndex === 1 &&
      !microservices[1].isSaved &&
      microservices[1].title === ''
    ) {
      // console.log('removing index: ', selectedMSIndex);
      // console.log(newMicroservices[selectedMSIndex]);
      newMicroservices.splice(1, 1);
      newTouched.splice(1, 1);
      newErrors.splice(1, 1);
      if (newIndex > 0) {
        newIndex--;
      }
    }
    // Check if the user wants to add a new microservice, if so add one
    if (newIndex === 0) {
      newIndex++;
      const newSelectedMicroservice = {
        title: '',
        subtitle: '',
        path: '',
        icon: '',
        componentName: '',
        children: [],
        isSaved: false,
        disabled: false,
        data: '',
        primary: '',
        secondary: '',
        id: generateID(),
      };
      const touchedMS = {
        componentName: false,
        icon: false,
        path: false,
        title: false,
        data: false,
        children: [],
      };
      const errorMS = {
        componentName: true,
        icon: true,
        path: true,
        title: true,
        data: true,
        children: [],
      };
      newMicroservices.splice(newIndex, 0, newSelectedMicroservice);
      newTouched.splice(newIndex, 0, touchedMS);
      newErrors.splice(newIndex, 0, errorMS);
    }
    this.setState({
      selectedMSIndex: newIndex,
      microservices: newMicroservices,
      formTouched: newTouched,
      formErrors: newErrors,
    });
  };

  handleExpandedChange = (panelIndex) => (event, expanded) => {
    this.setState({ expanded: expanded ? panelIndex : false });
  };

  handleReset = () => {
    const { originalMicroservices, originalFormTouched, originalFormErrors } =
      this.state;
    const microservices = JSON.parse(JSON.stringify(originalMicroservices));
    const formTouched = JSON.parse(JSON.stringify(originalFormTouched));
    const formErrors = JSON.parse(JSON.stringify(originalFormErrors));
    this.setState({
      expanded: false,
      selectedMSIndex: null,
      isReordered: false,
      isModified: false,
      microservices,
      formTouched,
      formErrors,
    });
  };

  handleClose = () => {
    this.setState({ modalOpen: false });
  };

  showHelp = (input) => {
    if (input) {
      this.setState({ help: input });
    } else {
      this.setState({ help: false });
    }
  };

  serverTimeout = (setSubmitting, isSubmitting) => {
    if (isSubmitting) {
      this.requestTimeout = null;
      clearTimeout(this.requestTimeout);
      this.requestTimeout = setTimeout(() => {
        if (isSubmitting && !this.state.error) {
          setSubmitting(false);
          this.setState({
            error: 'The request has timed out.',
            errorCode: 408,
          });
        }
      }, 5000);
    }
  };

  handleError = (response) => {
    if (!response.ok) {
      const errorObj = {
        error: response.statusText,
        errorCode: response.status,
      };
      throw errorObj;
    }
    return response;
  };

  handleSubmit = () => {
    // console.log('in HS');
    // Fetch the user's claims from Firebase
    firebase
      .auth()
      .currentUser.getIdTokenResult(true)
      .then((idTokenResult) => {
        const userClaims = idTokenResult.claims;
        // Check if user has permission to use this admin microservice
        if (userClaims.microserviceManagement) {
          if (
            window.confirm(
              'Your current config will go live! Are you sure you want to proceed?'
            )
          ) {
            this.setState({
              error: false,
              errorCode: null,
              modalState: 'loading',
              modalOpen: true,
              modalText: 'Updating microservices...',
              isSubmitting: true,
            });
            // console.log('if passed');
            const { microservices, selectedMSIndex } = this.state;
            const newMicroservices = JSON.parse(JSON.stringify(microservices));
            newMicroservices.splice(0, 1);
            // console.log(selectedMSIndex === 1);
            // console.log(!newMicroservices[1].isSaved);
            // console.log(newMicroservices[1].title === '');
            if (
              selectedMSIndex === 1 &&
              !newMicroservices[0].isSaved &&
              newMicroservices[0].title === ''
            ) {
              // console.log('removing index: ', selectedMSIndex);
              // console.log(newMicroservices[selectedMSIndex]);
              newMicroservices.splice(0, 1);
            }
            const processedMicroservices = newMicroservices.map((ms) => ({
              componentName: ms.componentName,
              data: ms.data,
              disabled: ms.disabled,
              icon: ms.icon,
              id: ms.id,
              path: ms.path,
              subtitle: ms.subtitle,
              title: ms.title,
              children: ms.children.map((child) => ({
                componentName: child.componentName,
                data: child.data,
                disabled: child.disabled,
                icon: child.icon,
                id: child.id,
                path: child.path,
                subtitle: child.subtitle,
                title: child.title,
              })),
            }));
            // console.log(processedMicroservices);
            this.props.appContext.firestore
              .collection('settings')
              .doc('publicMicroservices')
              .update({ microservices: processedMicroservices })
              .then(() => {
                this.props.appContext.updatePublicMicroservices();
                const saveMicroservices = JSON.parse(
                  JSON.stringify(microservices)
                );
                // Change the `isSaved` status of the new microservice
                if (
                  selectedMSIndex &&
                  saveMicroservices[selectedMSIndex].isSaved === false
                ) {
                  saveMicroservices[selectedMSIndex].isSaved = true;
                  if (saveMicroservices[selectedMSIndex].children.length) {
                    saveMicroservices[selectedMSIndex].children.forEach(
                      (child) => {
                        child.isSaved = true;
                      }
                    );
                  }
                }
                this.setState({
                  modalState: 'success',
                  modalOpen: true,
                  modalText: 'Microservices successfully updated!',
                  microservices: saveMicroservices,
                  originalMicroservices: JSON.parse(
                    JSON.stringify(saveMicroservices)
                  ),
                  expanded: false,
                  childExpanded: false,
                  isSubmitting: false,
                  isModified: false,
                });
              })
              .catch((error) => {
                this.setState({
                  modalState: 'failure',
                  modalOpen: true,
                  modalText: `Unable to update microservices: ${error}`,
                  isSubmitting: false,
                });
              });
          }
        } else {
          // console.log('in else');
          this.setState({
            modalState: 'failure',
            modalOpen: true,
            modalText: `Your permissions have been changed, please refresh the page`,
            isSubmitting: false,
          });
        }
        // console.log('done');
      });
  };

  componentDidMount() {
    // Fetch the user's claims from Firebase
    firebase
      .auth()
      .currentUser.getIdTokenResult(true)
      .then((idTokenResult) => {
        const userClaims = idTokenResult.claims;
        // Check if user has permission to use this admin microservice
        if (!userClaims.microserviceManagement) return;

        this.props.appContext.firestore
          .collection('settings')
          .doc('publicMicroservices')
          .onSnapshot((publicMicroservicesSnapshot) => {
            const getChildren = (children) =>
              children.map((child) => ({
                ...child,
                isSaved: true,
              }));
            const msChecker = {
              componentName: false,
              data: false,
              icon: false,
              path: false,
              title: false,
            };
            // console.log(this.props.appContext.appMicroservices);
            const microservices = publicMicroservicesSnapshot
              .get('microservices')
              .map((ms) => ({
                ...ms,
                children: getChildren(ms.children),
                isSaved: true,
                primary: ms.title,
                secondary: ms.subtitle,
              }));
            microservices.unshift({
              title: 'New Microservice',
              subtitle: '',
              path: '/',
              icon: 'add',
              componentName: '',
              children: [],
              isSaved: false,
              disabled: false,
              data: '',
              primary: 'New Microservice',
              secondary: '',
              id: generateID(),
            });
            // Creates a representation of the microservcices that can be used by the error and touched checkers
            const checkerSchema = microservices.map((ms) => {
              if (Array.isArray(ms.data)) {
                return {
                  ...msChecker,
                  // data
                  data: [
                    {
                      ...Object.keys(ms.data).reduce((acc, elem) => {
                        acc[elem] = false;
                        return acc;
                      }, {}),
                    },
                  ],
                  children: ms.children.map((child) => {
                    if (Array.isArray(child.data)) {
                      return {
                        ...msChecker,
                        data: [
                          {
                            ...Object.keys(child.data).reduce(
                              (childAcc, childElem) => {
                                childAcc[childElem] = false;
                                return childAcc;
                              },
                              {}
                            ),
                          },
                        ],
                      };
                    } else {
                      return { ...msChecker };
                    }
                  }),
                };
              } else {
                return {
                  ...msChecker,
                  children: ms.children.map((child) => {
                    if (Array.isArray(child.data)) {
                      return {
                        ...msChecker,
                        data: [
                          {
                            ...Object.keys(child.data).reduce(
                              (childAcc, childElem) => {
                                childAcc[childElem] = false;
                                return childAcc;
                              },
                              {}
                            ),
                          },
                        ],
                      };
                    } else {
                      return { ...msChecker };
                    }
                  }),
                };
              }
            });
            // console.log('checkerSchema');
            // console.log(checkerSchema);
            const formTouched = JSON.parse(JSON.stringify(checkerSchema));
            const formErrors = JSON.parse(JSON.stringify(checkerSchema));
            const originalMicroservices = JSON.parse(
              JSON.stringify(microservices)
            );
            const originalFormTouched = JSON.parse(JSON.stringify(formTouched));
            const originalFormErrors = JSON.parse(JSON.stringify(formErrors));
            this.setState({
              userClaims,
              microservices,
              formTouched,
              formErrors,
              originalMicroservices,
              originalFormTouched,
              originalFormErrors,
            });
          });
      });
  }

  render() {
    const { classes, appContext } = this.props;
    const dimensions = appContext.dimensions;
    const {
      microservices,
      errorCode,
      error,
      loading,
      expanded,
      modalOpen,
      modalText,
      modalState,
      selectedMSIndex,
      userClaims,
      reorderOpen,
      errorOpen,
      formTouched,
      formErrors,
      isSubmitting,
      isReordered,
      isModified,
      help,
    } = this.state;

    const totalWidth = this.props.appContext.dimensions.width;
    const totalHeight = this.props.appContext.dimensions.height;
    const adjustWidth = this.props.appContext.drawerOpen
      ? this.props.appContext.dimensions.openSideNav
      : this.props.appContext.dimensions.collapsedSideNav;

    const getModalStyle = () => {
      const adjustLeft = (adjustWidth / totalWidth) * 50; // multiply by 50 (not 100) to get half of the adjust value
      const adjustTop = (0 / totalHeight) * 50;
      const top = 50 + adjustTop;
      const left = 50 + adjustLeft;
      return {
        top: `${top}%`,
        left: `${left}%`,
        transform: `translate(-${top}%, -${left}%)`,
      };
    };

    /*outer div margin top 25px
    outer div margin bottom 25px
    settingsPaperContainer padding top 25px
    settingsPaperContainer padding bottom 25px
    heading & margin 24px + 15px
    Submit Div margin 25px
    Submit Div 36px*/
    const availableScrollbarSize =
      dimensions.height -
      (dimensions.topPanel + 25 + 25 + 25 + 25 + 24 + 15 + 25 + 36);

    if (!loading && microservices.length) {
      // console.log('selectedMSIndex: ', selectedMSIndex);
      // console.log('microservices:');
      // console.log(microservices);

      const selectedMicroservice = microservices[selectedMSIndex];
      let errorsExist = false;
      // console.log('selectedMicroservice:');
      // console.log(selectedMicroservice);
      // console.log('formTouched:');
      // console.log(formTouched);
      // console.log('formErrors:');
      // console.log(formErrors);

      if (selectedMicroservice) {
        errorsExist = Object.keys(formErrors[selectedMSIndex])
          .map((elem) => {
            // console.log('on elem: ', elem);
            // console.log('formErrors[selectedMSIndex][elem] elem value: ', formErrors[selectedMSIndex][elem]);
            if (
              elem === 'children' &&
              formErrors[selectedMSIndex].children.length
            ) {
              const childErrors = formErrors[selectedMSIndex].children
                .map((child) => {
                  return Object.keys(child)
                    .map((childElem) => {
                      if (child[childElem] !== false) {
                        return true;
                      } else {
                        return false;
                      }
                    })
                    .reduce((childElem, acc) => {
                      return childElem || acc;
                    }, false);
                })
                .reduce((child, acc) => {
                  return child || acc;
                }, false);
              // console.log('childErrors: ', childErrors);
              return childErrors;
            } else if (
              elem !== 'children' &&
              elem !== 'data' &&
              formErrors[selectedMSIndex][elem] !== false
            ) {
              // console.log('returning true');
              return true;
            } else {
              // console.log('returning false');
              return false;
            }
          })
          .reduce((elem, acc) => {
            return elem || acc;
          }, false);
      }
      // console.log('errorsExist: ', errorsExist);
      // console.log(`Testing isSubmitting: ${isSubmitting} errorsExist: ${errorsExist} !isReordered && selectedMSIndex === null: ${!isReordered && selectedMSIndex === null}`)
      // console.log('isSubmitting');
      // console.log(isSubmitting);
      // console.log('errorsExist');
      // console.log(errorsExist);
      // console.log('(!isModified && !isReordered && selectedMSIndex === null)');
      // console.log((!isModified && !isReordered && selectedMSIndex === null));

      return (
        <div className={classes.outerDiv}>
          <form className={classes.form}>
            <Paper className={classes.paper}>
              <div className={classes.settingsContainer}>
                <div className={classes.listDiv}>
                  <ListText
                    items={microservices}
                    selectedIndex={selectedMSIndex}
                    title='Select Microservice'
                    onListItemSelect={(index) =>
                      this.handleUpdateSelectedMicroservice(index)
                    }
                    dimensions={appContext.dimensions}
                    maxHeight={availableScrollbarSize}
                    disableGutters
                  />
                </div>
                {selectedMicroservice && (
                  <div className={classes.msOptions}>
                    <div className={classes.titleDiv}>
                      <Typography variant='subheading'>
                        {selectedMicroservice.isSaved
                          ? `Update ${selectedMicroservice.title}`
                          : 'New Microservice'}
                      </Typography>
                    </div>
                    <Scrollbars
                      autoHeight
                      autoHeightMin={`${availableScrollbarSize}px`}
                      renderThumbVertical={(props) => (
                        <div className={classes.scrollbar} />
                      )}
                      renderThumbHorizontal={(props) => (
                        <div className={classes.noScrollbar} />
                      )}
                      autoHide
                      autoHideTimeout={500}
                      autoHideDuration={300}
                    >
                      <div className={classes.scrollbarChild}>
                        <FormControl
                          fullWidth
                          error={
                            formTouched[selectedMSIndex].title &&
                            !!formErrors[selectedMSIndex].title
                          }
                        >
                          <div className={classes.inputRow}>
                            <InputLabel htmlFor='title'>Title</InputLabel>
                            <Input
                              className={classes.inputWithHelp}
                              value={selectedMicroservice.title}
                              onChange={(e) => {
                                this.handleUpdateField('title', e.target.value);
                              }}
                              name='title'
                              label='Title'
                              type='text'
                            />
                            <Tooltip
                              onClose={(event) => this.showHelp(false)}
                              onOpen={(event) => this.showHelp('title')}
                              open={help === 'title'}
                              title='The name of the microservice'
                            >
                              <Icon classes={{ root: classes.helpIcon }}>
                                help
                              </Icon>
                            </Tooltip>
                          </div>
                          <FormHelperText id='title-error-text'>
                            {formTouched[selectedMSIndex].title &&
                              formErrors[selectedMSIndex].title}
                          </FormHelperText>
                        </FormControl>
                        <FormControl
                          fullWidth
                          error={
                            formTouched[selectedMSIndex].subtitle &&
                            !!formErrors[selectedMSIndex].subtitle
                          }
                        >
                          <div className={classes.inputRow}>
                            <InputLabel htmlFor='subtitle'>Subtitle</InputLabel>
                            <Input
                              className={classes.inputWithHelp}
                              value={selectedMicroservice.subtitle}
                              onChange={(e) => {
                                this.handleUpdateField(
                                  'subtitle',
                                  e.target.value
                                );
                              }}
                              name='subtitle'
                              label='Subitle'
                              type='text'
                            />
                            <Tooltip
                              onClose={(event) => this.showHelp(false)}
                              onOpen={(event) => this.showHelp('subtitle')}
                              open={help === 'subtitle'}
                              title='Only displayed when the microservice is disabled. Suggested: "Under Maintenance"'
                            >
                              <Icon classes={{ root: classes.helpIcon }}>
                                help
                              </Icon>
                            </Tooltip>
                          </div>
                          <FormHelperText id='subtitle-error-text'>
                            {formTouched[selectedMSIndex].subtitle &&
                              formErrors[selectedMSIndex].subtitle}
                          </FormHelperText>
                        </FormControl>
                        <FormControl
                          fullWidth
                          error={
                            formTouched[selectedMSIndex].icon &&
                            !!formErrors[selectedMSIndex].icon
                          }
                        >
                          <div className={classes.inputRow}>
                            <InputLabel htmlFor='icon'>Icon</InputLabel>
                            <Input
                              className={classes.inputWithHelp}
                              value={selectedMicroservice.icon}
                              onChange={(e) => {
                                this.handleUpdateField('icon', e.target.value);
                              }}
                              name='icon'
                              label='Icon'
                              type='text'
                            />
                            <Tooltip
                              onClose={(event) => this.showHelp(false)}
                              onOpen={(event) => this.showHelp('icon')}
                              open={help === 'icon'}
                              title='Click to view available icons, type the name of the selected one here.'
                            >
                              <Link
                                target='_blank'
                                className={classes.helpIcon}
                                href='https://material.io/tools/icons'
                              >
                                <Icon classes={{ root: classes.helpIcon }}>
                                  help
                                </Icon>
                              </Link>
                            </Tooltip>
                          </div>
                          <FormHelperText id='icon-error-text'>
                            {formTouched[selectedMSIndex].icon &&
                              formErrors[selectedMSIndex].icon}
                          </FormHelperText>
                        </FormControl>
                        <FormControl
                          fullWidth
                          error={
                            formTouched[selectedMSIndex].path &&
                            !!formErrors[selectedMSIndex].path
                          }
                        >
                          <div className={classes.inputRow}>
                            <InputLabel htmlFor='path'>Path</InputLabel>
                            <Input
                              className={classes.inputWithHelp}
                              value={selectedMicroservice.path}
                              onChange={(e) => {
                                this.handleUpdateField('path', e.target.value);
                              }}
                              name='path'
                              label='Path'
                              type='text'
                            />
                            <Tooltip
                              onClose={(event) => this.showHelp(false)}
                              onOpen={(event) => this.showHelp('path')}
                              open={help === 'path'}
                              title='The URL displayed when the user visits the microservice'
                            >
                              <Link
                                className={classes.helpIcon}
                                href='https://material.io/tools/icons'
                              >
                                <Icon classes={{ root: classes.helpIcon }}>
                                  help
                                </Icon>
                              </Link>
                            </Tooltip>
                          </div>
                          <FormHelperText id='path-error-text'>
                            {formTouched[selectedMSIndex].path &&
                              formErrors[selectedMSIndex].path}
                          </FormHelperText>
                        </FormControl>
                        {userClaims.allowDisable && (
                          <div className={classes.inputRow}>
                            <FormControl
                              component='fieldset'
                              className={classes.formControl}
                            >
                              <FormLabel component='legend'>
                                Microservice State
                              </FormLabel>
                              <RadioGroup
                                aria-label='disabled'
                                name='disabled'
                                className={classes.group}
                                value={selectedMicroservice.disabled}
                                onChange={() => {
                                  this.handleUpdateField(
                                    'disabled',
                                    !selectedMicroservice.disabled
                                  );
                                }}
                              >
                                <FormControlLabel
                                  value={false}
                                  control={<Radio />}
                                  label='Enabled'
                                />
                                <FormControlLabel
                                  value={true}
                                  control={<Radio />}
                                  label='Disabled'
                                />
                              </RadioGroup>
                            </FormControl>
                            <Tooltip
                              onClose={(event) => this.showHelp(false)}
                              onOpen={(event) => this.showHelp('state')}
                              open={help === 'state'}
                              title='Disable to prevent access'
                            >
                              <Icon classes={{ root: classes.helpIcon }}>
                                help
                              </Icon>
                            </Tooltip>
                          </div>
                        )}
                        <FormControl
                          fullWidth
                          error={
                            formTouched[selectedMSIndex].componentName &&
                            !!formErrors[selectedMSIndex].componentName
                          }
                        >
                          <div className={classes.inputRow}>
                            <InputLabel htmlFor='componentName'>
                              Microservice Type
                            </InputLabel>
                            <Select
                              className={classes.inputWithHelp}
                              value={selectedMicroservice.componentName}
                              onChange={(e) => {
                                this.handleUpdateField(
                                  'componentName',
                                  e.target.value
                                );
                              }}
                              inputProps={{
                                name: 'componentName',
                                id: 'componentName-select',
                              }}
                            >
                              <MenuItem value={''}>Select Type</MenuItem>
                              <MenuItem value={'LinkCardSelector'}>
                                Parent
                              </MenuItem>
                              <MenuItem value={'TableauContainer'}>
                                Tableau Dashboard
                              </MenuItem>
                              <MenuItem value={'PowerBiContainer'}>
                                PowerBI Dashboard
                              </MenuItem>
                              <MenuItem value={'DownloadData'}>
                                Download Data
                              </MenuItem>
                              <MenuItem value={'DataQualityReport'}>
                                Data Quality Report
                              </MenuItem>
                              <MenuItem value={'Presentations'}>
                                Presentations
                              </MenuItem>
                              <MenuItem value={'Embedded'}>Embedded</MenuItem>
                              <MenuItem value={'UploadObject'}>
                                GCS Object Upload
                              </MenuItem>
                              <MenuItem value={'ProjectTracker'}>
                                Project Tracker
                              </MenuItem>
                              <MenuItem value={'FormSubmissionViewer'}>
                                Form Submission Viewer
                              </MenuItem>
                              <MenuItem value={'FormBuilder'}>
                                Form Builder
                              </MenuItem>
                              <MenuItem value={'Demo'}>Demo</MenuItem>
                            </Select>
                            <Tooltip
                              onClose={(event) => this.showHelp(false)}
                              onOpen={(event) => this.showHelp('type')}
                              open={help === 'type'}
                              title='The base component for the microservice. Select "Parent" to create another layer of microservices below this one.'
                            >
                              <Icon classes={{ root: classes.helpIcon }}>
                                help
                              </Icon>
                            </Tooltip>
                          </div>
                          <FormHelperText id='componentName-error-text'>
                            {formTouched[selectedMSIndex].componentName &&
                              formErrors[selectedMSIndex].componentName}
                          </FormHelperText>
                        </FormControl>
                        {selectedMicroservice.componentName ===
                          'LinkCardSelector' && (
                          <div className={classes.dashboardListItemChild}>
                            <Typography>Child Configuration</Typography>
                            <React.Fragment>
                              {selectedMicroservice.children.length > 0 &&
                                selectedMicroservice.children.map(
                                  (child, childIndex) => {
                                    // console.log('childIndex: ', childIndex);
                                    // console.log('chilodren len:', selectedMicroservice.children.length);
                                    const msChildHasError =
                                      !!formErrors[selectedMSIndex].children[
                                        childIndex
                                      ].title ||
                                      !!formErrors[selectedMSIndex].children[
                                        childIndex
                                      ].icon ||
                                      !!formErrors[selectedMSIndex].children[
                                        childIndex
                                      ].path ||
                                      !!formErrors[selectedMSIndex].children[
                                        childIndex
                                      ].componentName;
                                    return (
                                      <ExpansionPanel
                                        expanded={expanded === childIndex}
                                        onChange={this.handleExpandedChange(
                                          childIndex
                                        )}
                                        className={classes.expansionPanel}
                                      >
                                        <ExpansionPanelSummary
                                          expandIcon={
                                            <Icon color='primary'>
                                              expand_more
                                            </Icon>
                                          }
                                        >
                                          <div className={classes.column}>
                                            <Icon
                                              classes={{
                                                root: classes.iconHome,
                                              }}
                                            >
                                              {child.icon}
                                            </Icon>
                                            <Typography>
                                              {child.title}
                                            </Typography>
                                          </div>
                                          <div className={classes.smallColumn}>
                                            {msChildHasError && (
                                              <Typography
                                                className={
                                                  classes.dashboardLinkRequired
                                                }
                                              >
                                                Child Details Required
                                              </Typography>
                                            )}
                                          </div>
                                          <div className={classes.bigColumn}>
                                            <div className={classes.buttonDiv}>
                                              <Button
                                                color='primary'
                                                onClick={(event) => {
                                                  this.handleMoveItem(
                                                    selectedMSIndex,
                                                    'up',
                                                    childIndex
                                                  );
                                                  event.stopPropagation();
                                                }}
                                                className={classes.upButton}
                                                variant='raised'
                                                label='↑'
                                                disabled={childIndex === 0}
                                                size='small'
                                              >
                                                ↑
                                              </Button>
                                              <Button
                                                color='primary'
                                                onClick={(event) => {
                                                  this.handleMoveItem(
                                                    selectedMSIndex,
                                                    'down',
                                                    childIndex
                                                  );
                                                  event.stopPropagation();
                                                }}
                                                className={classes.downButton}
                                                variant='raised'
                                                label='↓'
                                                disabled={
                                                  childIndex ===
                                                  selectedMicroservice.children
                                                    .length -
                                                    1
                                                }
                                                size='small'
                                              >
                                                ↓
                                              </Button>
                                              {!child.isSaved && (
                                                <Button
                                                  color='primary'
                                                  onClick={(event) => {
                                                    this.handleDeleteMicroservice(
                                                      selectedMSIndex,
                                                      childIndex
                                                    );
                                                    event.stopPropagation();
                                                  }}
                                                  className={
                                                    classes.removeButton
                                                  }
                                                  variant='raised'
                                                  label='remove'
                                                  size='small'
                                                >
                                                  Remove
                                                </Button>
                                              )}
                                            </div>
                                          </div>
                                        </ExpansionPanelSummary>
                                        <ExpansionPanelDetails>
                                          <div className={classes.msChildList}>
                                            <div
                                              className={
                                                classes.dashboardListItem
                                              }
                                            >
                                              <FormControl
                                                className={classes.wideForm}
                                                error={
                                                  formTouched[selectedMSIndex]
                                                    .children[childIndex]
                                                    .title &&
                                                  !!formErrors[selectedMSIndex]
                                                    .children[childIndex].title
                                                }
                                              >
                                                <InputLabel htmlFor='childTitle'>
                                                  Title
                                                </InputLabel>
                                                <Input
                                                  value={
                                                    selectedMicroservice
                                                      .children[childIndex]
                                                      .title
                                                  }
                                                  onChange={(e) => {
                                                    this.handleUpdateField(
                                                      'title',
                                                      e.target.value,
                                                      childIndex
                                                    );
                                                  }}
                                                  name='title'
                                                  label='Title'
                                                  type='text'
                                                />
                                                <FormHelperText id='childTitle-error-text'>
                                                  {formTouched[selectedMSIndex]
                                                    .children[childIndex]
                                                    .title &&
                                                    formErrors[selectedMSIndex]
                                                      .children[childIndex]
                                                      .title}
                                                </FormHelperText>
                                              </FormControl>
                                            </div>
                                            <div
                                              className={
                                                classes.dashboardListItem
                                              }
                                            >
                                              <FormControl
                                                className={classes.wideForm}
                                                error={
                                                  !!formErrors[selectedMSIndex]
                                                    .children[childIndex]
                                                    .subtitle
                                                }
                                              >
                                                <InputLabel htmlFor='childSubtitle'>
                                                  Subtitle
                                                </InputLabel>
                                                <Input
                                                  value={
                                                    selectedMicroservice
                                                      .children[childIndex]
                                                      .subtitle
                                                  }
                                                  onChange={(e) => {
                                                    this.handleUpdateField(
                                                      'subtitle',
                                                      e.target.value,
                                                      childIndex
                                                    );
                                                  }}
                                                  name='subtitle'
                                                  label='Title'
                                                  type='text'
                                                />
                                                <FormHelperText id='childSubtitle-error-text'>
                                                  {
                                                    formErrors[selectedMSIndex]
                                                      .children[childIndex]
                                                      .subtitle
                                                  }
                                                </FormHelperText>
                                              </FormControl>
                                            </div>
                                            <div
                                              className={
                                                classes.dashboardListItem
                                              }
                                            >
                                              <FormControl
                                                className={classes.wideForm}
                                                error={
                                                  formTouched[selectedMSIndex]
                                                    .children[childIndex]
                                                    .icon &&
                                                  !!formErrors[selectedMSIndex]
                                                    .children[childIndex].icon
                                                }
                                              >
                                                <InputLabel htmlFor='childIcon'>
                                                  Icon
                                                </InputLabel>
                                                <Input
                                                  value={
                                                    selectedMicroservice
                                                      .children[childIndex].icon
                                                  }
                                                  onChange={(e) => {
                                                    this.handleUpdateField(
                                                      'icon',
                                                      e.target.value,
                                                      childIndex
                                                    );
                                                  }}
                                                  name='icon'
                                                  label='Title'
                                                />
                                                <FormHelperText id='childIcon-error-text'>
                                                  {formTouched[selectedMSIndex]
                                                    .children[childIndex]
                                                    .icon &&
                                                    formErrors[selectedMSIndex]
                                                      .children[childIndex]
                                                      .icon}
                                                </FormHelperText>
                                              </FormControl>
                                            </div>
                                            <div
                                              className={
                                                classes.dashboardListItem
                                              }
                                            >
                                              <FormControl
                                                className={classes.wideForm}
                                                error={
                                                  formTouched[selectedMSIndex]
                                                    .children[childIndex]
                                                    .path &&
                                                  !!formErrors[selectedMSIndex]
                                                    .children[childIndex].path
                                                }
                                              >
                                                <InputLabel htmlFor='childPath'>
                                                  Path
                                                </InputLabel>
                                                <Input
                                                  value={
                                                    selectedMicroservice
                                                      .children[childIndex].path
                                                  }
                                                  onChange={(e) => {
                                                    this.handleUpdateField(
                                                      'path',
                                                      e.target.value,
                                                      childIndex
                                                    );
                                                  }}
                                                  name='path'
                                                  label='Title'
                                                  type='text'
                                                />
                                                <FormHelperText id='childPath-error-text'>
                                                  {formTouched[selectedMSIndex]
                                                    .children[childIndex]
                                                    .path &&
                                                    formErrors[selectedMSIndex]
                                                      .children[childIndex]
                                                      .path}
                                                </FormHelperText>
                                              </FormControl>
                                            </div>
                                            {userClaims.allowDisable && (
                                              <div
                                                className={
                                                  classes.dashboardListItem
                                                }
                                              >
                                                <FormControl
                                                  component='fieldset'
                                                  className={
                                                    classes.childFormControl
                                                  }
                                                >
                                                  <FormLabel component='legend'>
                                                    Microservice State
                                                  </FormLabel>
                                                  <RadioGroup
                                                    aria-label='childDisabled'
                                                    name='childDisabled'
                                                    className={classes.group}
                                                    value={
                                                      selectedMicroservice
                                                        .children[childIndex]
                                                        .disabled
                                                    }
                                                    onChange={() => {
                                                      this.handleUpdateField(
                                                        'disabled',
                                                        !selectedMicroservice
                                                          .children[childIndex]
                                                          .disabled,
                                                        childIndex
                                                      );
                                                    }}
                                                  >
                                                    <FormControlLabel
                                                      value={false}
                                                      control={<Radio />}
                                                      label='Enabled'
                                                    />
                                                    <FormControlLabel
                                                      value={true}
                                                      control={<Radio />}
                                                      label='Disabled'
                                                    />
                                                  </RadioGroup>
                                                </FormControl>
                                              </div>
                                            )}
                                            <div
                                              className={
                                                classes.dashboardListItem
                                              }
                                            >
                                              <FormControl
                                                className={classes.wideForm}
                                                error={
                                                  formTouched[selectedMSIndex]
                                                    .children[childIndex]
                                                    .componentName &&
                                                  !!formErrors[selectedMSIndex]
                                                    .children[childIndex]
                                                    .componentName
                                                }
                                              >
                                                <InputLabel htmlFor='childComponentName'>
                                                  Microservice Type
                                                </InputLabel>
                                                <Select
                                                  value={
                                                    selectedMicroservice
                                                      .children[childIndex]
                                                      .componentName
                                                  }
                                                  onChange={(e) => {
                                                    this.handleUpdateField(
                                                      'componentName',
                                                      e.target.value,
                                                      childIndex
                                                    );
                                                  }}
                                                  inputProps={{
                                                    name: 'childComponentName',
                                                    id: 'childComponentName-select',
                                                  }}
                                                >
                                                  <MenuItem value={''}>
                                                    Select Type
                                                  </MenuItem>
                                                  <MenuItem
                                                    value={'TableauContainer'}
                                                  >
                                                    Tableau Dashboard
                                                  </MenuItem>
                                                  <MenuItem
                                                    value={'PowerBiContainer'}
                                                  >
                                                    PowerBi Dashboard
                                                  </MenuItem>
                                                  <MenuItem
                                                    value={'DownloadData'}
                                                  >
                                                    Download Data
                                                  </MenuItem>
                                                  <MenuItem
                                                    value={'DataQualityReport'}
                                                  >
                                                    Data Quality Report
                                                  </MenuItem>
                                                  <MenuItem
                                                    value={'Presentations'}
                                                  >
                                                    Presentations
                                                  </MenuItem>
                                                  <MenuItem value={'Embedded'}>
                                                    Embedded
                                                  </MenuItem>
                                                  <MenuItem
                                                    value={'UploadObject'}
                                                  >
                                                    GCS Object Upload
                                                  </MenuItem>
                                                  <MenuItem value={'Demo'}>
                                                    Demo
                                                  </MenuItem>
                                                </Select>
                                                <FormHelperText
                                                  id={
                                                    'childComponentName-error-text'
                                                  }
                                                >
                                                  {formTouched[selectedMSIndex]
                                                    .children[childIndex]
                                                    .componentName &&
                                                    formErrors[selectedMSIndex]
                                                      .children[childIndex]
                                                      .componentName}
                                                </FormHelperText>
                                              </FormControl>
                                            </div>

                                            {selectedMicroservice.children[
                                              childIndex
                                            ].componentName !==
                                              'LinkCardSelector' &&
                                              selectedMicroservice.children[
                                                childIndex
                                              ].componentName !== '' && (
                                                <div
                                                  className={
                                                    classes.dashboardListItemChild
                                                  }
                                                >
                                                  <div
                                                    className={
                                                      classes.dashboardListItem
                                                    }
                                                  >
                                                    <FormControl
                                                      className={
                                                        classes.wideForm
                                                      }
                                                      error={
                                                        formTouched[
                                                          selectedMSIndex
                                                        ].children[childIndex]
                                                          .data &&
                                                        !!formErrors[
                                                          selectedMSIndex
                                                        ].children[childIndex]
                                                          .data
                                                      }
                                                    >
                                                      <InputLabel htmlFor='childTitle'>
                                                        Global Data
                                                      </InputLabel>
                                                      <Input
                                                        value={
                                                          selectedMicroservice
                                                            .children[
                                                            childIndex
                                                          ].data
                                                        }
                                                        onChange={(e) => {
                                                          this.handleUpdateField(
                                                            'data',
                                                            e.target.value,
                                                            childIndex
                                                          );
                                                        }}
                                                        name='data'
                                                        label='Global Data'
                                                        type='text'
                                                      />
                                                      <FormHelperText id='childTitle-error-text'>
                                                        {formTouched[
                                                          selectedMSIndex
                                                        ].children[childIndex]
                                                          .data &&
                                                          formErrors[
                                                            selectedMSIndex
                                                          ].children[childIndex]
                                                            .data}
                                                      </FormHelperText>
                                                    </FormControl>
                                                  </div>
                                                </div>
                                              )}
                                          </div>
                                        </ExpansionPanelDetails>
                                      </ExpansionPanel>
                                    );
                                  }
                                )}
                              <div className={classes.addContainer}>
                                <Button
                                  color='primary'
                                  onClick={this.handleAddChild}
                                  className={classes.onTheLeftButton}
                                  variant='raised'
                                  label='Add Microservice'
                                  size='small'
                                >
                                  Add Child
                                </Button>
                              </div>
                            </React.Fragment>
                          </div>
                        )}
                        {selectedMicroservice.componentName !==
                          'LinkCardSelector' &&
                          selectedMicroservice.componentName !== '' &&
                          !Array.isArray(selectedMicroservice.data) && (
                            <FormControl
                              fullWidth
                              error={
                                formTouched[selectedMSIndex].data &&
                                !!formErrors[selectedMSIndex].data
                              }
                            >
                              <div className={classes.inputRow}>
                                <InputLabel htmlFor='childTitle'>
                                  Global Data
                                </InputLabel>
                                <Input
                                  className={classes.inputWithHelp}
                                  value={selectedMicroservice.data}
                                  onChange={(e) => {
                                    this.handleUpdateField(
                                      'data',
                                      e.target.value
                                    );
                                  }}
                                  name='data'
                                  label='Global Data'
                                  type='text'
                                />
                                <Tooltip
                                  onClose={(event) => this.showHelp(false)}
                                  onOpen={(event) =>
                                    this.showHelp('globalData')
                                  }
                                  open={help === 'globalData'}
                                  title='If the microservice is enabled here and for the client, but has no data configured, this will be used.'
                                >
                                  <Icon classes={{ root: classes.helpIcon }}>
                                    help
                                  </Icon>
                                </Tooltip>
                              </div>
                              <FormHelperText id='data-error-text'>
                                {formTouched[selectedMSIndex].data &&
                                  formErrors[selectedMSIndex].data}
                              </FormHelperText>
                            </FormControl>
                          )}
                        {Array.isArray(selectedMicroservice.data) &&
                          selectedMicroservice.data.map((elem, dataIndex) => {
                            return (
                              <MultiDataPoint
                                handleUpdateDataField={
                                  this.handleUpdateDataField
                                }
                                handleMoveUp={this.handleMoveDataPointUp}
                                handleMoveDown={this.handleMoveDataPointDown}
                                handleRemoveDataPoint={
                                  this.handleRemoveDataPoint
                                }
                                handleAddDataPoint={this.handleAddDataPoint}
                                selectedMicroservice={selectedMicroservice}
                                dataIndex={dataIndex}
                                dataLength={selectedMicroservice.data.length}
                                formErrors={formErrors}
                                formTouched={formTouched}
                                dataPoint={elem}
                                classes={classes}
                              />
                            );
                          })}
                      </div>
                    </Scrollbars>
                  </div>
                )}
              </div>
              <div className={classes.submitDiv}>
                <div className={classes.submitDivLeft}>
                  <Button
                    className={classes.button}
                    variant='raised'
                    label='reset'
                    onClick={this.handleReset}
                  >
                    Reset
                  </Button>
                  <Button
                    className={classes.reorderButton}
                    variant='raised'
                    onClick={this.handleOpenReorder}
                  >
                    Reorder
                  </Button>
                </div>
                <div className={classes.submitDivRight}>
                  {microservices[selectedMSIndex] &&
                    (userClaims.allowDelete ||
                      !microservices[selectedMSIndex].isSaved) && (
                      <Button
                        className={classes.deleteButton}
                        variant='raised'
                        onClick={() =>
                          this.handleDeleteMicroservice(selectedMSIndex)
                        }
                        disabled={selectedMicroservice.isSaved === false}
                      >
                        Delete Microservice
                      </Button>
                    )}
                  <Button
                    className={classes.saveButton}
                    variant='raised'
                    label='submit'
                    onClick={this.handleSubmit}
                    disabled={
                      isSubmitting ||
                      errorsExist ||
                      (!isModified && !isReordered && selectedMSIndex === null)
                    }
                  >
                    Save Changes
                  </Button>
                </div>
              </div>
              <LoadingModal
                open={modalOpen}
                state={modalState}
                text={modalText}
                handleClose={this.handleClose}
                totalWidth={totalWidth}
                totalHeight={totalHeight}
                adjustWidth={adjustWidth}
              />
              <Modal
                aria-labelledby='simple-modal-title'
                aria-describedby='simple-modal-description'
                open={reorderOpen}
                onClose={this.handleCloseReorder}
              >
                <div
                  style={getModalStyle()}
                  className={classes.reorderModalPaper}
                >
                  <List component='nav' className={classes.list}>
                    <Typography variant='subheading'>
                      Reorder Microservices
                    </Typography>
                    <Scrollbars
                      autoHeight
                      autoHeightMin={`650px`}
                      renderThumbVertical={(props) => (
                        <div {...props} className={classes.scrollbar} />
                      )}
                      renderThumbHorizontal={(props) => (
                        <div {...props} className={classes.scrollbar} />
                      )}
                    >
                      <div className={classes.reorderScrollbarChild}>
                        {microservices.map((ms, index) => {
                          if (index > 0) {
                            return (
                              <ListItem className={classes.listItem} divider>
                                {ms.icon && (
                                  <ListItemIcon>
                                    <Icon
                                      color={ms.disabled ? 'error' : 'primary'}
                                      classes={{ root: classes.panelIcon }}
                                    >
                                      {ms.icon}
                                    </Icon>
                                  </ListItemIcon>
                                )}
                                <ListItemText
                                  primaryTypographyProps={{
                                    color: ms.disabled ? 'error' : 'primary',
                                  }}
                                  primary={ms.primary}
                                  secondary={ms.secondary}
                                  disableGutters
                                />
                                <div className={classes.buttonDiv}>
                                  <Button
                                    color='primary'
                                    onClick={(event) =>
                                      this.handleSetItemPosition(index, 1)
                                    }
                                    className={classes.upButton}
                                    variant='raised'
                                    label='↑↑'
                                    disabled={
                                      index === 1 ||
                                      (index === 2 && !microservices[1].isSaved)
                                    }
                                    size='small'
                                  >
                                    ↑↑
                                  </Button>
                                  <Button
                                    color='primary'
                                    onClick={(event) =>
                                      this.handleMoveItem(index, 'up')
                                    }
                                    className={classes.upButton}
                                    variant='raised'
                                    label='↑'
                                    disabled={
                                      index === 1 ||
                                      (index === 2 && !microservices[1].isSaved)
                                    }
                                    size='small'
                                  >
                                    ↑
                                  </Button>
                                  <Button
                                    color='primary'
                                    onClick={(event) =>
                                      this.handleMoveItem(index, 'down')
                                    }
                                    className={classes.downButton}
                                    variant='raised'
                                    label='↓'
                                    disabled={
                                      index === microservices.length - 1 ||
                                      microservices[index].title === ''
                                    }
                                    size='small'
                                  >
                                    ↓
                                  </Button>
                                  <Button
                                    color='primary'
                                    onClick={(event) =>
                                      this.handleSetItemPosition(
                                        index,
                                        microservices.length
                                      )
                                    }
                                    className={classes.downButton}
                                    variant='raised'
                                    label='↓↓'
                                    disabled={
                                      index === microservices.length - 1 ||
                                      microservices[index].title === ''
                                    }
                                    size='small'
                                  >
                                    ↓↓
                                  </Button>
                                </div>
                              </ListItem>
                            );
                          } else {
                            return null;
                          }
                        })}
                      </div>
                    </Scrollbars>
                  </List>
                  <div className={classes.reorderSubmitDiv}>
                    <Button
                      className={classes.button}
                      variant='raised'
                      label='reset'
                      onClick={this.handleCancelReorder}
                    >
                      Cancel
                    </Button>
                    <Button
                      className={classes.saveButton}
                      variant='raised'
                      onClick={this.handleCloseReorder}
                    >
                      Done
                    </Button>
                  </div>
                </div>
              </Modal>
              <Modal
                aria-labelledby='simple-modal-title'
                aria-describedby='simple-modal-description'
                open={errorOpen}
                onClose={() => this.handleErrorModal(false)}
              >
                <div
                  style={getModalStyle()}
                  className={classes.reorderModalPaper}
                >
                  <div className={classes.titleDiv}>
                    <Typography variant='subheading'>
                      Incomplete Form
                    </Typography>
                  </div>
                  <Typography variant='body'>
                    Please make sure you have filled in all the fields and that
                    there are no errors
                  </Typography>
                  <div className={classes.errorSubmitDiv}>
                    <Button
                      className={classes.saveButton}
                      variant='raised'
                      onClick={() => this.handleErrorModal(false)}
                    >
                      OK
                    </Button>
                  </div>
                </div>
              </Modal>
            </Paper>
          </form>
        </div>
      );
    } else if (error) {
      return <ErrorPage error={error} errorCode={errorCode} />;
    } else {
      return <Loading text='Loading Microservice' />;
    }
  }
}

MicroserviceManagement.propTypes = {
  appContext: PropTypes.object.isRequired,
};

export default withStyles(styles)(MicroserviceManagement);
