import React from 'react';
import {withStyles} from '@material-ui/core/styles';
import ButtonBase from '@material-ui/core/ButtonBase';
import Grid from '@material-ui/core/Grid';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import CardHeader from '@material-ui/core/CardHeader';
import PropTypes from 'prop-types';
import {Link} from 'react-router-dom';
import Icon from '@material-ui/core/Icon';

const styles = {
  container: {
    position: 'relative',
    textAlign: 'center',
    color: 'white',
    padding: '2%'
  },
  card: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-end',
    alignItems: 'center',
    width: '300px',
    height: '420px',
  },
  link: {
    textDecoration: 'none',
  },
  icon: {
    fontSize: '250px',
    width: '100%',
    height: '100%',
  }
};

const LinkCard = (props) => {
  const {classes, microservice} = props;
  return (
    <Grid item>
      <ButtonBase component={Link} to={microservice.path}>
        <Card className={classes.card}>
          <CardHeader
            title={microservice.title}
            subheader={microservice.subtitle}
            color='primary'
            align='center'
          />
          <CardContent className={classes.content}>
            {<Icon className={classes.icon} color={'inherit'}>
              {microservice.icon}
            </Icon>}
          </CardContent>
        </Card>
      </ButtonBase>
    </Grid>
  );
};

LinkCard.defaultProps = {
  microservice: {},
};

LinkCard.propTypes = {
  microservice: PropTypes.object.isRequired,
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(LinkCard);
