import React from 'react';
import PropTypes from 'prop-types';
import Icon from '@material-ui/core/Icon';
import Typography from '@material-ui/core/Typography';
import FormControl from '@material-ui/core/FormControl';
import Button from '@material-ui/core/Button';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';

// Component that represents a microservice in the Microservice Selection (third) step
const ConfigureMicroservice = props => {
  const {
    handleUpdateMicroservice,
    handleMoveUp,
    handleMoveDown,
    microservice,
    index,
    length,
    classes,
  } =  props;
  return (
    <div className={classes.msListItem} key={microservice.id}>
      <div className={classes.titleDiv}>
        <Icon color={microservice.disabled ? 'error' : 'primary'} classes={{root: classes.iconHome}}>{microservice.icon}</Icon>
        <Typography variant='subheading'>{microservice.title}</Typography>
      </div>
      <div className={classes.indexDiv}>
        <Button
          onClick={() => handleMoveUp('microservice', index)}
          color="primary"
          className={classes.upButton}
          variant='raised'
          label='↑'
          disabled={index === 0}
          size='small'
        >
          ↑
        </Button>
        <Button
          onClick={() => handleMoveDown('microservice', index)}
          color="primary"
          className={classes.downButton}
          variant='raised'
          label='↓'
          disabled={index === length - 1}
          size='small'
        >
          ↓
        </Button>
      </div>
      <div className={classes.stateDiv}>
        <FormControl className={classes.fullForm}>
          <Select
            value={microservice.state}
            onChange={(e) => {handleUpdateMicroservice('state', e.target.value, index);}}
          >
            <MenuItem value={'enabled'}>Enabled</MenuItem>
            <MenuItem value={'inApp'}>Demo</MenuItem>
            <MenuItem value={'removed'}>Removed</MenuItem>
          </Select>
        </FormControl>
      </div>
    </div>
  );
};

ConfigureMicroservice.propTypes = {
  handleUpdateMicroservice: PropTypes.func.isRequired,
  handleMoveUp: PropTypes.func.isRequired,
  handleMoveDown: PropTypes.func.isRequired,
  microservice: PropTypes.object.isRequired,
  index: PropTypes.number.isRequired,
  length: PropTypes.number.isRequired,
  classes: PropTypes.object.isRequired,
};

export default ConfigureMicroservice;