import React from 'react';
import PropTypes from 'prop-types';
import InputLabel from '@material-ui/core/InputLabel';
import Input from '@material-ui/core/Input';
import FormControl from '@material-ui/core/FormControl';
import FormHelperText from '@material-ui/core/FormHelperText';
import Button from '@material-ui/core/Button';

const generateID = () => {
  return String.fromCharCode(65 + Math.floor(Math.random() * 26)) + Math.floor(Date.now() * Math.random()).toString(8);
};

// Component that represents a data point within a microservice in the Microservice Config (forth) step
const MultiDataPoint = props => {
  const {
    handleUpdateMicroserviceData,
    handleMoveUp,
    handleMoveDown,
    handleRemoveDataPoint,
    handleAddDataPoint,
    microservice,
    msIndex,
    childIndex,
    dataIndex,
    dataLength,
    formErrors,
    dataPoint,
    classes
  } = props;
  // console.log('in MultiDataPoint for: ', microservice.title);
  // console.log(microservice);
  const dataPointFields = Object.keys(dataPoint);
  return (
    <div className={classes.msConfigArrayListContainer} key={dataPoint.id}>
      <div className={classes.msConfigArrayListItem}>
        {dataPointFields.map((dataPointField, dpIndex) => {
          if (dataPointField !== 'id') {
            const fieldRef = dataPoint.id + dataPointField;
            return (
              <FormControl className={classes.dataPointInput} error={!!formErrors[fieldRef]} key={fieldRef}>
                <InputLabel>{dataPointField}</InputLabel>
                <Input
                  value={microservice.data[dataIndex][dataPointField]}
                  onChange={(e) => {
                    handleUpdateMicroserviceData(fieldRef, e.target.value, microservice.isDashboard, msIndex, childIndex, dataIndex, dataPointField);
                  }}
                  name={dataPointField}
                  type='text'
                />
                <FormHelperText id={`${fieldRef}-error-text`}>{formErrors[fieldRef]}</FormHelperText>
              </FormControl>
            );
          } else {
            return null;
          }
        })}
      </div>
      <div className={classes.msConfigButtonsContainer}>
        <div className={classes.indexDiv}>
          <Button
            onClick={() => handleMoveUp('data', dataIndex, msIndex, childIndex)}
            color="primary"
            className={classes.upButton}
            variant='raised'
            label='↑'
            disabled={dataIndex === 0}
            size='small'
          >
            ↑
          </Button>
          <Button
            onClick={() => handleMoveDown('data', dataIndex, msIndex, childIndex)}
            color="primary"
            className={classes.downButton}
            variant='raised'
            label='↓'
            disabled={dataIndex === dataLength - 1}
            size='small'
          >
            ↓
          </Button>
        </div>
        <Button
          onClick={() => handleRemoveDataPoint(dataIndex, dataPoint.id, msIndex, childIndex)}
          color="primary"
          className={classes.onTheLeftButton}
          variant='raised'
          label='Remove'
          size='small'
          disabled={dataLength === 1}
        >
          Remove
        </Button>
      </div>
      {dataIndex === dataLength - 1 &&
        <div className={classes.msConfigAddButtonContainer}>
          <Button
            onClick={() => handleAddDataPoint({...dataPointFields.reduce((acc, elem) => {
              acc[elem] = '';
              return acc;
            }, {}), id: generateID()}, msIndex, childIndex)}
            color="primary"
            className={classes.onTheLeftButton}
            variant='raised'
            label='Add'
            size='small'
          >
            Add Another
          </Button>
        </div>
      }
    </div>
  );
};

MultiDataPoint.propTypes = {
  handleUpdateMicroserviceData: PropTypes.func.isRequired,
  handleMoveUp: PropTypes.func.isRequired,
  handleMoveDown: PropTypes.func.isRequired,
  handleRemoveDataPoint: PropTypes.func.isRequired,
  handleAddDataPoint: PropTypes.func.isRequired,
  isBigQuerySetup: PropTypes.bool.isRequired,
  handleIsBigQuerySetup: PropTypes.func.isRequired,
  microservice: PropTypes.object.isRequired,
  msIndex: PropTypes.number.isRequired,
  isChild: PropTypes.bool.isRequired,
  childIndex: PropTypes.number.isRequired,
  formErrors: PropTypes.array.isRequired,
  formTouched: PropTypes.array.isRequired,
  classes: PropTypes.object.isRequired,
};

export default MultiDataPoint;