import React from 'react';
import PropTypes from 'prop-types';
import Paper from '@material-ui/core/Paper';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';
import FormLabel from '@material-ui/core/FormLabel';
import {withStyles} from '@material-ui/core/styles';
import Snackbar from '@material-ui/core/Snackbar';

const styles = (theme) => ({
  settingsPaperContainer: {
    flexGrow: 1,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  themePaper:{
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
  },
  formControl: {
    margin: theme.spacing.unit * 3,
  },
  group: {
    margin: `${theme.spacing.unit}px`,
  },
});

class AppManagement extends React.Component {
  state = {
    theme: 'dark',
    drawerOpen: true,
    saved: false,
  };

  updateTheme = () => {
    const {theme} = this.state;
    const newTheme = theme === 'dark' ? 'light' : 'dark';
    this.props.appContext.firestore.collection('settings').doc('defaults')
    .update({theme: newTheme})
    .then(() => {
      this.setState({theme: newTheme, saved: true});
    });
  };

  updateDrawerOpen = () => {
    const {drawerOpen} = this.state;
    this.props.appContext.firestore.collection('settings').doc('defaults')
    .update({drawerOpen: !drawerOpen})
    .then(() => {
      this.setState(prevState => {
        return ({drawerOpen: !prevState.drawerOpen, saved: true});
      });
    });
  };

  handleCloseSnackbar = (event, reason) => {
    this.setState({saved: false});
  } 

  componentDidMount() {
    this.props.appContext.firestore.collection('users').doc('defaults').get().then(defaultsSnapshot => {
      const defaults = defaultsSnapshot.data();
      this.setState({...defaults});
    });
  };

  render() {
    const {classes} = this.props;
    const {theme, drawerOpen, saved} = this.state;
    return (
      <React.Fragment>
        <Paper className={classes.settingsPaperContainer}>
          <Paper className={classes.themePaper}>
            <FormControl component='fieldset' className={classes.formControl}>
              <FormLabel component='legend'>Theme</FormLabel>
              <RadioGroup
                aria-label='theme'
                name='theme1'
                className={classes.group}
                value={theme}
                onChange={this.updateTheme}
              >           
                <FormControlLabel value='light' control={<Radio />} label='Light' />
                <FormControlLabel value='dark' control={<Radio />} label='Dark' />
              </RadioGroup>
            </FormControl>
            <FormControl component='fieldset' className={classes.formControl}>
              <FormLabel component='legend'>Drawer State</FormLabel>
              <RadioGroup
                aria-label='drawerOpen'
                name='drawerOpen1'
                className={classes.group}
                value={drawerOpen}
                onChange={this.updateDrawerOpen}
              >           
                <FormControlLabel value={true} control={<Radio />} label='Open' />
                <FormControlLabel value={false} control={<Radio />} label='Closed' />
              </RadioGroup>
            </FormControl>
          </Paper>
        </Paper>
        <Snackbar
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'right',
          }}
          open={saved}
          autoHideDuration={2000}
          onClose={this.handleCloseSnackbar}
          ContentProps={{'aria-describedby': 'message-id'}}
          message={<span id="message-id">Default setting has been updated</span>}
        />
      </React.Fragment>
    );
  }
}

AppManagement.propTypes = {
  appContext: PropTypes.object.isRequired,
};

export default withStyles(styles)(AppManagement);
