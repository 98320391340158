import React from 'react';
import PropTypes from 'prop-types';
import Typography from '@material-ui/core/Typography';
import Icon from '@material-ui/core/Icon';
import InputLabel from '@material-ui/core/InputLabel';
import Input from '@material-ui/core/Input';
import FormControl from '@material-ui/core/FormControl';
import FormHelperText from '@material-ui/core/FormHelperText';
import FormLabel from '@material-ui/core/FormLabel';
import Checkbox from '@material-ui/core/Checkbox';
import MultiDataPoint from './MultiDataPoint';

// Component that represents a data point within a microservice in the Microservice Config (forth) step
const MicroserviceDataPoint = props => {
  const {
    handleUpdateMicroserviceData,
    handleMoveUp,
    handleMoveDown,
    handleRemoveDataPoint,
    handleAddDataPoint,
    isBigQuerySetup,
    handleIsBigQuerySetup,
    microservice,
    msIndex,
    isChild,
    childIndex,
    formErrors,
    formTouched,
    classes,
  } = props;
  // console.log('in MicroserviceDataPoint for: ', microservice.title);
  // console.log(microservice);
  if (Array.isArray(microservice.data) && microservice.state === 'enabled') {
    // Microservice has multiple data inputs
    // console.log('1');
    // console.log(microservice);
    return (
      <React.Fragment>
        <div className={classes.titleIconContainer}>
          <Icon color={microservice.disabled ? 'error' : 'primary'} classes={{root: classes.iconHome}}>{microservice.icon}</Icon>
          <Typography variant='subheading' className={classes.reviewTitle}>{microservice.title}</Typography>
        </div>
        {microservice.data.map((elem, dataIndex) => {
          return (
            <MultiDataPoint
              handleUpdateMicroserviceData={handleUpdateMicroserviceData}
              handleMoveUp={handleMoveUp}
              handleMoveDown={handleMoveDown}
              handleRemoveDataPoint={handleRemoveDataPoint}
              handleAddDataPoint={handleAddDataPoint}
              microservice={microservice}
              msIndex={msIndex}
              childIndex={isChild ? childIndex : false}
              dataIndex={dataIndex}
              dataLength={microservice.data.length}
              formErrors={formErrors}
              formTouched={formTouched}
              dataPoint={elem}
              classes={classes}
            />
          );
        })}
      </React.Fragment>
    );
  } else if (typeof(microservice.data) === 'string' && (microservice.state === 'enabled' || isChild) && !microservice.isDashboard) {
    // console.log('2');
    // Single data
    const fieldRef = `microservices[${msIndex}]data`;
    if (microservice.title === 'Data Download') {
      // Special check to see if Big Query has been set up for the Download Data MS
      // console.log('A');
      // console.log(microservice);
      return (
        <React.Fragment>
          <div className={classes.titleIconContainer}>
            <Icon color={microservice.disabled ? 'error' : 'primary'} classes={{root: classes.iconHome}}>{microservice.icon}</Icon>
            <Typography variant='subheading' className={classes.reviewTitle}>{microservice.title}</Typography>
          </div>
          <div className={classes.dashboardListItem} key={microservice.id}>
            <FormControl className={classes.isUser}>
              <FormLabel component='legend'>Has the client's dataset been created in BigQuery?</FormLabel>
              <Checkbox
                checked={isBigQuerySetup}
                onChange={handleIsBigQuerySetup}
                value={isBigQuerySetup}
                color='primary'
              />
            </FormControl>
          </div>
        </React.Fragment>
      );
    } else {
      // Normal single data entry
      // console.log('B');
      // console.log(microservice);
      return (
        <React.Fragment>
          <div className={classes.titleIconContainer}>
            <Icon color={microservice.disabled ? 'error' : 'primary'} classes={{root: classes.iconHome}}>{microservice.icon}</Icon>
            <Typography variant='subheading' className={classes.reviewTitle}>{microservice.title}</Typography>
          </div>
          <div className={classes.dashboardListItem} key={microservice.id}>
            <FormControl fullWidth error={!!formErrors[fieldRef]}>
              <InputLabel>{`${microservice.title} Data`}</InputLabel>
              <Input
                value={microservice.data}
                onChange={(e) => {handleUpdateMicroserviceData(fieldRef, e.target.value, microservice.isDashboard, msIndex, childIndex);}}
                name={fieldRef}
                type='text'
              />
              <FormHelperText id={`${fieldRef}-error-text`}>{formErrors[fieldRef]}</FormHelperText>
            </FormControl>
          </div>
        </React.Fragment>
      );
    }
  } else {
    // console.log('False');
    // console.log('typeof(microservice.data) === string: ', typeof(microservice.data) === 'string');
    // console.log('microservice.state === enabled: ', microservice.state === 'enabled');
    // console.log('!microservice.isDashboard: ', !microservice.isDashboard);
    // console.log(microservice);
    return null;
  }
};

MicroserviceDataPoint.PropTypes = {
  handleUpdateMicroserviceData: PropTypes.func.isRequired,
  handleMoveUp: PropTypes.func.isRequired,
  handleMoveDown: PropTypes.func.isRequired,
  handleRemoveDataPoint: PropTypes.func.isRequired,
  handleAddDataPoint: PropTypes.func.isRequired,
  isBigQuerySetup: PropTypes.bool.isRequired,
  handleIsBigQuerySetup: PropTypes.func.isRequired,
  microservice: PropTypes.object.isRequired,
  msIndex: PropTypes.number.isRequired,
  isChild: PropTypes.bool.isRequired,
  childIndex: PropTypes.number.isRequired,
  formErrors: PropTypes.array.isRequired,
  formTouched: PropTypes.array.isRequired,
  classes: PropTypes.object.isRequired,
};

export default MicroserviceDataPoint;