import React from 'react';
import PropTypes from 'prop-types';
import {Link} from 'react-router-dom';
import {Formik} from 'formik';
import Paper from '@material-ui/core/Paper';
import Button from '@material-ui/core/Button';
import FormControl from '@material-ui/core/FormControl';
import Input from '@material-ui/core/Input';
import InputLabel from '@material-ui/core/InputLabel';
import FormHelperText from '@material-ui/core/FormHelperText';
import {withStyles} from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import Background from '../app/assets/img/background.jpg'
import EchoLogo from '../app/assets/img/echo_logo_draft_1.png';
import firebase from 'firebase/app';
import 'firebase/auth';

const styles = (theme) => ({
  background: {
    display: 'flex',
    width: '100%',
    height: '100%',
    backgroundImage: `url(${Background})`,
    backgroundSize: 'cover',
    justifyContent: 'center',
    alignItems: 'center',
  },
  paperContainer: {
    display: 'flex',
    flexDirection: 'column',
    width: '426px',
    height: '500px',
    padding: '50px',
  },
  logo: {
    width: '33%',
    marginLeft: '-6px',
    marginBottom: '15px',
  },
  subheading: {
    marginBottom: '15px',
  },
  inputField: {
    width: '100%',
  },
  buttonDiv: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    width: '100%',
    marginTop: '15px',
  },
  button: {
    background: theme.palette.primary.main,
    color: 'white'
  },
  forgotPassword: {
    color: theme.palette.primary.main,
    textDecoration: 'none',
    
  },
  cssLabel: {
    '&$cssFocused': {
      color: theme.palette.primary.main,
    },
  },
  cssFocused: {},
  cssUnderline: {
    '&:after': {
      borderBottomColor: theme.palette.primary.main,
    },
  },
});

class LoginForm extends React.Component {
  state = {
    persistentLogin: false,
    searchParams: new URLSearchParams(window.location.search)
  };

  setPersistence = () => {
    this.setState((prevState) => ({persistentLogin: !prevState.persistentLogin}));
  };

  validate = (values) => {
    let errors = {};
    if (!values.email) {
      errors.email = 'Email is required';
    } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(values.email)) {
      errors.email = 'Invalid email address';
    }
    if (!values.password) {
      errors.password = 'Password is required';
    }
    return errors;
  };

  handleSubmit = (values, {setSubmitting, setErrors}) => {
    setSubmitting(true);
    if (this.state.persistentLogin) {
      firebase.auth()
      .signInWithEmailAndPassword(values.email, values.password) // Triggers firebase.auth().onAuthStateChanged() in App.js on successful login
      .catch(error => {
        setSubmitting(false);
        if (error.code === 'auth/invalid-email') {
          setErrors({email: 'Invalid email'});
        } else if (error.code === 'auth/user-disabled') {
          setErrors({email: 'User has been disabled'});
        } else if (error.code === 'auth/user-not-found') {
          setErrors({email: 'User not found'});
        } else if (error.code === 'auth/wrong-password') {
          setErrors({password: 'Invalid password'});
        }
      })
      .finally(() => setSubmitting(false));
    } else {
      firebase.auth().setPersistence(firebase.auth.Auth.Persistence.SESSION)
      .then(() => firebase.auth()
        .signInWithEmailAndPassword(values.email, values.password) // Triggers firebase.auth().onAuthStateChanged() in App.js on successful login
        .catch(error => {
          setSubmitting(false);
          if (error.code === 'auth/invalid-email') {
            setErrors({email: 'Invalid email'});
          } else if (error.code === 'auth/user-disabled') {
            setErrors({email: 'User has been disabled'});
          } else if (error.code === 'auth/user-not-found') {
            setErrors({email: 'User not found'});
          } else if (error.code === 'auth/wrong-password') {
            setErrors({password: 'Invalid password'});
          } else if (error.code === 'auth/invalid-persistence-type') {
            setErrors({email: 'Internal Error: Invalid Persistence Type'});
          } else if (error.code === 'auth/unsupported-persistence-type') {
            setErrors({email: 'Internal Error: ENV does not support persistence type'});
          }
        })
        .finally(() => setSubmitting(false))
      );
    }
  };

  render() {
    const {classes} = this.props;
    return (
      <div className={classes.background}>
        <Paper className={classes.paperContainer}>
          <img src={EchoLogo} alt='' className={classes.logo}/>
          <Typography variant="headline" gutterBottom >Sign In</Typography>
          <Typography variant="subheading" className={classes.subheading} >to continue to Echo's Admin Portal</Typography>
          <Formik
            initialValues={{email: this.state.searchParams.get('email'), password: ''}}
            validate={this.validate}
            onSubmit={this.handleSubmit}
            render={
            ({
               values,
               errors,
               touched,
               handleChange,
               handleBlur,
               handleSubmit,
               isSubmitting
            }) => (
              <form onSubmit={handleSubmit} style={{width: '100%'}}>
                <FormControl error={touched.email && !!errors.email} className={classes.inputField}>
                  <InputLabel FormLabelClasses={{root: classes.cssLabel, focused: classes.cssFocused}} htmlFor="custom-css-input">
                    Email
                  </InputLabel>
                  <Input
                    classes={{underline: classes.cssUnderline}}
                    value={values.email}
                    onChange={handleChange}
                    name='email'
                    label='Email'
                    type='email'
                    id='email'
                    onBlur={handleBlur}
                  />
                  <FormHelperText id='email-error-text'>{touched.email && errors.email}</FormHelperText>
                </FormControl>
                <FormControl error={touched.password && !!errors.password} className={classes.inputField}>
                  <InputLabel FormLabelClasses={{root: classes.cssLabel, focused: classes.cssFocused}} htmlFor="custom-css-input">
                    Password
                  </InputLabel>
                  <Input
                    classes={{underline: classes.cssUnderline}}
                    value={values.password}
                    onChange={handleChange}
                    name='password'
                    label='Password'
                    type='password'
                    id='password'
                    onBlur={handleBlur}
                  />
                  <FormHelperText id='password-error-text'>{errors.password}</FormHelperText>
                </FormControl>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={this.state.persistentLogin}
                      onChange={this.setPersistence}
                      value="persistentLogin"
                    />
                  }
                  label="Remember Me"
                />
                <div className={classes.buttonDiv}>
                  <Typography className={classes.forgotPassword} variant='body1' component={Link} to='/forgotPassword' >Forgot Password?</Typography>
                  <Button
                    className={classes.button}
                    variant='raised'
                    label='submit'
                    type='submit'
                    id='login'
                    disabled={isSubmitting || (errors != null && Object.keys(errors).length > 0)}
                  >
                    Sign in
                  </Button>
                </div>
              </form>
            )}/>
        </Paper>
      </div>
    );
  };
}

LoginForm.propTypes = {
  classes: PropTypes.object.isRequired,
}

export default withStyles(styles)(LoginForm);