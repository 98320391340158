import React from 'react';
import PropTypes from 'prop-types';
import { Scrollbars } from 'react-custom-scrollbars';
import Typography from '@material-ui/core/Typography';
import InputLabel from '@material-ui/core/InputLabel';
import Input from '@material-ui/core/Input';
import FormControl from '@material-ui/core/FormControl';
import Chip from '@material-ui/core/Chip';
import FormHelperText from '@material-ui/core/FormHelperText';
import MultiSelectDropdown from '../app/components/MultiSelectDropdown';

// Component for the second step in client setup
const ClientDetails = props => {
  const {
    handleUpdateField,
    handleAddUser,
    handleRemoveUser,
    selectedClient,
    formTouched,
    formErrors,
    classes,
    selectedUsersScrollbarSize
  } = props;
  // console.log('test!!');
  // console.log(formTouched);
  // console.log(formErrors);
  return (
    <div className={classes.msList}>
      <Typography variant='title'>Client Details</Typography>
      <FormControl className={classes.wideForm} error={formTouched.name && !!formErrors.name}>
        <InputLabel>Client Name</InputLabel>
        <Input
          value={selectedClient.name}
          onChange={(e) => {handleUpdateField('name', e.target.value);}}
          name='name'
          label='Client Name'
          type='text'
        />
        <FormHelperText id='name-error-text'>{formTouched.name && formErrors.name}</FormHelperText>
      </FormControl>
      <FormControl className={classes.wideForm} error={formTouched.mainContactEmail && !!formErrors.mainContactEmail}>
        <InputLabel>Main Contact Email</InputLabel>
        <Input
          value={selectedClient.mainContactEmail}
          onChange={(e) => {handleUpdateField('mainContactEmail', e.target.value);}}
          name='mainContactEmail'
          label='Main Contact Email'
          type='email'
        />
        <FormHelperText id='mainContactEmail-error-text'>{formTouched.mainContactEmail && formErrors.mainContactEmail}</FormHelperText>
      </FormControl>
      <div className={classes.userSelectionHeading}>
        <Typography variant='h6'>Users With Access</Typography>
      </div>
      <div className={classes.userSelectionDiv}>
        <div className={classes.userSelectionHalfDiv}>
          <MultiSelectDropdown
            placeholder='Search ...'
            options={selectedClient.availableUsers}
            onChange={handleAddUser}
            selectedValues={selectedClient.selectedUsers}
          />
        </div>
        <div className={classes.userSelectionHalfDiv}>
          <Typography variant='subtitle1'>Selected Users</Typography>
          {selectedClient.selectedUsers.length > 0 && <Scrollbars
            autoHeight
            autoHeightMin={`${selectedUsersScrollbarSize}px`}
            renderThumbVertical={props => <div className={classes.scrollbar}/>}
            renderThumbHorizontal={props => <div className={classes.noScrollbar}/>}
          >
            {selectedClient.selectedUsers.map(user => 
              <Chip
                clickable
                label={`${user.displayName} (${user.email})`}
                onClick={() => handleRemoveUser(user.uid)}
              />
            )}
          </Scrollbars>}
        </div>
      </div>
    </div>
  );
};

ClientDetails.propTypes = {
  handleUpdateField: PropTypes.func.isRequired,
  handleAddUser: PropTypes.func.isRequired,
  handleRemoveUser: PropTypes.func.isRequired,
  selectedClient: PropTypes.object.isRequired,
  formTouched: PropTypes.array.isRequired,
  formErrors: PropTypes.array.isRequired,
  classes: PropTypes.object.isRequired,
  selectedUsersScrollbarSize: PropTypes.number.isRequired,
};

export default ClientDetails;