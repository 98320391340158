import React from 'react';
import {Link} from 'react-router-dom';
import {withStyles} from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import ErrorBackground from '../assets/img/error.png';

const styles = theme => ({
  all: {
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column',
    justifyContent: 'center',
    width: '100%',
    height: '100%',
    background: 'white',
  },
  other: {
    color: theme.palette.secondary.dark,
    fontSize: '40px',
  },
  subText: {
    fontSize: '25px',
    color: theme.palette.secondary.dark,
  },
  errorText: {
    fontSize: '20px',
    color: theme.palette.secondary.dark,
  }
});

const ErrorPage = (props) => {
  const {classes, errorCode, error} = props;
  if (errorCode === 404) {
    return (
      <div className={classes.all}>
        <img src={ErrorBackground} alt=''/>
        <h1 className={classes.other}>Oh dear, we lost you...</h1>
        <Link to='/'>
          <p className={classes.subText}>Shortcut to Home</p>
        </Link>
        <p className={classes.errorText}>{errorCode}: {error}</p>
      </div>
    );
  } else if (errorCode === 403) {
    return (
      <div className={classes.all}>
        <img src={ErrorBackground} alt=''/>
        <h1 className={classes.other}>Forbidden</h1>
        <p className={classes.subText}>You don't have permission to access this page. Please contact us if this is a mistake.</p>
        <p className={classes.errorText}>{errorCode}: {error}</p>
      </div>
    );
  } else if (errorCode === 408) {
    return (
      <div className={classes.all}>
        <img src={ErrorBackground} alt=''/>
        <h1 className={classes.other}>Looks like your internet might be a bit slow...</h1>
        <p className={classes.subText}>Please refresh to try again</p>
        <p className={classes.errorText}>{errorCode}: {error}</p>
      </div>
    );
  } else {
    return (
      <div className={classes.all}>
        <img src={ErrorBackground} alt=''/>
        <h1 className={classes.other}>Something Broke :(</h1>
        {errorCode && error && <p className={classes.errorText}>{errorCode}: {error}</p>}
      </div>
    );
  }
};

ErrorPage.propTypes = {
  error: PropTypes.string.isRequired,
  errorCode: PropTypes.number,
};

export default withStyles(styles)(ErrorPage);
