import React from 'react';
import moment from 'moment';
import { withStyles } from '@material-ui/core/styles';



import Chip from '@material-ui/core/Chip';

import firebase from 'firebase/app';
import 'firebase/firestore';

import Typography from '@material-ui/core/Typography';

import PropTypes from 'prop-types';
import MultiChart from '../app/components/MultiChart';
import Loading from '../app/components/Loading';
import ListText from '../app/components/ListText';
import BarChart from "./BarChart";

const styles = theme => ({
  mainContainer: {
    display: 'flex',
    height: '100%',
    width: '100%',
    minWidth: '800px',
    justifyContent: 'flex-start',
    alignItems: 'flex-start',
  },
  userListDiv: {
    display: 'flex',
    height: '100%',
  },
  detailsContainer: {
    flexGrow: 1,
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
  },
  mainGraphTitle: {
    display: 'flex',
    height: '3%',
    width: '100%',
  },
  mainGraphs: {
    display: 'flex',
    height: '59%',
    width: '100%',
    justifyContent: 'flex-start',
    alignItems: 'flex-start',
  },
  subGraphKey: {
    display: 'flex',
    height: '5%',
    width: '100%',
    justifyContent: 'space-evenly',
  },
  subGraphTitle: {
    display: 'flex',
    height: '3%',
    width: '100%',
  },
  subGraphs: {
    display: 'flex',
    height: '30%',
    width: '100%',
    justifyContent: 'flex-start',
    alignItems: 'flex-start',
  },
  leftGraph: {
    display: 'flex',
    height: '100%',
    width: '50%',
    justifyContent: 'center',
  },
  rightGraph: {
    display: 'flex',
    height: '100%',
    width: '50%',
    justifyContent: 'center',
  },
  outerLeftGraph: {
    display: 'flex',
    height: '100%',
    width: '25%',
    justifyContent: 'center',
  },
  innerLeftGraph: {
    display: 'flex',
    height: '100%',
    width: '25%',
    justifyContent: 'center',
  },
  innerRightGraph: {
    display: 'flex',
    height: '100%',
    width: '25%',
    justifyContent: 'center',
  },
  outerRightGraph: {
    display: 'flex',
    height: '100%',
    width: '25%',
    justifyContent: 'center',
  },
  chips: {
    backgroundColor: '#ffa500',
    opacity: '0.4',
  },
});

class TestResults extends React.Component {
  state = {
    loading: true,
    selectedTestIndex: -1,
    submittedTests: [],
    colourArr: ['#029fe5', '#ffa500', '#e50202', '#8ae502', '#2a9304', '#73e2a9', '#21efc5', '#00725b', '#24b9e2', '#1766ef', '#0c357c', '#0f2e63', '#3f43a8', '#343be5', '#0a13ff', '#6b49a3', '#7f3af2', '#5900ef', '#e29bff', '#9028ba', '#7200a0', '#a626ad', '#76007c', '#fc00b4', '#9b1a76', '#bc0048'],
    currGraphData : [],
    allTestAverages: {},
    allTestTotals: {},
    currAverageGraphData: [],
    allAverageGraphData: [],
    currTotalGraphData: [],
    allTotalGraphData: [],
    totalRuns: 0,
  };

  generateRandomColour = () => {
    const letters = '0123456789ABCDEF';
    let colour = '#';
    for (let i = 0; i < 6; i++) {
      colour += letters[Math.floor(Math.random() * 16)];
    }
    return colour;
  };

  getRandomColour = () => {
    let colour = '';
    while (colour === '' || this.state.colourArr.find(colour)) {
      colour = this.generateRandomColour();
    }
    const newColourArr = this.state.colourArr.slice(0);
    newColourArr.push(colour);
    this.setState({colourArr: newColourArr});
    return colour;
  };

  // Function passes to graph to set the cursor location on X Axis
  setLastDrawLocation = (lastDrawLocation) => {
    this.setState({lastDrawLocation});
  };

  handleSelectTest = (newSelectedTestIndex) => {
    const {submittedTests, allTestAverages, allTestTotals, selectedTestIndex} = this.state;
    const currGraphData = JSON.parse(JSON.stringify(submittedTests[newSelectedTestIndex].graphData));
    const currAverageGraphData = [
      {x: 'Login', y: Math.round(submittedTests[newSelectedTestIndex].loginTime)},
      {x: 'Trusted Auth', y: Math.round(submittedTests[newSelectedTestIndex].averageTrustedAuthTime)},
      {x: 'Tableau iFrame', y: Math.round(submittedTests[newSelectedTestIndex].averageiFrameTime)},
      {x: 'iFrame DOM Content', y: Math.round(submittedTests[newSelectedTestIndex].averageDomcontentloadedTime)},
      {x: 'Total Time', y: Math.round(submittedTests[newSelectedTestIndex].averageTotalTime)},
    ];
    const allAverageGraphData = [
      {x: 'Login', y: Math.round(allTestAverages.loginTime)},
      {x: 'Trusted Auth', y: Math.round(allTestAverages.allAverageTrustedAuthTimes)},
      {x: 'Tableau iFrame', y: Math.round(allTestAverages.allAverageiFrameTimes)},
      {x: 'iFrame DOM Content', y: Math.round(allTestAverages.allAverageDomContentTimes)},
      {x: 'Total Time', y: Math.round(allTestAverages.allAverageTotalTimes)},
    ];
    const currTotalGraphData = [
      {x: 'Trusted Auth', y: Math.round(submittedTests[newSelectedTestIndex].totalTrustedAuthTime)},
      {x: 'Tableau iFrame', y: Math.round(submittedTests[newSelectedTestIndex].totaliFrameTime)},
      {x: 'iFrame DOM Content', y: Math.round(submittedTests[newSelectedTestIndex].totalDomcontentloadedTime)},
      {x: 'Total Time', y: Math.round(submittedTests[newSelectedTestIndex].totalTotalTime)},
    ];
    const allTotalGraphData = [
      {x: 'Trusted Auth', y: Math.round(allTestTotals.allTotalTrustedAuthTimes / submittedTests.length)},
      {x: 'Tableau iFrame', y: Math.round(allTestTotals.allTotaliFrameTimes / submittedTests.length)},
      {x: 'iFrame DOM Content', y: Math.round(allTestTotals.allTotalDomContentTimes / submittedTests.length)},
      {x: 'Total Time', y: Math.round(allTestTotals.allTotalTotalTimes / submittedTests.length)},
    ];
    if (selectedTestIndex > -1) {
      const msStates = submittedTests[selectedTestIndex].msStates.slice(0);
      msStates.forEach(elem => {
        elem.enabled = true;
      })
      submittedTests[selectedTestIndex].msStates = msStates;
    }
    this.setState({
      selectedTestIndex: newSelectedTestIndex,
      currGraphData,
      currAverageGraphData,
      allAverageGraphData,
      currTotalGraphData,
      allTotalGraphData,
      submittedTests,
    });
  };

  handleToggleMsState = (index) => {
    const {submittedTests, selectedTestIndex, currGraphData} = this.state;
    const msStates = submittedTests[selectedTestIndex].msStates.slice(0);
    const graphData = JSON.parse(JSON.stringify(currGraphData));
    if (msStates[index].enabled) {
      Object.keys(graphData).forEach(graphName => {
        graphData[graphName][index] = [];
      })
    } else {
      Object.keys(graphData).forEach(graphName => {
        graphData[graphName][index] = JSON.parse(JSON.stringify(submittedTests[selectedTestIndex].graphData[graphName][index]));
      })
    }
    msStates[index].enabled = !msStates[index].enabled;
    submittedTests[selectedTestIndex].msStates = msStates;
    this.setState({submittedTests, currGraphData: graphData});
  };

  componentDidMount() {
    const appContext = this.props.appContext;
    // Fetch the user's claims from Firebase
    firebase.auth().currentUser.getIdTokenResult(true).then((idTokenResult) => {
      const claims = idTokenResult.claims;
      // Check if user has permission to use this admin microservice
      if (claims.testResults) {
        const loadTimesRef = appContext.firestore.doc('tests/loadTimes');
        const submissionsRef = loadTimesRef.collection('submissions');
        // Get list of submissions
        submissionsRef.orderBy('testTime', 'desc').get().then(submissionsSnapshot => {
          const submittedTests = [];
          // eslint-disable-next-line
          let totalRuns = 0;
          let totalLoads = 0;
          const allTestAverages = {
            allAverageTrustedAuthTimes: 0,
            allAverageiFrameTimes: 0,
            allAverageDomContentTimes: 0,
            allAverageTotalTimes: 0,
          };
          const allTestTotals = {
            loginTime: 0,
            allTotalTrustedAuthTimes: 0,
            allTotaliFrameTimes: 0,
            allTotalDomContentTimes: 0,
            allTotalTotalTimes: 0,
          };
          submissionsSnapshot.forEach(submissionDocSnapshot => {
            const data = submissionDocSnapshot.data();
            data.id = submissionDocSnapshot.id;
            data.msStates = [];
            data.graphData = {};
            data.graphData.trustedAuthTime = [];
            data.graphData.iFrameTime = [];
            data.graphData.domcontentloadedTime = [];
            data.graphData.totalTime = [];
            totalRuns += data.numRuns;
            totalLoads += data.numRuns * data.microservices.length;
            allTestTotals.loginTime += data.loginTime;
            allTestTotals.allTotalTrustedAuthTimes += data.totalTrustedAuthTime;
            allTestTotals.allTotaliFrameTimes += data.totaliFrameTime;
            allTestTotals.allTotalDomContentTimes += data.totalDomcontentloadedTime;
            allTestTotals.allTotalTotalTimes += data.totalTotalTime;
            const testDate = new Date(data.testTime);
            data.dateString = testDate.toDateString();
            data.microservices.forEach(ms => {
              const trustedAuthTimeGraphData = ms.trustedAuthTime.map((time, i) => {
                return {x: i, y: time, i};
              });
              const iFrameTimeGraphData = ms.iFrameTime.map((time, i) => {
                return {x: i, y: time, i};
              });
              const domcontentloadedTimeGraphData = ms.domcontentloadedTime.map((time, i) => {
                return {x: i, y: time, i};
              });
              const totalTimeGraphData = ms.totalTime.map((time, i) => {
                return {x: i, y: time, i};
              });
              data.graphData.trustedAuthTime.push(trustedAuthTimeGraphData);
              data.graphData.iFrameTime.push(iFrameTimeGraphData);
              data.graphData.domcontentloadedTime.push(domcontentloadedTimeGraphData);
              data.graphData.totalTime.push(totalTimeGraphData);
              data.msStates.push({name: ms.msName, enabled: true});
            });
            submittedTests.push(data);
          });
          allTestAverages.loginTime = allTestTotals.loginTime / submittedTests.length;
          allTestAverages.allAverageTrustedAuthTimes = allTestTotals.allTotalTrustedAuthTimes / totalLoads;
          allTestAverages.allAverageiFrameTimes = allTestTotals.allTotaliFrameTimes / totalLoads;
          allTestAverages.allAverageDomContentTimes = allTestTotals.allTotalDomContentTimes / totalLoads;
          allTestAverages.allAverageTotalTimes = allTestTotals.allTotalTotalTimes / totalLoads;
          this.setState({submittedTests, loading: false, allTestAverages, allTestTotals, totalLoads});
        })
        .catch(error => {
          console.log(error);
          this.setState({loading: false});
        });
      } else {
        this.setState({error: 'Insufficient Permissions', errorCode: 403})
      }
    })
    .catch((error) => {
      console.log(error);
    });
  };

  render() {
    const {classes, appContext} = this.props;
    const {
      loading,
      selectedTestIndex,
      submittedTests,
      colourArr,
      currGraphData,
      currAverageGraphData,
      allAverageGraphData,
      currTotalGraphData,
      lastDrawLocation,
    } = this.state;
    if (!loading) {
      return (
        <div className={classes.mainContainer}>
          <div className={classes.userListDiv}>
            <ListText
              items={submittedTests.map(test => ({primary: test.client, secondary: test.url.substring(7)}))}
              selectedIndex={selectedTestIndex}
              title='Select a test'
              onListItemSelect={this.handleSelectTest}
              dimensions={appContext.dimensions}
              maxHeight={appContext.dimensions.height - appContext.dimensions.topPanel}
            />
          </div>
          {selectedTestIndex >= 0 && <div className={classes.detailsContainer}>
           <div className={classes.mainGraphTitle}>
            <div className={classes.leftGraph}>
              <Typography variant="subheading">{`Total time for ${submittedTests[selectedTestIndex].numRuns} run throughs`}</Typography>
            </div>
            <div className={classes.rightGraph}>
              <Typography variant="subheading">Average time to load</Typography>
            </div>
          </div>
          <div className={classes.mainGraphs}>
            <div className={classes.leftGraph}>
              <BarChart
                vertBarSeriesArr={[currTotalGraphData]}
                colors={{vertBar: ['#0982b9', '#59b1ec']}}
              />
            </div>
            <div className={classes.rightGraph}>
              <BarChart
                vertBarSeriesArr={[currAverageGraphData, allAverageGraphData]}
                colors={{vertBar: ['#0982b9', '#59b1ec']}}
                legend={true}
                legendItems={[{title: 'This test', color: '#0982b9', strokeWidth: 13}, {title: 'All tests', color: '#59b1ec', strokeWidth: 15}]}
              />
            </div>
          </div>
          <div className={classes.subGraphKey}>
            {submittedTests[selectedTestIndex].microservices.map((elem, i) => {
              return <Chip
                label={elem.msName}
                onClick={() => this.handleToggleMsState(i)}
                style={{backgroundColor: submittedTests[selectedTestIndex].msStates[i].enabled ? colourArr[i] : 'grey'}}
              />;
            })}
          </div>
          <div className={classes.subGraphTitle}>
            <div className={classes.outerLeftGraph}>
              <Typography variant="subheading">Trusted Auth Time</Typography>
            </div>
            <div className={classes.innerLeftGraph}>
              <Typography variant="subheading">iFrame Time</Typography>
            </div>
            <div className={classes.innerRightGraph}>
              <Typography variant="subheading">DOM Content Loaded Time</Typography>
            </div>
            <div className={classes.outerRightGraph}>
              <Typography variant="subheading">Total Time</Typography>
            </div>
          </div>
          <div className={classes.subGraphs}>
          <div className={classes.outerLeftGraph}>
            <MultiChart
              interaction={{
                setLastDrawLocation: this.setLastDrawLocation,
                lastDrawLocation: lastDrawLocation,
              }}
              data={{lineSeriesArr: currGraphData.trustedAuthTime}}
              format={{
                colors: {line: colourArr},
                horGridLines: true,
                verGridLines: true,
                horGridLineValues: null,
                animation: true,
              }}
              xAxis={{
                title: 'Run #',
                tickSize: 3,
                tickAlign: 0,
                tickTotal: 6,
                values: Array.apply(null, {length: submittedTests[selectedTestIndex].numRuns}).map(Number.call, Number),
                tickFormat: (label) => label + 1
              }}
              yAxis={{
                title: 'Time (mm:ss:ms)',
                tickSize: 3,
                tickAlign: 0,
                tickTotal: 7,
                tickFormat: (label) => moment(label).format('mm:ss:SS')
              }}
              crosshair={{values: [], titleFormat: () => {}, itemsFormat:() => {}}}
            />
          </div>
          <div className={classes.innerLeftGraph}>
            <MultiChart
              interaction={{
                setLastDrawLocation: this.setLastDrawLocation,
                lastDrawLocation: lastDrawLocation,
              }}
              data={{lineSeriesArr: currGraphData.iFrameTime}}
              format={{
                colors: {line: colourArr},
                horGridLines: true,
                verGridLines: true,
                horGridLineValues: null,
                animation: true,
              }}
              xAxis={{
                title: 'Run #',
                tickSize: 3,
                tickAlign: 0,
                tickTotal: 6,
                values: Array.apply(null, {length: submittedTests[selectedTestIndex].numRuns}).map(Number.call, Number),
                tickFormat: (label) => label + 1
              }}
              yAxis={{
                title: 'Time (mm:ss:ms)',
                tickSize: 3,
                tickAlign: 0,
                tickTotal: 7,
                tickFormat: (label) => moment(label).format('mm:ss:SS')
              }}
              crosshair={{values: [], titleFormat: () => {}, itemsFormat:() => {}}}
            />
          </div>
          <div className={classes.innerRightGraph}>
          <MultiChart
          interaction={{
            setLastDrawLocation: this.setLastDrawLocation,
            lastDrawLocation: lastDrawLocation,
          }}
          data={{lineSeriesArr: currGraphData.domcontentloadedTime}}
          format={{
            colors: {line: colourArr},
            horGridLines: true,
            verGridLines: true,
            horGridLineValues: null,
            animation: true,
          }}
          xAxis={{
            title: 'Run #',
            tickSize: 3,
            tickAlign: 0,
            tickTotal: 6,
            values: Array.apply(null, {length: submittedTests[selectedTestIndex].numRuns}).map(Number.call, Number),
            tickFormat: (label) => label + 1
          }}
          yAxis={{
            title: 'Time (mm:ss:ms)',
            tickSize: 3,
            tickAlign: 0,
            tickTotal: 7,
            tickFormat: (label) => moment(label).format('mm:ss:SS')
          }}
          crosshair={{values: [], titleFormat: () => {}, itemsFormat:() => {}}}
          />
          </div>
          <div className={classes.outerRightGraph}>
          <MultiChart
          interaction={{
            setLastDrawLocation: this.setLastDrawLocation,
            lastDrawLocation: lastDrawLocation,
          }}
          data={{lineSeriesArr: currGraphData.totalTime}}
          format={{
            colors: {line: colourArr},
            horGridLines: true,
            verGridLines: true,
            horGridLineValues: null,
            animation: true,
          }}
          xAxis={{
            title: 'Run #',
            tickSize: 3,
            tickAlign: 0,
            tickTotal: 6,
            values: Array.apply(null, {length: submittedTests[selectedTestIndex].numRuns}).map(Number.call, Number),
            tickFormat: (label) => label + 1
          }}
          yAxis={{
            title: 'Time (mm:ss:ms)',
            tickSize: 3,
            tickAlign: 0,
            tickTotal: 7,
            tickFormat: (label) => moment(label).format('mm:ss:SS')
          }}
          crosshair={{values: [], titleFormat: () => {}, itemsFormat:() => {}}}
          />
        </div>
        </div>
        </div>}
        </div>
        );
}
else {
  return <Loading text={'Loading test results'}/>
}
}
}

TestResults.propTypes = {
  appContext: PropTypes.object.isRequired,
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(TestResults);
