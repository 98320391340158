import React from 'react';
import PropTypes from 'prop-types';
import Paper from '@material-ui/core/Paper';
import FormControl from '@material-ui/core/FormControl';
import {withStyles} from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import FormHelperText from '@material-ui/core/FormHelperText';
import Input from '@material-ui/core/Input';
import InputLabel from '@material-ui/core/InputLabel';
import Typography from '@material-ui/core/Typography';
import Chip from '@material-ui/core/Chip';
import firebase from 'firebase/app';
import 'firebase/auth';
import Loading from '../app/components/Loading';
import LoadingModal from '../app/components/LoadingModal';
import ListText from '../app/components/ListText';
import ErrorPage from '../app/components/ErrorPage';

const styles = (theme) => ({
  outerDiv: {
    flexGrow: 1,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  settingsPaperContainer: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: '25px',
    height: '80vh',
  },
  settingsDiv: {
    display: 'flex',
    justifyContent: 'flex-start',
    flexDirection: 'row',
    alignItems: 'flex-start',
    height: '70vh',
  },
  userListDiv: {
    display: 'flex',
    justifyContent: 'flex-start',
    flexDirection: 'column',
    alignItems: 'center',
    paddingRight: '25px',
    maxHeight: '70vh',
  },
  newUserDiv: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    alignItems: 'center',
    margin: '10px',
    width: '280px',
    maxHeight: '70vh',
  },
  permissionsDiv: {
    display: 'flex',
    maxHeight: '70vh',
  },
  selectedPermissionsDiv: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    alignItems: 'center',
    margin: '10px',
    width: '250px',
    maxHeight: '70vh',
    overflowY: 'auto',
  },
  formControl: {
    margin: theme.spacing.unit * 3,
  },
  submitDiv: {
    width: '100%',
    height: '10vh',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  button: {
    background: theme.palette.primary.main,
    color: 'white',
  },
  disableButton: {
    background: '#ff9411',
    color: 'white',
    marginRight: '15px',
  },
  deleteButton: {
    background: theme.palette.error.main,
    color: 'white',
    marginRight: '15px',
  },
  inputContainer: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'flex-start',
    maxHeight: '70vh',
    width: '780px'
  },
  chip: {
    backgroundColor: '#0982b9',
    color: '#ffffff',
    marginTop: '5px',
    padding: '12px',
  },
  chipDisabled: {
    marginTop: '5px',
    padding: '12px',
  }
});

class UserManagement extends React.Component {
  state = {
    availablePermissions: {},
    userClaims: {},
    userAdminClaims: {},
    clientList: [],
    userList: [],
    originalUserList: [],
    selectedUserIndex: null,
    selectedUserOriginalDetails: {},
    error: false,
    errorCode: null,
    loading: true,
    modalOpen: false,
    modalText: '',
    modalState: 'loading',
    apiUrl: this.props.appContext.apiUrl,
    formTouched: {},
    formErrors: {},
    isSubmitting: false,
  };

  handleUpdateField = (field, newValue) => {
    const checkValue = (value, userAdminClaims) => {
      // console.log('checking value: ', value);
      // console.log(userAdminClaims);
      if (userAdminClaims.some(claim => claim === value) && value !== 'allowModify') {
        return true;
      } else {
        return false;
      }
    };
    this.setState(prevState => {
      const {userList, selectedUserIndex, formTouched, userAdminClaims} = prevState;
      const newUserList = JSON.parse(JSON.stringify(userList));
      if (field === 'addPermission') {
        newUserList[selectedUserIndex].modifiableClaims[newValue] = true;
        // Add 'allowModify' if an admin claim was selected and it's not already added
        if (checkValue(newValue, userAdminClaims)) {
          newUserList[selectedUserIndex].modifiableClaims.allowModify = true;
        }
      } else if (field === 'removePermission') {
        delete newUserList[selectedUserIndex].modifiableClaims[newValue];
        // Remove all admin claims if 'allowModify' was removed
        if (newValue === 'allowModify') {
          userAdminClaims.forEach(claim => {
            delete newUserList[selectedUserIndex].modifiableClaims[claim];
          });
        }
      } else {
        this.validate(field, newValue);
        newUserList[selectedUserIndex][field] = newValue;
      }
      return {userList: newUserList, formTouched: {...formTouched, [field]: true}};
    });
  };

  validate = (field, newValue) => {
    const {userList, selectedUserIndex, formErrors} = this.state;
    let newErrors = Object.assign({}, formErrors);
    if (field === 'displayName') {
      if (newValue.indexOf(' ') >= 0) {
        newErrors.displayName = 'Please remove spaces';
      } else if (field === 'displayName' && newValue.indexOf(' ') < 0) {
        delete newErrors.displayName;
      }
    } else if (field === 'email') {
      if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(newValue)) {
        newErrors.email = 'Invalid email address';
      } else if (/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(newValue)) {
        delete newErrors.email;
      }
    } else if (field === 'password') {
      if (newValue.length < 6) {
        newErrors.password = 'Password must be at least 6 characters';
      } else if (newValue.length >= 6) {
        delete newErrors.password;
      }
    } else if (field === 'confirmPassword') {
      if (newValue !== userList[selectedUserIndex].password) {
        newErrors.confirmPassword = 'Passwords do not match';
      } else if (newValue === userList[selectedUserIndex].password) {
        delete newErrors.confirmPassword;
      }
    }
    this.setState({formErrors: newErrors});
  };

  handleSelectUser = (newSelectedUserIndex) => {
    const {userList, selectedUserIndex, selectedUserOriginalDetails} = this.state;
    let newSelectedUserOriginalDetails = {};

    if (selectedUserIndex && !userList[selectedUserIndex].isSaved) {
      if (newSelectedUserIndex === 0) {
        userList.splice(selectedUserIndex, 1);
      } else {
        userList.splice(selectedUserIndex, 1);
        newSelectedUserIndex--;
      }
    } else if (Object.keys(selectedUserOriginalDetails).length > 1) {
      userList[selectedUserIndex] = selectedUserOriginalDetails;
    }
    // If new user was selected
    if (newSelectedUserIndex === 0) {
      newSelectedUserIndex++;
      newSelectedUserOriginalDetails = {
        displayName: '',
        email: '',
        nonModifiableClaims: {},
        modifiableClaims: {},
        uid: '',
        disabled: '',
        isSaved: false,
      };
      userList.splice(newSelectedUserIndex, 0, newSelectedUserOriginalDetails);
    } else {
      newSelectedUserOriginalDetails = JSON.parse(JSON.stringify(userList[newSelectedUserIndex]));
    }
    this.setState({userList, selectedUserIndex: newSelectedUserIndex, selectedUserOriginalDetails: newSelectedUserOriginalDetails, formTouched: {}, formErrors: {}});
  };

  handleReset = () => {
    const {originalUserList} = this.state;
    this.setState({
      userList: JSON.parse(JSON.stringify(originalUserList)),
      selectedUserIndex: null,
      selectedUserOriginalDetails : {},
      formTouched: {},
      formErrors: {},
    });
  };

  handleSuccessReset = () => {
    const {userList} = this.state;
    userList.splice(0, 1);
    const sortedList = userList.sort((a, b) => {
      const x = a.displayName.toLowerCase();
      const y = b.displayName.toLowerCase();
      if (x < y) {return -1;}
      if (x > y) {return 1;}
      return 0;
    });
    userList.unshift({
      displayName: 'New User',
      email: '',
      nonModifiableClaims: {},
      modifiableClaims: {},
      uid: '',
      disabled: false,
      isSaved: false,
    });
    this.setState({
      originalUserList: JSON.parse(JSON.stringify(sortedList)),
      userList: sortedList,
      selectedUserIndex: null,
      selectedUserOriginalDetails : {},
      formTouched: {},
      formErrors: {},
    });
  };

  handleClose = () => {
    this.setState({ modalOpen: false });
  };

  serverTimeout = () => {
    const {isSubmitting} = this.state;
    if (isSubmitting) {
      this.requestTimeout = undefined;
      clearTimeout(this.requestTimeout);
      this.requestTimeout = setTimeout(() => {
        if (isSubmitting && !this.state.error) {
          this.setState({
            error: 'The request has timed out.',
            errorCode: 408,
            isSubmitting: false,
          });
        }
      }, 10000);
    }
  };

  handleError = (response) => {
    if (!response.ok) {
      const errorObj = {
        error: response.statusText,
        errorCode: response.status,
      };
      throw errorObj;
    }
    return response;
  };

  handleSubmit = () => {
    const {apiUrl, userList, selectedUserIndex, selectedUserOriginalDetails} = this.state;
    const selectedUserDetails = userList[selectedUserIndex];
    // console.log('selectedUserDetails');
    // console.log(selectedUserDetails);
    let loadingModalText = `Updating user: ${selectedUserDetails.displayName}...`;
    let successModalText = `User '${selectedUserDetails.displayName}' successfully updated!`;
    if (!selectedUserDetails.isSaved) {
      loadingModalText = `Creating user: ${selectedUserDetails.displayName}...`;
      successModalText = `User '${selectedUserDetails.displayName}' successfully created!`;
    }
    this.setState({
      error: false,
      errorCode: null,
      modalState: 'loading',
      modalOpen: true,
      modalText: loadingModalText,
      isSubmitting: true,
    });
    if (!selectedUserDetails.isSaved) {
      // Create new user with POST
      firebase.auth().currentUser.getIdToken(/* forceRefresh */ true).then((idToken) => {
        fetch(`${apiUrl}/admin/users`, {method: 'POST', headers: {'Content-Type': 'application/json', 'Authorization': idToken},
        body: JSON.stringify({
          displayName: selectedUserDetails.displayName,
          email: selectedUserDetails.email,
          password: selectedUserDetails.password,
          permissions: {...selectedUserDetails.modifiableClaims, ...selectedUserDetails.nonModifiableClaims},
        })
      })
      .then(this.handleError)
      .then(response => response.json().then(responseJSON => {
        // console.log('userList[selectedUserIndex]');
        // console.log(userList[selectedUserIndex]);
        userList[selectedUserIndex].uid = responseJSON.uid;
        userList[selectedUserIndex].isSaved = true;
        userList[selectedUserIndex].password = '';
        userList[selectedUserIndex].confirmPassword = '';
        userList[selectedUserIndex].disabled = false;
        // console.log('userList[selectedUserIndex]');
        // console.log(userList[selectedUserIndex]);
        this.setState({
          modalState: 'success',
          modalOpen: true,
          modalText: successModalText,
          userList,
        });
        this.handleSuccessReset();
      }))
      .catch(({error, errorCode}) => {
        if (error && errorCode) {
          this.setState({
            modalState: 'failure',
            modalOpen: true,
            modalText: `Encountered a ${errorCode} error: ${error}`,
          });
        }
      })
      .finally(this.setState({isSubmitting: false}));
      })
      .catch(({error, errorCode}) => this.setState({error, errorCode}));
      this.serverTimeout();
    } else {
      // Update existing user with POST
      firebase.auth().currentUser.getIdToken(/* forceRefresh */ true).then((idToken) => {
        fetch(`${apiUrl}/admin/users`, {
          method: 'PUT', headers: {'Content-Type': 'application/json', 'Authorization': idToken},
          body: JSON.stringify({
            displayName: selectedUserDetails.displayName,
            email: selectedUserDetails.email,
            password: selectedUserDetails.password,
            newPermissions: {...selectedUserDetails.modifiableClaims, ...selectedUserDetails.nonModifiableClaims},
            oldPermissions: {...selectedUserOriginalDetails.modifiableClaims, ...selectedUserOriginalDetails.nonModifiableClaims},
            uid: selectedUserDetails.uid,
            disabled: selectedUserDetails.disabled,
          })
        })
        .then(this.handleError)
        .then(() => {
          userList[selectedUserIndex].password = '';
          userList[selectedUserIndex].confirmPassword = '';
          this.setState({
            userList,
            modalState: 'success',
            modalOpen: true,
            modalText: successModalText,
            selectedUserIndex: null,
          });
          this.handleSuccessReset();
        })
        .catch(({error, errorCode}) => {
          if (error && errorCode) {
            this.setState({
              modalState: 'failure',
              modalOpen: true,
              modalText: `Encountered a ${errorCode} error: ${error}`,
            });
          }
        })
        .finally(this.setState({isSubmitting: false}));
      });
      this.serverTimeout();
    }
  };

  handleDisable = () => {
    const {apiUrl, userList, selectedUserIndex, selectedUserOriginalDetails} = this.state;
    const selectedUserDetails = userList[selectedUserIndex];
    const loadingModalText = selectedUserDetails.disabled ? `Enabling user: ${selectedUserDetails.displayName}...` : `Disabling user: ${selectedUserDetails.displayName}...`;
    const successModalText = selectedUserDetails.disabled ? `User '${selectedUserDetails.displayName}' successfully enabled!`: `User '${selectedUserDetails.displayName}' successfully disabled!`;
    this.setState({
      error: false,
      errorCode: null,
      modalState: 'loading',
      modalOpen: true,
      modalText: loadingModalText,
      isSubmitting: true,
    });
    firebase.auth().currentUser.getIdToken(/* forceRefresh */ true).then((idToken) => {
      fetch(`${apiUrl}/admin/users`, {
        method: 'PUT', headers: {'Content-Type': 'application/json', 'Authorization': idToken},
        body: JSON.stringify({
          displayName: selectedUserDetails.displayName,
          email: selectedUserDetails.email,
          password: selectedUserDetails.password,
          newPermissions: {...selectedUserDetails.modifiableClaims, ...selectedUserDetails.nonModifiableClaims},
          oldPermissions: {...selectedUserOriginalDetails.modifiableClaims, ...selectedUserOriginalDetails.nonModifiableClaims},
          uid: selectedUserDetails.uid,
          disabled: !selectedUserDetails.disabled,
        })
      })
      .then(this.handleError)
      .then(() => {
        userList[selectedUserIndex].disabled = !selectedUserDetails.disabled;
        userList[selectedUserIndex].password = '';
        userList[selectedUserIndex].confirmPassword = '';
        this.setState({
          userList,
          modalState: 'success',
          modalOpen: true,
          modalText: successModalText,
          selectedUserIndex: null,
        });
        this.handleSuccessReset();
      })
      .catch(({error, errorCode}) => {
        if (error && errorCode) {
          this.setState({
            modalState: 'failure',
            modalOpen: true,
            modalText: `Encountered a ${errorCode} error: ${error}`,
          });
        }
      })
      .finally(this.setState({isSubmitting: false}));
    });
    this.serverTimeout();
  };

  handleDelete = () => {
    const {apiUrl, userList, selectedUserIndex} = this.state;
    const selectedUserDetails = userList[selectedUserIndex];
    const loadingModalText = `Deleting user: ${selectedUserDetails.displayName}...`;
    const successModalText = `User '${selectedUserDetails.displayName}' successfully deleted!`;
    if (window.confirm(`Are you sure you want to delete ${selectedUserDetails.displayName}?`)) {
      this.setState({
        error: false,
        errorCode: null,
        modalState: 'loading',
        modalOpen: true,
        modalText: loadingModalText,
        isSubmitting: true,
      });
      firebase.auth().currentUser.getIdToken(/* forceRefresh */ true).then((idToken) => {
        fetch(`${apiUrl}/admin/users/${selectedUserDetails.uid}`, {
          method: 'DELETE', headers: {'Content-Type': 'application/json', 'Authorization': idToken}
        })
        .then(this.handleError)
        .then(() => {
          userList.splice(selectedUserIndex, 1);
          this.setState({
            userList,
            modalState: 'success',
            modalOpen: true,
            modalText: successModalText,
            selectedUserIndex: null,
          });
          this.handleSuccessReset();
        })
        .catch(({error, errorCode}) => {
          if (error && errorCode) {
            this.setState({
              modalState: 'failure',
              modalOpen: true,
              modalText: `Encountered a ${errorCode} error: ${error}`,
            });
          }
        })
        .finally(this.setState({isSubmitting: false}));
      });
      this.serverTimeout();
    }
  };

  componentDidMount() {
    const appContext = this.props.appContext;
    let availablePermissions = {};
    let clientList = [];
    // Fetch the user's claims from Firebase
    firebase.auth().currentUser.getIdTokenResult(true).then((idTokenResult) => {
      const claims = idTokenResult.claims;
      // Check if user has permission to use this admin microservice
      if (claims.userManagement) {
        // Get list of current clients
        appContext.firestore.collection('clients').get().then(clientsSnapshot => {
          clientsSnapshot.forEach(client => {
            // Filter out clients this user does not have claims for
            if (claims[client.id]) {
              clientList.push(client.id)
            }
          });
          // Filter out admin rights this user does not have claims for
          const userAdminClaims = appContext.adminClaims.filter(claim => claims[claim]);
          // Push available claims to permissions object and save all to state
          clientList.forEach(elem => availablePermissions[elem] = true);
          userAdminClaims.forEach(elem => availablePermissions[elem] = true);
          // Get a list of users with their claims from Admin API
          firebase.auth().currentUser.getIdToken(/* forceRefresh */ true).then((idToken) => {
            fetch(`${appContext.apiUrl}/admin/users`,
              {
                method: "GET",
                headers: {
                  'Content-Type': 'application/json',
                  'Authorization': idToken,
              }
            })
            .then(this.handleError)
            .then(response => response.json()
            .then(responseJSON => {
              const sortedUsers = responseJSON.users.sort((a, b) => {
                const x = a.displayName.toLowerCase();
                const y = b.displayName.toLowerCase();
                if (x < y) {return -1;}
                if (x > y) {return 1;}
                return 0;
              });
              const userList = sortedUsers.map(user => ({...user, isSaved: true}));
              userList.unshift({
                displayName: 'New User',
                email: '',
                nonModifiableClaims: {},
                modifiableClaims: {},
                uid: '',
                disabled: false,
                isSaved: false,
              });
              this.setState({
                loading: false,
                userClaims: idTokenResult.claims,
                userAdminClaims,
                clientList,
                availablePermissions,
                userList,
                originalUserList: JSON.parse(JSON.stringify(userList)),
              });
            }))
            .catch(({error, errorCode}) => {
              if (error && errorCode) {
                this.setState({error, errorCode});
              }
            })
          })
          .catch(({error, errorCode}) => this.setState({error, errorCode}));
        })
        .catch(error => {
          console.log(error);
          this.setState({userClaims: idTokenResult.claims, loading: false});
        });
      } else {
        this.setState({error: 'Insufficient Permissions', errorCode: 403})
      }
    })
    .catch((error) => {
      console.log(error);
    });
  }

  render() {
    const {classes, appContext} = this.props;
    const {
      userList,
      selectedUserIndex,
      error,
      errorCode,
      loading,
      availablePermissions,
      modalOpen,
      modalText,
      modalState,
      userClaims,
      formTouched,
      formErrors,
      isSubmitting,
    } = this.state;
    const selectedUser = userList[selectedUserIndex];

    if (!loading) {
      return (
        <div className={classes.outerDiv}>
          <form>
            <Paper className={classes.settingsPaperContainer}>
              <div className={classes.settingsDiv}>
                <div className={classes.userListDiv}>
                  <ListText
                    items={userList.map(user => ({primary: user.displayName, secondary: user.email, disabled: user.disabled}))}
                    selectedIndex={selectedUserIndex}
                    title='Select User'
                    onListItemSelect={this.handleSelectUser}
                    dimensions={appContext.dimensions}
                  />
                </div>
                <div className={classes.inputContainer}>
                  {selectedUserIndex !== null && <React.Fragment>
                    <div className={classes.newUserDiv}>
                      <Typography variant='subheading'>Enter User Details</Typography>
                      <FormControl required fullWidth error={formTouched.displayName && !!formErrors.displayName}>
                        <InputLabel>First Name</InputLabel>
                        <Input
                          value={selectedUser.displayName}
                          onChange={(e) => {this.handleUpdateField('displayName', e.target.value);}}
                          name='displayName'
                          label='First Name'
                          type='text'
                        />
                        <FormHelperText>{formTouched.displayName && formErrors.displayName}</FormHelperText>
                      </FormControl>
                      <FormControl required fullWidth error={formTouched.email && !!formErrors.email}>
                        <InputLabel>Email</InputLabel>
                        <Input
                          value={selectedUser.email}
                          onChange={(e) => {this.handleUpdateField('email', e.target.value);}}
                          name='email'
                          label='Email'
                          type='email'
                        />
                        <FormHelperText>{formTouched.email && formErrors.email}</FormHelperText>
                      </FormControl>
                      <FormControl fullWidth error={formTouched.password && !!formErrors.password}>
                        <InputLabel>{selectedUserIndex !== null && userList[selectedUserIndex].isSaved ? 'Reset Password' : 'Password'}</InputLabel>
                        <Input
                          value={selectedUser.password}
                          onChange={(e) => {this.handleUpdateField('password', e.target.value);}}
                          name='password'
                          label='Reset Password'
                          type='password'
                        />
                        <FormHelperText>{formTouched.password && formErrors.password}</FormHelperText>
                      </FormControl>
                      <FormControl fullWidth error={formTouched.confirmPassword && !!formErrors.confirmPassword}>
                        <InputLabel>Confirm Password</InputLabel>
                        <Input
                          value={selectedUser.confirmPassword}
                          onChange={(e) => {this.handleUpdateField('confirmPassword', e.target.value);}}
                          name='confirmPassword'
                          label='Confirm Password'
                          type='password'
                        />
                        <FormHelperText>{formTouched.confirmPassword && formErrors.confirmPassword}</FormHelperText>
                      </FormControl>
                    </div>
                    <div className={classes.permissionsDiv}>
                      <FormControl fullWidth error={formTouched.addPermission && !!formErrors.addPermission}>
                        <div className={classes.selectedPermissionsDiv}>
                          <Typography variant='subheading'>Available Permissions</Typography>
                          {Object.keys(availablePermissions).filter(elem => !selectedUser.modifiableClaims[elem]).map(elem => {
                            return <Chip className={classes.chip} label={elem} clickable onClick={() => this.handleUpdateField('addPermission', elem)}/>;
                          })}
                        </div>
                        <FormHelperText>{formTouched.addPermission && formErrors.addPermission}</FormHelperText>
                      </FormControl>
                    </div>
                    <div className={classes.permissionsDiv}>
                      <FormControl required fullWidth error={formTouched.removePermission && !!formErrors.removePermission}>
                        <div className={classes.selectedPermissionsDiv}>
                          <Typography variant='subheading'>Selected Permissions</Typography>
                          {Object.keys(selectedUser.modifiableClaims).filter(elem => selectedUser.modifiableClaims[elem] === true).map(elem => {
                            return <Chip className={classes.chip} label={elem} clickable onClick={() => this.handleUpdateField('removePermission', elem)}/>;
                          })}
                          {Object.keys(selectedUser.nonModifiableClaims).map(elem => {
                            return <Chip className={classes.chipDisabled} label={elem} clickable disabled/>;
                          })}
                        </div>
                        <FormHelperText>{formTouched.removePermission && formErrors.removePermission}</FormHelperText>
                      </FormControl>
                    </div>
                  </React.Fragment>}
                </div>
              </div>
              <div className={classes.submitDiv}>
                <Button onClick={this.handleReset} className={classes.button}>Reset</Button>
                <div>
                  {userClaims.allowDelete && <Button
                    className={classes.deleteButton}
                    variant='raised'
                    onClick={this.handleDelete}
                    disabled={(selectedUserIndex && !userList[selectedUserIndex].isSaved) || selectedUserIndex === null || isSubmitting}
                  >
                    Delete User
                  </Button>}
                  {userClaims.allowDisable && <Button
                    className={classes.disableButton}
                    variant='raised'
                    onClick={this.handleDisable}
                    disabled={(selectedUserIndex && !userList[selectedUserIndex].isSaved) || selectedUserIndex === null || isSubmitting}
                  >
                    {selectedUser && selectedUser.disabled ? 'Enable User' : 'Disable User'}
                  </Button>}
                  <Button
                    className={classes.button}
                    variant='raised'
                    onClick={this.handleSubmit}
                    disabled={
                      isSubmitting || (selectedUser && selectedUser.disabled) || selectedUserIndex === null ||
                      (formTouched.addPermission && !!formErrors.addPermission) ||
                      (formTouched.password && !!formErrors.password) ||
                      (formTouched.confirmPassword && !!formErrors.confirmPassword) ||
                      (formTouched.email && !!formErrors.email) ||
                      (formTouched.displayName && formErrors.displayName)
                  }>
                    {selectedUserIndex !== null && userList[selectedUserIndex].isSaved ? 'Update User' : 'Create User'}
                  </Button>
                </div>
                <LoadingModal
                  open={modalOpen}
                  state={modalState}
                  text={modalText}
                  handleClose={this.handleClose}
                  totalWidth={appContext.dimensions.width}
                  totalHeight={appContext.dimensions.height}
                  adjustWidth={appContext.drawerOpen ? appContext.dimensions.openSideNav : this.props.appContext.dimensions.collapsedSideNav}
                />
              </div>
            </Paper>
          </form>
        </div>
      );
    } else if (error) {
      return <ErrorPage error={error} errorCode={errorCode}/>
    } else {
      return <Loading text='Loading Users'/>
    }
  }
}

UserManagement.propTypes = {
  appContext: PropTypes.object.isRequired,
};

export default withStyles(styles)(UserManagement);
