import React from 'react';
import Highlight from "./highlight";
import {
  AreaSeries,
  Borders,
  Crosshair,
  DiscreteColorLegend,
  HorizontalGridLines,
  LineSeries,
  MarkSeries,
  makeVisFlexible,
  VerticalBarSeries,
  VerticalGridLines,
  XAxis,
  XYPlot,
  YAxis,
  LabelSeries
} from 'react-vis';

const FlexibleXYPlot = makeVisFlexible(XYPlot);

const MultiChart = (props) => {
  const {
    interaction,
    data,
    format,
    xAxis,
    yAxis,
    crosshair
  } = props;
  if (!data) {
    return <div name='No data passed'/>;
  }
    
  const {
    lineSeriesArr,
    markSeries,
    areaSeries,
    vertBarSeriesArr,
  } = data;

  const {
    setLastDrawLocation,
    lastDrawLocation,
    highlightXArr,
    highlighted
  } = interaction;

  const {
    colors,
    horGridLines,
    verGridLines,
    horGridLineValues,
    verGridLineValues,
    legend,
    xType,
    xDistance,
    labelData,
    animation,
  } = format;

  const {
    values,
    titleFormat,
    itemsFormat
  } = crosshair;

  const maxValue = Math.max(...[
    ...([].concat(...(lineSeriesArr || []))).map(d => d.y),
    ...(markSeries || []).map(d => d.y),
    ...(areaSeries || []).map(d => d.y),
    ...(vertBarSeriesArr || []).map(d => d.y)
  ]);

  const yDomain = [0, 1.02 * maxValue];

  const legendItems = legend ? Object.keys(legend).map((key) => ({title: legend[key], color: colors[key], disabled: false})) : [];

  const makeLabelValuesX = (series, maxVal) => {
    const uniqueSortedSeries = Array.from(new Set(series.sort((a, b) => a - b)));
    const labelValuesSubset = [];
    const delta = Math.ceil(uniqueSortedSeries.length / (maxVal-1));
    if (delta) {
      for (let i = 0; i <= uniqueSortedSeries.length; i=i+delta) {
        labelValuesSubset.push(uniqueSortedSeries[i]);
        if (i + delta >= uniqueSortedSeries.length) {
          if (labelValuesSubset[labelValuesSubset.length-1] === uniqueSortedSeries[uniqueSortedSeries.length-2]) {
            labelValuesSubset.pop();
          }
          labelValuesSubset.push(uniqueSortedSeries[uniqueSortedSeries.length-1]);
          break;
        }
      }
    }
    return labelValuesSubset;
  }

  return (
    <React.Fragment>
      {legend && <div className="legend">
        <DiscreteColorLegend
          orientation='horizontal'
          items={legendItems}
          className={'legend'}
        />
      </div>}
      <FlexibleXYPlot
        margin={{top: 20, bottom: 40, left: 50, right: 20}}
        onMouseLeave={() => highlightXArr && highlightXArr.map(func => func(null, 0))}
        yDomain={yDomain}
        xDomain={lastDrawLocation && [lastDrawLocation.left, lastDrawLocation.right]}
        xType={xType && xType}
        xDistance={xDistance && xDistance}
        animation={animation}
      >
        {horGridLines && <HorizontalGridLines tickValues={horGridLineValues} />}
        {verGridLines && <VerticalGridLines tickValues={verGridLineValues} />}
        {lineSeriesArr && lineSeriesArr.map((lineSeries, index) => {
          return (<LineSeries
            data={lineSeries}
            onNearestX={highlightXArr && highlightXArr[index]}
            stroke={colors.line[index]}
            strokeWidth={2}
          />);
        })}
        {areaSeries && <AreaSeries
          opacity={0.5}
          curve="curveNatural"
          data={areaSeries}
          fill={colors.area}
        />}
        {markSeries && <MarkSeries
          size={1}
          data={markSeries}
          stroke={colors.mark}
          fill={colors.markFill}
          colorType='literal'
          sizeType='literal'
        />}
        {highlighted && <LineSeries
          data={[
            {x: highlighted && highlighted.x, y: yDomain[0]},
            {x: highlighted && highlighted.x, y: yDomain[1]}
          ]}
          stroke='#ececec'
          strokeWidth={2}
        />}
        {highlighted && <MarkSeries
          data={[{
            x: highlighted && highlighted.x,
            y: highlighted && highlighted.y
          }]}
          strokeWidth={5}
          stroke={colors.line}
          fill='white'
        />}
        <Borders style={{all: {fill: '#fff'}}} />
        <XAxis
          title={xAxis && xAxis.title && xAxis.title}
          tickSize={xAxis && xAxis.tickSize && xAxis.tickSize}
          tickLabelAngle={xAxis && xAxis.tickAlign && xAxis.tickAlign}
          tickTotal={xAxis && xAxis.tickTotal && xAxis.tickTotal}
          tickValues={xAxis && xAxis.values && xAxis.values.length && xAxis.values.length > 20 ? makeLabelValuesX(xAxis.values, 20) : xAxis.values}
          tickFormat={xAxis && xAxis.tickFormat && xAxis.tickFormat}
        />
        <YAxis
          title={yAxis && yAxis.title && yAxis.title}
          tickSize={yAxis && yAxis.tickSize && yAxis.tickSize}
          tickLabelAngle={yAxis && yAxis.tickAlign && yAxis.tickAlign}
          tickTotal={yAxis && yAxis.tickTotal && yAxis.tickTotal}
          tickValues={yAxis && yAxis && yAxis.values}
          tickFormat={yAxis && yAxis.tickFormat && yAxis.tickFormat}
        />
        {vertBarSeriesArr && vertBarSeriesArr.map((vertBarSeries, index) => {
          return (<VerticalBarSeries
            data={vertBarSeries}
            stroke={colors.vertBar[index]}
          />);
        })}
        {labelData && <LabelSeries data={labelData} getLabel={d => d.x} />}
        {setLastDrawLocation && <Highlight onBrushEnd={area => setLastDrawLocation(area)}/>}
        <Crosshair
          values={values}
          className={'test-class-name'}
          titleFormat={titleFormat}
          itemsFormat={itemsFormat}
        />
      </FlexibleXYPlot>
    </React.Fragment>
  );
};

export default MultiChart;
