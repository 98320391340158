import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Icon from '@material-ui/core/Icon';
import {Link} from 'react-router-dom';

const styles = theme => ({
  outer: {
    alignSelf: 'flex-start',
    flexGrow: 1,
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    height: theme.sizes.topPanel,
    marginLeft: '3%'
  },
  iconHome: {
    color: theme.palette.primary.main,
    marginRight: '1%',
  },
  iconArrow: {
    color: theme.palette.primary.main,
  },
  link:{
    textDecoration: 'none',
  },
});

const BreadCrumb = (props) => {
  const { classes, microservices } = props;
  const msWithHome = microservices.slice();
  msWithHome.push({
    title: 'Home',
    path: '/',
    icon: 'home',
  });
  const bCArray = window.location.pathname.substring(1).split('/');
  const currentMS = msWithHome.find((elem) => {return elem.path.substring(1) === bCArray[0]});
  const hasUpperCase = (str) => str.toLowerCase() !== str;
  const toTitleCase = (str) => {
    let newStr = '';
    if (hasUpperCase(str) && currentMS.children) {
      const childMS = currentMS.children.find(elem => elem.path === window.location.pathname);
      if (childMS) {
        newStr = childMS.title;  
      } else {
        newStr = str.replace(/\w\S*/g, (txt) => txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase());
      }
    } else {
      newStr = str.replace(/\w\S*/g, (txt) => txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase());
    }
    return newStr;
  };
  return (
    <div className={classes.outer}>
      {currentMS && <Icon classes={{root: classes.iconHome}}>
        {currentMS.icon}
      </Icon>}
      {currentMS && <Link to={currentMS.path} className={classes.link}>
        <Typography variant="subheading">
          {currentMS.title}
        </Typography>
      </Link>}
      {bCArray.map((item, index) => {
        return (
          index > 0 && 
            <React.Fragment key={index}>
              <Icon classes={{root: classes.iconArrow}}>
                chevron_right
              </Icon>
              <Typography variant="subheading">
                {toTitleCase(item)}
              </Typography>
            </React.Fragment>
        );
      })}
    </div>
  );
}

BreadCrumb.defaultProps = {
  microservices: [],
};

BreadCrumb.propTypes = {
  microservices: PropTypes.array.isRequired,
};

export default withStyles(styles)(BreadCrumb);