import React from 'react';
import PropTypes from 'prop-types';
import Paper from '@material-ui/core/Paper';
import {withStyles} from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import Stepper from '@material-ui/core/Stepper';
import Step from '@material-ui/core/Step';
import StepLabel from '@material-ui/core/StepLabel';
import StepConnector from '@material-ui/core/StepConnector';
import firebase from 'firebase/app';
import 'firebase/auth';
import Loading from '../app/components/Loading';
import LoadingModal from '../app/components/LoadingModal';
import ErrorPage from '../app/components/ErrorPage';
import ClientSelection from './ClientSelection';
import ClientDetails from './ClientDetails';
import MicroserviceConfig from './MicroserviceConfig';
import DashboardConfig from './DashboardConfig';
import MicroserviceSelection from './MicroserviceSelection';
import Review from './Review';

const generateID = () => {
  return String.fromCharCode(65 + Math.floor(Math.random() * 26)) + Math.floor(Date.now() * Math.random()).toString(8);
};

const styles = (theme) => ({
  outerDiv: {
    flexGrow: 1,
    display: 'flex',
    justifyContent: 'center',
    marginTop: '25px',
    marginBottom: '25px',
  },
  settingsPaperContainer: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    padding: '25px',
    width: '80%',
  },
  form: {
    flexGrow: 1,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start',
  },
  stepper: {
    height: '110px',
  },
  step: {
  },
  controlDiv: {
    width: '100%',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginTop: '25px',
  },
  msList: {
    flexGrow: 1,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    alignItems: 'center',
  },
  button: {
    height: '36px',
    background: theme.palette.primary.main,
    color: 'white',
  },
  downButton: {
    height: '36px',
    color: 'black',
    fontSize: '18px',
    background: 'white',
    padding: '0px',
  },
  upButton: {
    height: '36px',
    color: 'black',
    marginRight: '10px',
    fontSize: '18px',
    background: 'white',
    padding: '0px',
  },
  onTheLeftButton: {
    height: '36px',
    background: theme.palette.primary.main,
    color: 'white',
    marginLeft: '15px',
  },
  msListItem: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-evenly',
    alignItems: 'center',
    height: '36px',
    width: '100%',
    marginBottom: '5px',
    marginTop: '10px',
  },
  msConfigButtonsContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    height: '36px',
    width: '80%',
    marginTop: '5px',
  },
  msConfigAddButtonContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-end',
    alignItems: 'center',
    height: '36px',
    width: '80%',
    marginTop: '100px',
  },
  msConfigArrayListContainer: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    alignItems: 'center',
    width: '100%',
    marginBottom: '10px',
    marginTop: '5px',
  },
  msConfigArrayListItem: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    height: '68px',
    width: '90%',
    marginBottom: '1px',
    marginTop: '1px',
  },
  dashboardListItem: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-evenly',
    alignItems: 'center',
    height: '68px',
    width: '100%',
    marginBottom: '1px',
    marginTop: '1px',
  },
  reviewListTitle: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-start',
    alignItems: 'center',
    height: '36px',
    width: '100%',
    marginBottom: '5px',
    marginTop: '10px',
  },
  reviewListItem: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    alignSelf: 'flex-start',
    height: '36px',
    width: '80%',
    marginBottom: '5px',
    marginTop: '5px',
    marginLeft: '100px',
  },
  reviewListItemNoIcon: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-end',
    alignItems: 'center',
    alignSelf: 'flex-start',
    height: '36px',
    width: '80%',
    marginBottom: '5px',
    marginTop: '5px',
    marginLeft: '100px',
  },
  msConfigSrollbarChild: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'flex-start',
    flexGrow: 1,
    height: '100%',
    width: '100%',
  },
  scrollbarChild: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    flexGrow: 1,
    height: '100%',
    width: '100%',
  },
  dashboardExpansionPanel: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    width: '80%',
  },
  titleDiv: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-start',
    alignItems: 'center',
    height: '35px',
    width: '300px',
  },
  indexDiv: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: '100%',
    width: '140px',
  },
  stateDiv: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: '100%',
    width: '240px',
  },
  dataPointInput: {
    width: '49%',
    height: '68px',
  },
  wideForm: {
    width: '90%',
    height: '68px',
  },
  fullForm: {
    width: '90%',
  },
  halfForm: {
    width: '35%',
  },
  isUser: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    width: '90%',
    height: '48px',
  },
  scrollbar: {
    backgroundColor: theme.palette.primary.main,
    opacity: '0.4',
  },
  reviewTitle: {
    fontSize: '18px',
  },
  column: {
    maxWidth: '33.33%',
    flexBasis: '33.33%',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    marginRight: theme.sizes.itemSeparation,
  },
  dashboardLinkRequired: {
    color: '#f44336',
    fontSize: '14px',
  },
  iconHome: {
    marginRight: '16px',
  },
  titleIconContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-start',
    alignItems: 'center',
  },
  connectorActive: {
    '& $connectorLine': {
      borderColor: theme.palette.secondary.main,
    },
  },
  connectorCompleted: {
    '& $connectorLine': {
      borderColor: theme.palette.primary.main,
    },
  },
  connectorDisabled: {
    '& $connectorLine': {
      borderColor: theme.palette.grey[100],
    },
  },
  connectorLine: {
    transition: theme.transitions.create('border-color'),
  },
  userSelectionHeading: {
    width: '90%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    marginTop: '25px',
  },
  userSelectionDiv: {
    width: '90%',
    flexGrow: 1,
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-start',
    alignItems: 'flex-start',
  },
  userSelectionHalfDiv: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    alignItems: 'center',
    width: '50%',
    height: '100%',
  },
  disableButton: {
    background: '#ff9411',
    color: 'white',
    marginRight: '15px',
  },
  deleteButton: {
    background: theme.palette.error.main,
    color: 'white',
    marginRight: '15px',
  },
});

const sortByProp = (a, b, prop) => {
  const x = a[prop].toLowerCase();
  const y = b[prop].toLowerCase();
  if (x < y) {return -1;}
  if (x > y) {return 1;}
  return 0;
};

class ClientManagement extends React.Component {
  state = {
    userClaims: {},
    userAdminClaims: {},
    loading: true,
    error: false,
    errorCode: null,
    modalOpen: false,
    modalText: '',
    modalState: 'loading',
    apiUrl: this.props.appContext.apiUrl,
    dimensions: this.props.appContext.dimensions,
    microservices: [],
    // Steps the user will follow to create a client
    steps: ['Client Selection', 'Client Details', 'Microservice Selection', 'Microservice Config', 'Dashboard Config', 'Review'],
    /* Initially no microservices are selected, so steps pertaining to specific microservice configurtion are optional
       We indicate this by adding the index of optional steps from the array above, to the array below */
    optionalSteps: [3, 4],
    // User starts on step zero
    activeStep: 0,
    skipped: new Set(),
    clients: [],
    clientsList: [],
    selectedClientIndex: 0,
    formTouched: {},
    formErrors: {},
    isSubmitting: false,
  };

  handleUpdateField = (field, newValue) => {
    this.setState(prevState => {
      const {clients, selectedClientIndex, formTouched} = prevState;
      const newClients = JSON.parse(JSON.stringify(clients));
      const newTouched =  Object.assign({}, formTouched);
      newTouched[field] = true;
      newClients[selectedClientIndex][field] = newValue;
      this.validate(field, newValue);
      return {clients: newClients, formTouched: newTouched};
    });
  };

  handleUpdateMicroservice = (field, newValue, msIndex) => {
    this.setState(prevState => {
      const {clients, selectedClientIndex, formTouched} = prevState;
      const newClients = JSON.parse(JSON.stringify(clients));
      const newTouched =  Object.assign({}, formTouched);
      newTouched[field] = true;
      newClients[selectedClientIndex].microservices[msIndex][field] = newValue;
      return {clients: newClients, formTouched: newTouched};
    });
  };

  // MS Data point
  // onChange={(e) => {handleUpdateMicroserviceData(fieldRef, e.target.value, microservice.isDashboard, msIndex, childIndex);}}
  // onChange={(e) => {handleUpdateMicroserviceData(fieldRef, e.target.value, msIndex, microservice.isDashboard, childIndex);}}
  // MultiDataPoint
  // onChange={(e) => {handleUpdateMicroserviceData(fieldRef, e.target.value, microservice.isDashboard, msIndex, childIndex, dataIndex, dataPointField);}}
  // onChange={(e) => {handleUpdateMicroserviceData(fieldRef, e.target.value, msIndex, microservice.isDashboard, dataIndex, dataPointField, childIndex);}}
  // Dashboard Config
  // onChange={(e) => {handleUpdateMicroserviceData(title, e.target.value, true, msIndex, childIndex);}}
  // onChange={(e) => {handleUpdateMicroserviceData(title, e.target.value, msIndex, true, childIndex, 'data');}}
  // onChange={title === 'Top Level Dashboards' ? (e) => {handleUpdateMicroserviceData(title, e.target.value, msIndex, true, childIndex, 'data');} : (e) => {handleUpdateMicroserviceData(title, e.target.value, msIndex, true, childIndex, 'data', childIndex);}}
  handleUpdateMicroserviceData = (fieldRef, newValue, isDashboard, msIndex, childIndex=-1, dataIndex=-1, dataPoint=null) => {
    this.setState(prevState => {
      // console.log(`fieldRef: "${fieldRef}" newValue: "${newValue}" msIndex: "${msIndex}" dataIndex: "${dataIndex}" dataPoint: "${dataPoint}" childIndex: "${childIndex}"`);
      const {clients, selectedClientIndex, formTouched} = prevState;
      const newClients = JSON.parse(JSON.stringify(clients));
      const newTouched =  Object.assign({}, formTouched);
      newTouched[fieldRef] = true;
      if (childIndex > -1 && childIndex !== false) {
        // console.log('in child with value: ', newValue);
        if (dataIndex !== -1 && dataPoint) {
          // console.log('1 current value: ', newClients[selectedClientIndex].microservices[msIndex]);
          // console.log('1 current value: ', newClients[selectedClientIndex].microservices[msIndex].data[dataIndex]);
          // console.log('1 current value: ', newClients[selectedClientIndex].microservices[msIndex].data[dataIndex][dataPoint]);
          newClients[selectedClientIndex].microservices[msIndex].children[childIndex].data[dataIndex][dataPoint] = newValue;
        } else {
          // console.log('2 current value: ', newClients[selectedClientIndex].microservices[msIndex].data);
          newClients[selectedClientIndex].microservices[msIndex].children[childIndex].data = newValue;
        }
      } else {
        // console.log('in parent with value: ', newValue);
        if (dataIndex !== -1 && dataPoint) {
          // console.log('1 current value: ', newClients[selectedClientIndex].microservices[msIndex]);
          // console.log('1 current value: ', newClients[selectedClientIndex].microservices[msIndex].data[dataIndex]);
          // console.log('1 current value: ', newClients[selectedClientIndex].microservices[msIndex].data[dataIndex][dataPoint]);
          newClients[selectedClientIndex].microservices[msIndex].data[dataIndex][dataPoint] = newValue;
        } else {
          // console.log('2 current value: ', newClients[selectedClientIndex].microservices[msIndex].data);
          newClients[selectedClientIndex].microservices[msIndex].data = newValue;
        }
      }
      this.validate(fieldRef, newValue, isDashboard, newClients[selectedClientIndex].microservices[msIndex].isDashboard);
      return {clients: newClients, formTouched: newTouched};
    });
  };

  validate = (field, newValue, isDashboard, isTopLevelDash=false) => {
    this.setState(prevState => {
      // console.log(`field: "${field}" newValue: "${newValue}" isDashboard: "${isDashboard}" isTopLevelDash: "${isTopLevelDash}"`)
      const {formErrors} = prevState;
      let newErrors = Object.assign({}, formErrors);
      if (field === 'name') {
        if (!newValue) {
          newErrors.name = 'Client name is required';
        } else if (newValue.length < 3) {
          newErrors.name = 'Please enter the full client name';
        } else if (newValue.length >= 3) {
          delete newErrors.name;
        }
      } else if (field === 'mainContactEmail') {
        if (!newValue) {
          newErrors.mainContactEmail = 'Main contact email is required';
        } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(newValue)) {
          newErrors.mainContactEmail = 'Invalid email address';
        } else if (/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(newValue)) {
          delete newErrors.mainContactEmail;
        }
      } else if (isDashboard) {
        if (/\s/.test(newValue)) {
          newErrors[field] = 'Links should not contain whitespace';
        } else if (isTopLevelDash && (!newValue || newValue === '')) {
          newErrors[field] = 'Links for top level dashboards are required';
        } else {
          delete newErrors[field];
        }
      } else {
        if (!newValue) {
          delete newErrors[field];
        } else if (field.substring(field.length-3, field.length) === 'apiUrl') {
          const newValueLen = Math.min(newValue.length, 37);
          if (newValue && newValue !== '' && newValue.substring(0, newValueLen) !== 'https://docs.google.com/presentation/'.substring(0, newValueLen)) {
            newErrors[field] = 'Link should start with "https://docs.google.com/presentation/"';
          } else {
            delete newErrors[field];
          }
        }
      }
      // console.log('newErrors');
      // console.log(newErrors);
      return {formErrors: newErrors};
    });
  };

  handleMoveUp = (info, dataIndex, msIndex, childIndex=-1) => {
    this.setState(prevState => {
      const {clients, selectedClientIndex} = prevState;
      const newClients = JSON.parse(JSON.stringify(clients));
      if (info === 'microservice') {
        const temp = newClients[selectedClientIndex].microservices[msIndex - 1];
        newClients[selectedClientIndex].microservices[msIndex - 1] = newClients[selectedClientIndex].microservices[msIndex];
        newClients[selectedClientIndex].microservices[msIndex] = temp;
      } else {
        if (childIndex > -1 && childIndex !== false) {
          const temp = newClients[selectedClientIndex].microservices[msIndex].children[childIndex].data[dataIndex - 1];
          newClients[selectedClientIndex].microservices[msIndex].children[childIndex].data[dataIndex - 1] = newClients[selectedClientIndex].microservices[msIndex].children[childIndex].data[dataIndex];
          newClients[selectedClientIndex].microservices[msIndex].children[childIndex].data[dataIndex] = temp;
        } else {
          const temp = newClients[selectedClientIndex].microservices[msIndex].data[dataIndex - 1];
          newClients[selectedClientIndex].microservices[msIndex].data[dataIndex - 1] = newClients[selectedClientIndex].microservices[msIndex].data[dataIndex];
          newClients[selectedClientIndex].microservices[msIndex].data[dataIndex] = temp;
        }
      }
      return {clients: newClients};
    });
  };

  handleMoveDown = (info, dataIndex, msIndex, childIndex=-1) => {
    this.setState(prevState => {
      const {clients, selectedClientIndex} = prevState;
      const newClients = JSON.parse(JSON.stringify(clients));
      if (info === 'microservice') {
        const temp = newClients[selectedClientIndex].microservices[msIndex + 1];
        newClients[selectedClientIndex].microservices[msIndex + 1] = newClients[selectedClientIndex].microservices[msIndex];
        newClients[selectedClientIndex].microservices[msIndex] = temp;
      } else {
        if (childIndex > -1 && childIndex !== false) {
          const temp = newClients[selectedClientIndex].microservices[msIndex].children[childIndex].data[dataIndex + 1];
          newClients[selectedClientIndex].microservices[msIndex].children[childIndex].data[dataIndex + 1] = newClients[selectedClientIndex].microservices[msIndex].children[childIndex].data[dataIndex];
          newClients[selectedClientIndex].microservices[msIndex].children[childIndex].data[dataIndex] = temp;
        } else {
          const temp = newClients[selectedClientIndex].microservices[msIndex].data[dataIndex + 1];
          newClients[selectedClientIndex].microservices[msIndex].data[dataIndex + 1] = newClients[selectedClientIndex].microservices[msIndex].data[dataIndex];
          newClients[selectedClientIndex].microservices[msIndex].data[dataIndex] = temp;
        }
      }
      return {clients: newClients};
    });
  };

  handleAddDataPoint = (data, msIndex, childIndex=-1) => {
    // Does this work without modifiying errors?
    this.setState(prevState => {
      const {clients, selectedClientIndex} = prevState;
      const newClients = JSON.parse(JSON.stringify(clients));
      if (childIndex > -1 && childIndex !== false) {
        newClients[selectedClientIndex].microservices[msIndex].children[childIndex].data.push(data);
      } else {
        newClients[selectedClientIndex].microservices[msIndex].data.push(data);
      }
      return {clients: newClients};
    });
  };

  handleRemoveDataPoint = (dataIndex, fieldRef, msIndex, childIndex=-1) => {
    // console.log('in handleRemoveDataPoint: ', fieldRef);
    this.setState(prevState => {
      const {clients, selectedClientIndex, formErrors} = prevState;
      const newClients = JSON.parse(JSON.stringify(clients));
      if (childIndex > -1 && childIndex !== false) {
        newClients[selectedClientIndex].microservices[msIndex].children.childIndex.data.splice(dataIndex, 1);
      } else {
        newClients[selectedClientIndex].microservices[msIndex].data.splice(dataIndex, 1);
      }
      let newErrors = Object.assign({}, formErrors);
      const errors = Object.keys(newErrors);
      // console.log(errors);
      errors.forEach((errorField, index) => {
        if (errorField.search(fieldRef) > -1) {
          // console.log('found errorField');
          delete newErrors[errorField];
        }
      });
      // console.log('saving errors');
      // console.log(newErrors);
      return {clients: newClients, formErrors: newErrors};
    });
  };

  // Logic for Material Stepper
  isStepOptional = step => {
    return this.state.optionalSteps.includes(step);
  };

  // Logic for Material Stepper
  handleNext = () => {
    const {activeStep} = this.state;
    let {skipped} = this.state;
    if (this.isStepSkipped(activeStep)) {
      skipped = new Set(skipped.values());
      skipped.delete(activeStep);
    }
    this.setState({activeStep: activeStep + 1, skipped});
  };

  // Logic for Material Stepper
  handleBack = () => {
    const {activeStep, skipped} = this.state;
    const skippedPages = Array.from(skipped);
    let newPageNum = activeStep - 1;
    while (skippedPages.includes(newPageNum)) {
      newPageNum = newPageNum - 1;
    }
    this.setState({activeStep: newPageNum});
  };

  // Logic for Material Stepper
  handleSkip = () => {
    const {activeStep} = this.state;
    if (this.isStepOptional(activeStep)) {
      this.setState(prevState => {
        const skipped = new Set(prevState.skipped.values());
        skipped.add(activeStep);
        return {activeStep: prevState.activeStep + 1, skipped};
      }); 
    }
  };

  // Logic for Material Stepper
  handleReset = () => {
    this.setState({activeStep: 0, optionalSteps: [3, 4], skipped: new Set(), selectedClientIndex: 0});
  };

  // Logic for Material Stepper
  isStepSkipped = step => {
    return this.state.skipped.has(step);
  };

  // Manual handling of download data check as it does nothing for now
  handleIsBigQuerySetup = () => {
    this.setState(prevState => {
      const {clients, selectedClientIndex} = prevState;
      const newClients = JSON.parse(JSON.stringify(clients));
      newClients[selectedClientIndex].isBigQuerySetup = !clients[selectedClientIndex].isBigQuerySetup;
      return {clients: newClients};
    });
  };

  // Logic for Material Stepper, changes what is optional depending on the users selection
  alterOptional = (topLevelDashEnabled, dataEnabled, optionalSteps) => {
    // console.log(`topLevelDashEnabled: ${topLevelDashEnabled}, dataEnabled: ${dataEnabled}, optionalSteps: ${optionalSteps}`);
    if (!topLevelDashEnabled && !dataEnabled && !optionalSteps.length) {
      this.setState({optionalSteps: [3, 4]});
    } else if (topLevelDashEnabled && !dataEnabled && !optionalSteps.length) {
      this.setState({optionalSteps: [3]});
    } else if (!topLevelDashEnabled && dataEnabled && !optionalSteps.length) {
      this.setState({optionalSteps: [4]});
    } else if (topLevelDashEnabled && dataEnabled && optionalSteps.length > 0) {
      this.setState({optionalSteps: []});
    } else if (!topLevelDashEnabled && !dataEnabled && optionalSteps.length === 1) {
      this.setState({optionalSteps: [3, 4]});
    } else if (topLevelDashEnabled && !dataEnabled && optionalSteps.length === 2) {
      this.setState({optionalSteps: [3]});
    } else if (!topLevelDashEnabled && dataEnabled && optionalSteps.length === 2) {
      this.setState({optionalSteps: [4]});
    }
  };

  // Closes the modal
  handleClose = () => this.setState({ modalOpen: false });

  //Moves the user object of the selected user from availableUsers to selectedUsers
  handleAddUser = selectedUsers => {
    this.setState(prevState => {
      const {clients, selectedClientIndex} = prevState;
      const newClients = JSON.parse(JSON.stringify(clients));
      const newSelectedUsers = JSON.parse(JSON.stringify(selectedUsers));
      newClients[selectedClientIndex].selectedUsers = newSelectedUsers;
      return {clients: newClients};
    });
  };

  //Moves the user object of the selected user from selectedUsers to availableUsers
  handleRemoveUser = uid => {
    const {appContext} = this.props;
    const userID = appContext.user.uid;
    if (uid !== userID) {
      this.setState(prevState => {
        const {clients, selectedClientIndex} = prevState;
        const newClients = JSON.parse(JSON.stringify(clients));
        const selectedUserIndex = clients[selectedClientIndex].selectedUsers.findIndex(user => user.uid === uid);
        newClients[selectedClientIndex].selectedUsers.splice(selectedUserIndex, 1);
        return {clients: newClients};
      });
    }
  };

  // Updates state with the new selected index
  handleUpdateSelectedClient = index => this.setState(prevState => {
    const {originalClients} = prevState;
    const newClients = JSON.parse(JSON.stringify(originalClients));
    return {clients: newClients, selectedClientIndex: index, formTouched: {}, formErrors: {}};
  });

  handleEnableAll = () => this.setState(prevState => {
    const {clients, selectedClientIndex} = prevState;
    const newClients = JSON.parse(JSON.stringify(clients));
    newClients[selectedClientIndex].microservices.forEach(elem => elem.state = 'enabled'); 
    return {clients: newClients};
  });

  handleRemoveAll = () => this.setState(prevState => {
    const {clients, selectedClientIndex} = prevState;
    const newClients = JSON.parse(JSON.stringify(clients));
    newClients[selectedClientIndex].microservices.forEach(elem => elem.state = 'removed'); 
    return {clients: newClients};
  });

  handleDemoAll = () => this.setState(prevState => {
    const {clients, selectedClientIndex} = prevState;
    const newClients = JSON.parse(JSON.stringify(clients));
    newClients[selectedClientIndex].microservices.forEach(elem => elem.state = 'inApp'); 
    return {clients: newClients};
  });

  serverTimeout = () => {
    const {isSubmitting} = this.state;
    if (isSubmitting) {
      this.requestTimeout = undefined;
      clearTimeout(this.requestTimeout);
      this.requestTimeout = setTimeout(() => {
        if (isSubmitting && !this.state.error) {
          this.setState({
            error: 'The request has timed out.',
            errorCode: 408,
            isSubmitting: false,
          });
        }
      }, 10000);
    }
  };

  handleError = (response) => {
    if (!response.ok) {
      const errorObj = {
        error: response.statusText,
        errorCode: response.status,
      };
      throw errorObj;
    }
    return response;
  };

  handleSubmit = () => {
    const {apiUrl, clients, selectedClientIndex, originalClients} = this.state;
    const method = selectedClientIndex > 0 ? 'PUT' : 'POST';
    const selectedClient = clients[selectedClientIndex];
    // console.log('handleSubmit with ', method);
    // console.log('Selected Client:');
    // console.log(selectedClient);
    this.setState({
      isSubmitting: true,
      error: false,
      errorCode: null,
      modalState: 'loading',
      modalOpen: true,
      modalText: selectedClientIndex > 0 ? `Updating client: ${selectedClient.name}...` : `Creating client: ${selectedClient.name}...`,
    });
    firebase.auth().currentUser.getIdToken(/* forceRefresh */ true).then((idToken) => {
      fetch(`${apiUrl}/admin/clients`,
        {
          method,
          headers: {
            'Content-Type': 'application/json',
            'Authorization': idToken,
        },
        body: JSON.stringify({clientInfo: selectedClient})
      })
      .then(this.handleError)
      .then(response => {
        if (selectedClientIndex > 0) {
          // Updated an existing client, update original client to the current value of clients
          this.setState({
            modalState: 'success',
            modalOpen: true,
            modalText: `Client '${selectedClient.name}' successfully updated!`,
            activeStep: 0,
            optionalSteps: [3, 4],
            skipped: new Set(),
            selectedClientIndex: 0,
            isSubmitting: false,
            originalClients: JSON.parse(JSON.stringify(clients)),
          });
        } else {
          // Added a new client, make adjustments in clients and clientList
          const newClients = JSON.parse(JSON.stringify(clients));
          const sortedClients = newClients.sort((a, b) => sortByProp(a, b, 'name'));
          const clientsList = [{primary: 'New Client', secondary: '', disabled: false}, ...sortedClients.map(client => ({primary: client.name, secondary: client.mainContactEmail, disabled: client.disabled}))];
          sortedClients.unshift(JSON.parse(JSON.stringify(originalClients[0])));
          this.setState({
            modalState: 'success',
            modalOpen: true,
            modalText: `Client '${selectedClient.name}' successfully created!`,
            activeStep: 0,
            optionalSteps: [3, 4],
            skipped: new Set(),
            selectedClientIndex: 0,
            isSubmitting: false,
            originalClients: JSON.parse(JSON.stringify(sortedClients)),
            clients: sortedClients,
            clientsList: clientsList,
          });
        }
      })
      .catch(({error, errorCode}) => {
        if (error && errorCode) {
          this.setState({
            error,
            errorCode,
            modalState: 'failure',
            modalOpen: true,
            modalText: `Encountered a ${errorCode} error: ${error}`,
            isSubmitting: false,
          });
        }
      });
    })
    .catch(({error, errorCode}) => this.setState({error, errorCode}));
    this.serverTimeout();
  };

  handleDisable = () => {
    // console.log('handleDisable');
    const {apiUrl, clients, selectedClientIndex} = this.state;
    const newClients = JSON.parse(JSON.stringify(clients));
    newClients[selectedClientIndex].disabled = true;
    const selectedClient = newClients[selectedClientIndex];
    // console.log(newClients);
    this.setState({
      isSubmitting: true,
      error: false,
      errorCode: null,
      modalState: 'loading',
      modalOpen: true,
      modalText: `Disabling client: ${selectedClient.name}...`,
    });
    firebase.auth().currentUser.getIdToken(/* forceRefresh */ true).then((idToken) => {
      fetch(`${apiUrl}/admin/clients`,
        {
          method: 'PUT',
          headers: {
            'Content-Type': 'application/json',
            'Authorization': idToken,
        },
        body: JSON.stringify({clientName: selectedClient.name})
      })
      .then(this.handleError)
      .then(response => {
        this.setState({
          modalState: 'success',
          modalOpen: true,
          modalText: `Client '${selectedClient.name}' successfully disabled!`,
          activeStep: 0,
          optionalSteps: [3, 4],
          skipped: new Set(),
          selectedClientIndex: 0,
          isSubmitting: false,
          clients: newClients,
          originalClients: JSON.parse(JSON.stringify(newClients)),
        });
      })
      .catch(({error, errorCode}) => {
        if (error && errorCode) {
          this.setState({
            error,
            errorCode,
            modalState: 'failure',
            modalOpen: true,
            modalText: `Encountered a ${errorCode} error: ${error}`,
            isSubmitting: false,
          });
        }
      });
    })
    .catch(({error, errorCode}) => this.setState({error, errorCode}));
    this.serverTimeout();
  };

  handleDelete = () => {
    const {apiUrl, clients, selectedClientIndex, clientsList} = this.state;
    const selectedClient = clients[selectedClientIndex];
    if (window.confirm(`Are you sure you want to delete ${selectedClient.name}?`)) {
      this.setState({
        isSubmitting: true,
        error: false,
        errorCode: null,
        modalState: 'loading',
        modalOpen: true,
        modalText: `Deleting client: ${selectedClient.name}...`,
      });
      firebase.auth().currentUser.getIdToken(/* forceRefresh */ true).then((idToken) => {
        fetch(`${apiUrl}/admin/clients/${selectedClient.name}`,
          {
            method: 'DELETE',
            headers: {
              'Content-Type': 'application/json',
              'Authorization': idToken,
          },
        })
        .then(this.handleError)
        .then(response => {
          const newClients = JSON.parse(JSON.stringify(clients));
          const newClientsList = JSON.parse(JSON.stringify(clientsList));
          newClients.splice(selectedClientIndex, 1);
          newClientsList.splice(selectedClientIndex, 1);
          this.setState({
            modalState: 'success',
            modalOpen: true,
            modalText: `Client '${selectedClient.name}' successfully deleted!`,
            activeStep: 0,
            optionalSteps: [3, 4],
            skipped: new Set(),
            selectedClientIndex: 0,
            isSubmitting: false,
            clients: newClients,
            originalClients: JSON.parse(JSON.stringify(newClients)),
            clientsList: newClientsList,
          });
        })
        .catch(({error, errorCode}) => {
          if (error && errorCode) {
            this.setState({
              error,
              errorCode,
              modalState: 'failure',
              modalOpen: true,
              modalText: `Encountered a ${errorCode} error: ${error}`,
              isSubmitting: false,
            });
          }
        });
      })
      .catch(({error, errorCode}) => this.setState({error, errorCode}));
      this.serverTimeout();
    }
  };

  componentDidMount() {
    const {appContext} = this.props;

    const processMicroservice = (publicMicroservice, clientMicroservice=false) => {
      if (publicMicroservice.title === 'Investigator') {
        // console.log(`publicMicroservice: ${publicMicroservice.title} clientMicroservice: ${clientMicroservice}`);
        // console.log(publicMicroservice);
        // console.log(clientMicroservice);
      }
      const data = clientMicroservice ? clientMicroservice.data : publicMicroservice.data;
      const processedChildren = [];
      const isDashboard = publicMicroservice.componentName === 'TableauContainer';
      // Checks if this microservice has any children that are dashboards
      const hasDashChildren = !!(publicMicroservice.children && publicMicroservice.children.length
          && publicMicroservice.children.some(child => child.componentName === 'TableauContainer'));
      publicMicroservice.children.forEach(child => {
        if (clientMicroservice && clientMicroservice.children.length && publicMicroservice.children.length) {
          // Client already has configured children in this microservice
          const clientChild = clientMicroservice.children.find(clientChild => clientChild.id === child.id);
          const isChildDashboard = child.componentName === 'TableauContainer';
          if (clientChild !== undefined) {
            if (isChildDashboard && clientChild.data.length) {
              processedChildren.push({
                ...child,
                ...clientChild,
                isDashboard: isChildDashboard,
                data: appContext.url + clientChild.data,
              });
            } else {
              processedChildren.push({
                ...child,
                ...clientChild,
                isDashboard: isChildDashboard,
                data: clientChild.data,
              });
            }
          } else {
            processedChildren.push({
              ...child,
              ...clientChild,
              isDashboard: isChildDashboard,
              data: !Array.isArray(child.data) ? '' : [{name: '', data: '', id: generateID()}],
            });
          }
        } else {
          // Client has not configured this microservice, use the defaults
          processedChildren.push({
            ...child,
            isDashboard: child.componentName === 'TableauContainer',
            data: !Array.isArray(child.data) ? '' : [{name: '', data: '', id: generateID()}],
          });
        }
      });
      if (isDashboard && clientMicroservice && clientMicroservice.data) {
        return ({
          ...publicMicroservice,
          isDashboard,
          hasDashChildren,
          children: processedChildren,
          data: appContext.url + clientMicroservice.data,
          state: clientMicroservice.disabled ? 'inApp' : 'enabled',
        });
      } else {
        return ({
          ...publicMicroservice,
          isDashboard,
          hasDashChildren,
          children: processedChildren,
          data: clientMicroservice.data ? clientMicroservice.data : !Array.isArray(data) ? '' :[{name: '', data: '', id: generateID()}],
          state: !clientMicroservice ? 'removed' : clientMicroservice.disabled ? 'inApp' : 'enabled',
        });
      }
    };

    // const processClientMicroservice = (publicMicroservice, clientMicroservice) => {
    //   if (clientMicroservice) {
    //     // console.log(publicMicroservice);
    //     // console.log(clientMicroservice);
    //     // Determine the type of data
    //     if (hasDashChildren && clientMicroservice) {
    //       // Push an object that represents a dashboard child to the data of this microservice
    //       publicMicroservice.children.forEach(child => {
    //         const clientChild = clientMicroservice.children.find(clientChild => clientChild.id === child.id);
    //         if (child.isDashboard) {
    //           data.push({title: child.title, data: clientChild.data || '', isDashboard: true, id: clientChild.id});
    //         }
    //       });
    //     } else if (publicMicroservice.componentName === 'LinkCardSelector' && clientMicroservice.children && clientMicroservice.children.length) {
    //       // console.log('in');
    //       // console.log('publicMicroservice');
    //       // console.log(publicMicroservice);
    //       // console.log('clientMicroservice');
    //       // console.log(clientMicroservice);
    //       clientMicroservice.children.forEach(child => {
    //         const publicChild = publicMicroservice.children.find(publicChild => publicChild.id === child.id);
    //         data.push({title: publicChild.title, data: child.data, id: child.id, isChild: true});
    //       });
    //     } else if (publicMicroservice.componentName === 'Presentations') {
    //       data = clientMicroservice.data.map(elem => ({...elem, id: generateID()}));
    //     } else {
    //       data = clientMicroservice && clientMicroservice.data ? clientMicroservice.data : publicMicroservice.data;
    //     }
    //     // Create an object representing a default microservice with the correct type
    //     return ({
    //       ...publicMicroservice,
    //       state: clientMicroservice === undefined ? 'removed' : clientMicroservice.enabled ? 'enabled' : 'inApp',
    //       data,
    //     });
    //   } else {
    //     return publicMicroservice;
    //   }
    // };

    // Fetch the user's claims from Firebase
    firebase.auth().currentUser.getIdTokenResult(true).then((idTokenResult) => {
      const claims = idTokenResult.claims;
      // Check if user has permission to use this admin microservice
      if (claims.clientManagement) {
        // Create microservice array that will be used in the form by mapping over public microservices
        const microservices = appContext.appMicroservices.map(elem => processMicroservice(elem));
        let clients = [];
        // Get list of current clients
        appContext.firestore.collection('clients').get().then(clientsSnapshot => {
          clientsSnapshot.forEach(clientDoc => {
            // Filter out clients this user does not have claims for
            if (claims[clientDoc.id]) {
              const client = clientDoc.data();
              // console.log('Processing Client: ', client.name);
              clients.push({
                disabled: false,
                mainContactEmail: client.mainContactEmail,
                microservices: appContext.appMicroservices.map((elem, index) => processMicroservice(elem, client.microservices.find(ms => ms.id === elem.id))),
                name: client.name,
                users: client.users,
                isBigQuerySetup: false,
                selectedUsers: [],
                availableUsers: [],
              });
            }
          });
          // Create a array compatiable with the `ListText` component
          const clientsList = [{
              primary: 'New Client',
              secondary: '',
              disabled: false
            }, 
            ...clients.map(client => ({
              primary: client.name,
              secondary: client.mainContactEmail,
              disabled: client.disabled,
            }
          ))];
          clients.unshift({
            disabled: false,
            mainContactEmail: '',
            name: '',
            users: [],
            isBigQuerySetup: false,
            selectedUsers: [],
            availableUsers: [],
            microservices,
          });
          // console.log('clientsList');
          // console.log(clientsList);
          // console.log('clients');
          // console.log(clients);
          // Check if user has permission to modify users associated with this client
          if (claims.userManagement) {
            // Get the list of users
            firebase.auth().currentUser.getIdToken(/* forceRefresh */ true).then((idToken) => {
              fetch(`${appContext.apiUrl}/admin/users`,
                {
                  method: "GET",
                  headers: {
                    'Content-Type': 'application/json',
                    'Authorization': idToken,
                }
              })
              .then(this.handleError)
              .then(response => response.json().then(responseJSON => {
                // Create a list of users sorted alphabetically
                const sortedUsers = responseJSON.users.sort((a, b) => sortByProp(a, b, 'displayName'));
                // Create arrays of available and selected users for each client
                sortedUsers.forEach(userInfo => {
                  clients.forEach((client, index, arr) => {
                    const isUserAssociated = !!client.users.find(uid => uid === userInfo.uid);
                    if (isUserAssociated || userInfo.uid === appContext.user.uid) {
                      arr[index].selectedUsers.push({...userInfo, value: userInfo.uid, label: `${userInfo.displayName} (${userInfo.email})`});
                    }
                    arr[index].availableUsers.push({...userInfo, value: userInfo.uid, label: `${userInfo.displayName} (${userInfo.email})`});
                  });
                });
                // console.log('clients');
                // console.log(clients);
                this.setState({
                  userClaims: idTokenResult.claims,
                  loading: false,
                  originalClients: JSON.parse(JSON.stringify(clients)),
                  clients,
                  clientsList,
                  microservices,
                });
              }))
              .catch(({error, errorCode}) => {
                if (error && errorCode) {
                  this.setState({error, errorCode});
                }
              })
            })
            .catch(({error, errorCode}) => this.setState({error, errorCode}));
          } else {
            this.setState({userClaims: idTokenResult.claims, loading: false, microservices, clientsList, clients});
          }
        })
        .catch(error => {
          // console.log(error);
          this.setState({userClaims: idTokenResult.claims, loading: false});
        });
      } else {
        this.setState({error: 'Insufficient Permissions', errorCode: 403,})
      }
    })
    .catch((error) => {
      // console.log(error);
    });
  }

  render() {
    const {classes, appContext} = this.props;
    const {
      loading,
      error,
      errorCode,
      microservices,
      dimensions,
      steps,
      activeStep,
      optionalSteps,
      clientsList,
      clients,
      selectedClientIndex,
      modalOpen,
      modalText,
      modalState,
      isSubmitting,
      formTouched,
      formErrors,
      userClaims,
    } = this.state;

    const numScrollbarItems = microservices.length;
    /* Hardcoded heights of UI elements for the scrollbar
    outer div margin top 25px
    outer div margin bottom 25px
    settingsPaperContainer padding top 25px
    settingsPaperContainer padding bottom 25px
    Stepper Container 110px
    heading & margin 36px + 15px
    Submit Div margin 25px
    Submit Div 36px*/
    const availableScrollbarSize = (dimensions.height - ( dimensions.topPanel + 25 + 25 + 25 + 25 + 110 + 36 + 15 + 25 + 36));
    let minScrollbarSize = numScrollbarItems * 50; //Size of list item (35px + 15px margin)
    if (availableScrollbarSize < minScrollbarSize) {
      minScrollbarSize = availableScrollbarSize;
    }

    /* heading 24
    client name 68
    client main contact 68
    is user 48
    heading and margin 32 + 25*/

    const selectedUsersScrollbarSize =  (dimensions.height - ( dimensions.topPanel + 25 + 25 + 25 + 25 + 110 + 24 + 68 + 68 + 48 + 32 + 25 + 25 + 36));

    if (!loading) {
      // console.log('selectedClientIndex:');
      // console.log(selectedClientIndex);
      // console.log('clients:');
      // console.log(clients);

      const selectedClient = clients[selectedClientIndex];
      // console.log('selectedClient:');
      // console.log(selectedClient);

      const errorsExist = Object.keys(formErrors).length > 0;

      const connector = (
        <StepConnector
          classes={{
            active: classes.connectorActive,
            completed: classes.connectorCompleted,
            disabled: classes.connectorDisabled,
            line: classes.connectorLine,
          }}
        />
      );
      // console.log('formErrors');
      // console.log(formErrors);
      // Check if any of the top level dashboards have been set to 'enabled'
      const topLevelDashEnabled = !!selectedClient.microservices.find(elem => elem.isDashboard && elem.state === 'enabled');
      // Check if any of the microservices that require data input (not dashboards), has been selected
      const dataEnabled = !!selectedClient.microservices.find(elem => {
          // console.log(`On microservice: ${elem.title}`);
          // console.log((typeof(elem.data) === 'string' || Array.isArray(elem.data)) && !elem.isDashboard && !elem.hasDashChildren && elem.state === 'enabled');
          return ((typeof(elem.data) === 'string' || Array.isArray(elem.data)) && !elem.isDashboard && !elem.hasDashChildren && elem.state === 'enabled');
        }
      );
      // Get the index of top level microservices that have been set to 'enabled'
      const topLevelIndexes = selectedClient.microservices.reduce((arr, elem, index) => {
        if (elem.isDashboard && elem.state === 'enabled') {
          arr.push(index)
        }
        return arr
      }, []);
      // Check to see if there are any errors with top level dashboards links
      const topLevelDashError = topLevelIndexes.reduce((acc, msIndex) => {
        if (!acc && clients[selectedClientIndex].microservices[msIndex].data === '') {
          return true;
        } else {
          return acc;
        }
      }, false);
      // console.log('topLevelDashEnabled', topLevelDashEnabled);
      // console.log('dataEnabled', dataEnabled);
      // console.log('topLevelIndexes', topLevelIndexes);
      // console.log('topLevelDashError', topLevelDashError);
      // Set the state of optional steps according to the updated selectedClient
      this.alterOptional(topLevelDashEnabled, dataEnabled, optionalSteps);
      return (
        <div className={classes.outerDiv}>
          <Paper className={classes.settingsPaperContainer}>
            <form className={classes.form}>
              <div className={classes.stepper}>
                <Stepper activeStep={activeStep} className={{root: classes.stepper}} connector={connector}>
                  {steps.map((label, index) => {
                    const props = {};
                    const labelProps = {};
                    if (this.isStepOptional(index)) {
                      labelProps.optional = <Typography variant="caption">Optional</Typography>;
                    }
                    if (this.isStepSkipped(index)) {
                      props.completed = false;
                    }
                    return (
                      <Step key={label} {...props} className={classes.step}>
                        <StepLabel {...labelProps}>{label}</StepLabel>
                      </Step>
                    );
                  })}
                </Stepper>
              </div>
              <React.Fragment>
                {activeStep === 0 && <ClientSelection
                  handleUpdateSelectedClient={this.handleUpdateSelectedClient}
                  clientsList={clientsList}
                  selectedClientIndex={selectedClientIndex}
                  dimensions={appContext.dimensions}
                  minScrollbarSize={minScrollbarSize}
                  classes={classes}
                />}
                {activeStep === 1 && <ClientDetails
                  handleUpdateField={this.handleUpdateField}
                  handleAddUser={this.handleAddUser}
                  handleRemoveUser={this.handleRemoveUser}
                  selectedClient={selectedClient}
                  formTouched={formTouched}
                  formErrors={formErrors}
                  selectedUsersScrollbarSize={selectedUsersScrollbarSize}
                  classes={classes}
                />}
                {activeStep === 2 && <MicroserviceSelection
                  handleUpdateMicroservice={this.handleUpdateMicroservice}
                  handleMoveUp={this.handleMoveUp}
                  handleMoveDown={this.handleMoveDown}
                  selectedClient={selectedClient}
                  minScrollbarSize={minScrollbarSize}
                  classes={classes}
                />}
                {activeStep === 3 && <MicroserviceConfig
                  handleSkip={this.handleSkip}
                  handleUpdateMicroserviceData={this.handleUpdateMicroserviceData}
                  handleIsBigQuerySetup={this.handleIsBigQuerySetup}
                  handleMoveDown={this.handleMoveDown}
                  handleMoveUp={this.handleMoveUp}
                  handleRemoveDataPoint={this.handleRemoveDataPoint}
                  handleAddDataPoint={this.handleAddDataPoint}
                  selectedClient={selectedClient}
                  formTouched={formTouched}
                  formErrors={formErrors}
                  dataEnabled={dataEnabled}
                  minScrollbarSize={minScrollbarSize}
                  classes={classes}
                />}
                {activeStep === 4 && <DashboardConfig
                  handleSkip={this.handleSkip}
                  handleUpdateMicroserviceData={this.handleUpdateMicroserviceData}
                  selectedClient={selectedClient}
                  formTouched={formTouched}
                  formErrors={formErrors}
                  topLevelDashError={topLevelDashError}
                  minScrollbarSize={minScrollbarSize}
                  classes={classes}
                />}
                {activeStep === 5 && <Review
                  selectedClient={selectedClient}
                  minScrollbarSize={minScrollbarSize}
                  classes={classes}
                />}
                <div className={classes.controlDiv}>
                  <div>
                    {activeStep !== 0 && (
                      <Button onClick={this.handleBack} className={classes.button}>
                        Back
                      </Button>
                    )}
                    {this.isStepOptional(activeStep) && !topLevelDashEnabled && (
                      <Button
                        variant='contained'
                        onClick={this.handleSkip}
                        className={classes.onTheLeftButton}
                        disabled={topLevelDashError}
                      >
                        Skip
                      </Button>
                    )}
                    {activeStep === steps.length - 1 && (
                      <Button onClick={this.handleReset} className={classes.onTheLeftButton}>
                        Reset
                      </Button>
                    )}
                  </div>
                  <div>
                    {activeStep === 0 && userClaims.allowDelete &&
                      <Button
                        className={classes.deleteButton}
                        variant='raised'
                        onClick={this.handleDelete}
                        disabled={selectedClientIndex === null || selectedClientIndex === 0 || isSubmitting}
                      >
                        Delete Client
                      </Button>
                    }
                    {activeStep === 0 && userClaims.allowDisable &&
                      <Button
                        className={classes.disableButton}
                        variant='raised'
                        onClick={this.handleDisable}
                        disabled={true}
                        // disabled={selectedClientIndex === null || selectedClientIndex === 0 || isSubmitting}
                      >
                        {selectedClient && selectedClient.disabled ? 'Enable Client' : 'Disable Client'}
                      </Button>
                    }
                    {activeStep === 2 &&
                      <Button
                        className={classes.onTheLeftButton}
                        variant='raised'
                        onClick={this.handleEnableAll}
                      >
                        Enable All
                      </Button>
                    }
                    {activeStep === 2 &&
                      <Button
                        className={classes.onTheLeftButton}
                        variant='raised'
                        onClick={this.handleRemoveAll}
                      >
                        Remove All
                      </Button>
                    }
                    {activeStep === 2 &&
                      <Button
                        className={classes.onTheLeftButton}
                        variant='raised'
                        onClick={this.handleDemoAll}
                      >
                        Demo All
                      </Button>
                    }
                    {activeStep < steps.length - 1 && 
                      <Button
                        onClick={this.handleNext}
                        className={classes.onTheLeftButton}
                        variant='raised'
                        label='next'
                        disabled={
                          isSubmitting ||
                          (activeStep === 1 && (formErrors.name || selectedClient.name === '' || formErrors.mainContactEmail || selectedClient.mainContactEmail === '')) ||
                          (activeStep === 3 && errorsExist) ||
                          (activeStep === 4 && topLevelDashError)
                      }>
                        Next
                      </Button>
                    }
                  </div>
                  {activeStep === steps.length - 1 &&
                  <Button
                    className={classes.button}
                    variant='raised'
                    onClick={this.handleSubmit}
                    disabled={isSubmitting}
                  >
                    {selectedClientIndex > 0 ? 'Update Client' : 'Create Client'}
                  </Button>}
                </div>
                <LoadingModal
                  open={modalOpen}
                  state={modalState}
                  text={modalText}
                  handleClose={this.handleClose}
                  totalWidth={this.props.appContext.dimensions.width}
                  totalHeight={this.props.appContext.dimensions.height}
                  adjustWidth={this.props.appContext.drawerOpen ? this.props.appContext.dimensions.openSideNav : this.props.appContext.dimensions.collapsedSideNav}
                />
              </React.Fragment>
            </form>
          </Paper>
        </div>
      );
    } else if (error) {
      return <ErrorPage error={error} errorCode={errorCode}/>
    } else {
      return <Loading text='Loading Clients'/>
    }
  }
}

ClientManagement.propTypes = {
  appContext: PropTypes.object.isRequired,
};

export default withStyles(styles)(ClientManagement);
