import React from 'react';
import {withStyles} from '@material-ui/core/styles';
import LinkCard from './LinkCard';
import Grid from '@material-ui/core/Grid';
import { Scrollbars } from 'react-custom-scrollbars';
import PropTypes from 'prop-types';

const styles = (theme) => ({
  containerDiv: {
    marginTop: '16px',
  },
  gridContainerDiv: {
    overflow: 'auto',
    flexGrow: 1,
    marginLeft: '16px',
    marginRight: '16px',
    paddingBottom:'16px',
  },
  scrollbar: {
    backgroundColor: theme.palette.primary.main,
    opacity: '0.4',
  },
  noScrollbar: {
    display: 'none',
    opacity: '0.0',
  },
});

const LinkCardSelector = (props) => {
  const {classes, appContext, path} = props;
  const {dimensions, microservices} = appContext;
  let microserviceArr;
  if (path) {
    microservices.forEach(elem => {
      if (elem.path === path) {
        microserviceArr = elem.children;
      }
    });
  } else {
    microserviceArr = microservices;
  }
  return (
    <div className={classes.containerDiv}>
      <Scrollbars
        autoHeight
        autoHeightMin={`calc(100vh - ${dimensions.topPanel + 16}px)`}
        renderThumbVertical={props => <div {...props} className={classes.scrollbar}/>}
        renderThumbHorizontal={props => <div {...props} className={classes.noScrollbar}/>}
      >
        <Grid container justify='flex-start' alignContent='flex-start' spacing={16} className={classes.gridContainerDiv}>
          {microserviceArr.map((item, index) => {
            return <LinkCard key={index} microservice={item}/>
          })}
        </Grid>
      </Scrollbars>
    </div>
  );
};

LinkCardSelector.propTypes = {
  path: PropTypes.string,
  appContext: PropTypes.object.isRequired,
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(LinkCardSelector);
