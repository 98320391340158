import React from 'react';
import {Route, Switch} from 'react-router-dom'
import {AppContext} from './App';
import Authentication from '../authentication/Authentication';
import SideNav from './components/SideNav';
import TopPanel from './components/TopPanel';
import ErrorPage from './components/ErrorPage';
import { withStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import LinkCardSelector from './components/LinkCardSelector';

const styles = theme => ({
  mainContainer: {
    flexGrow: 1,
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
    minWidth: '750px',
    minHeight: '600px',
  },
});

const Routes = (props) => {
  const {classes} = props;
  return (
    <AppContext.Consumer>
      {(appContext) => (
        <Switch>
          <Route render={() =>
            <Authentication appContext={appContext}>
              <SideNav appContext={appContext}/>
              <Paper className={classes.mainContainer} >
                <TopPanel onLogoutClick={appContext.logUserOut} appContext={appContext} />
                <Switch>
                  <Route exact path='/' render={()=><LinkCardSelector appContext={appContext}/>}/>
                  {appContext.microservices.map((item) => {
                    const MSComponent = item.component;
                    return (
                      <Route
                        exact
                        key={item.path}
                        path={item.path}
                        render={()=><MSComponent appContext={appContext} path={item.path}/>}
                      />
                    );
                  })}
                  {appContext.microservices.map((item) => {
                    return item.hasOwnProperty('children') && item.children.map((child) => {
                      const MSComponent = child.component;
                      return (
                        <Route
                          exact
                          key={child.path}
                          path={child.path}
                          render={()=><MSComponent appContext={appContext} path={child.path}/>}
                        />
                      );
                    });
                  })}
                  <Route path='/' render={()=><ErrorPage errorCode={404} error='Not found'/>}/>
                </Switch>
              </Paper>
            </Authentication>
          }/>
          <Route path='/' render={()=><ErrorPage errorCode={404} error='Not found'/>}/>
        </Switch>
      )}
    </AppContext.Consumer>
  );
};

export default withStyles(styles)(Routes);
