import React from 'react';
import PropTypes from 'prop-types';
import Typography from '@material-ui/core/Typography';
import ListText from '../app/components/ListText';

// Component for the first step in client setup
const ClientSelection = props => {
  const {
    handleUpdateSelectedClient,
    clientsList,
    dimensions,
    minScrollbarSize,
    classes
  } = props;
  return (
    <div className={classes.msList}>
      <Typography variant='title'>Client Selection</Typography>
      <ListText
        items={clientsList}
        selectedIndex={props.selectedClientIndex}
        title='Select Client'
        onListItemSelect={(index) => handleUpdateSelectedClient(index)}
        dimensions={dimensions}
        maxHeight={minScrollbarSize}
        disableGutters
      />
    </div>
  );
};

ClientSelection.propTypes = {
  handleUpdateSelectedClient: PropTypes.func.isRequired,
  clientsList: PropTypes.array.isRequired,
  dimensions: PropTypes.object.isRequired,
  minScrollbarSize: PropTypes.number.isRequired,
  classes: PropTypes.object.isRequired,
  appContext: PropTypes.object.isRequired,
};

export default ClientSelection;
