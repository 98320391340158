import React from 'react';
import PropTypes from 'prop-types';
import { Scrollbars } from 'react-custom-scrollbars';
import Typography from '@material-ui/core/Typography';
import InputLabel from '@material-ui/core/InputLabel';
import Input from '@material-ui/core/Input';
import FormControl from '@material-ui/core/FormControl';
import Icon from '@material-ui/core/Icon';
import ExpansionPanel from '@material-ui/core/ExpansionPanel';
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary';
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails';
import FormHelperText from '@material-ui/core/FormHelperText';

// Component for the fifth step in client setup, dashboard microservices
const DashboardConfig = props => {
  const {
    handleSkip,
    handleUpdateMicroserviceData,
    selectedClient,
    topLevelDashError,
    formErrors,
    classes,
    minScrollbarSize,
  } = props;
  //Create an array of dashboards with all properties necessary to configure
  const dashboardTypes = [];
  const topLevelDashboards = [];
  selectedClient.microservices.forEach((ms, index) => {
    // console.log('ms');
    // console.log(ms);
     if (ms.hasDashChildren) {
      const childLinks = [];
      ms.children.forEach((child, childIndex) => {
        if (child.isDashboard === true) {
          childLinks.push({
            title: child.title,
            link: child.data,
            msIndex: index,
            childIndex,
          });
        }
      });
      if (ms.state === 'enabled') {
        dashboardTypes.push({
          title: ms.title,
          childLinks,
          enabled: childLinks.length > 0,
        });
      }
    } else if (ms.isDashboard && ms.state === 'enabled') {
      topLevelDashboards.push({
        title: ms.title,
        link: ms.data,
        msIndex: index,
      });
    }
  });
  // If there are any top level dashboards enabled, add then to dashboardTypes
  if (topLevelDashboards.length > 0) {
    dashboardTypes.push({
      title: 'Top Level Dashboards',
      childLinks: topLevelDashboards,
      enabled: true,
    });
  }
  // If there are no enabled dashboards, skip this step
  if (!dashboardTypes.reduce((acc, elem) => {return (elem.enabled || acc)}, false)) {
    handleSkip();
  }
  return (
    <div className={classes.msList}>
      <div className={classes.msListItem}>
        <Typography variant='title'>Dashboard Share Links</Typography>
      </div>
      <Scrollbars
        autoHeight
        autoHeightMin={`${minScrollbarSize}px`}
        renderThumbVertical={props => <div className={classes.scrollbar}/>}
        renderThumbHorizontal={props => <div className={classes.noScrollbar}/>}
      >
        { // Map over the panels and render a expansion panel for each 'panel' or unique dashboard type
          dashboardTypes.map((elem, index) => {
            // console.log(elem);
            const {title, childLinks, enabled} = elem;
            if (enabled) {
              return (
                <div className={classes.scrollbarChild} key={index}>
                  <ExpansionPanel className={classes.dashboardExpansionPanel}>
                    <ExpansionPanelSummary expandIcon={<Icon color='primary'>expand_more</Icon>}>
                      <div className={classes.column}>
                        <Typography>{title}</Typography>
                      </div>
                      {title === 'Top Level Dashboards' && topLevelDashError && <div className={classes.column}>
                        <Typography className={classes.dashboardLinkRequired}>Dashboard Link Required</Typography>
                      </div>}
                    </ExpansionPanelSummary>
                    <ExpansionPanelDetails>
                      <div className={classes.msList}>
                        {childLinks.map((dash, dashIndex) => {
                          const {title, link, msIndex, childIndex} = dash;
                          return (
                            <div className={classes.dashboardListItem} key={dashIndex}>
                              <FormControl className={classes.wideForm} error={!!formErrors[title]}>
                                <InputLabel>{title}</InputLabel>
                                <Input
                                  value={link}
                                  onChange={(e) => {handleUpdateMicroserviceData(title, e.target.value, true, msIndex, childIndex);}}
                                  label={title}
                                  type='text'
                                />
                                <FormHelperText id={`${title}-error-text`}>{formErrors[title]}</FormHelperText>
                              </FormControl>
                            </div>
                          );
                        })}
                      </div>
                    </ExpansionPanelDetails>
                  </ExpansionPanel>
                </div>
              );
            } else {
              return null;
            }
          })
        }
      </Scrollbars>
    </div>
  );
};

DashboardConfig.propTypes = {
  handleSkip: PropTypes.func.isRequired,
  handleUpdateMicroserviceData: PropTypes.func.isRequired,
  selectedClient: PropTypes.object.isRequired,
  topLevelDashError: PropTypes.bool.isRequired,
  formErrors: PropTypes.array.isRequired,
  classes: PropTypes.object.isRequired,
  minScrollbarSize: PropTypes.number.isRequired,
};

export default DashboardConfig;