import React, {Component} from 'react';
import 'react-vis/dist/style.css';
import {createMuiTheme, MuiThemeProvider} from '@material-ui/core/styles';
import Routes from './Routes';
import Demo from './components/Demo';
import Loading from './components/Loading';
import LinkCardSelector from './components/LinkCardSelector';
import UserManagement from '../user_management/UserManagement';
import ClientManagement from '../client_management/ClientManagement';
import AppManagement from '../app_management/AppManagement';
import MicroserviceManagement from '../microservice_management/MicroserviceManagement';
import TestResults from '../test_results/TestResults';
import firebase from 'firebase/app';
import 'firebase/auth';
import 'firebase/firestore';

const config = {
  apiKey: "AIzaSyCgihRPF53kDSB7RQNsf7ptz43ZLyl59RI",
  authDomain: "tkg-tkm-echo-old.firebaseapp.com",
  projectId: "tkg-tkm-echo-old",
  storageBucket: "tkg-tkm-echo-old.appspot.com",
  messagingSenderId: "744116932386",
  appId: "1:744116932386:web:c9dfc05889322ce843df7b"
};

if (!firebase.apps.length) {
  firebase.initializeApp(config);
}

const firestore = firebase.firestore();
const settings = {};
firestore.settings(settings);

const topPanelSize = 85;
const openSideNavSize = 300;
const collapsedSideNavSize = 85;
const listItemWidthSize = 250;
const listItemHeightSize = 60;
const expandButtonSize = openSideNavSize - listItemWidthSize;
const itemSeparationSize = 16;
const itemHeadingMarginSize = 5;

const primaryColour = {
  main: '#0982b9',
  light: '#59b1ec',
  dark: '#005689',
};

const lightSecondaryColour = {
  main: '#efe3e3',
  light: '#efefef',
  dark: '#a79e9e',
};

const darkSecondaryColour = {
  main: '#232323',
  light: '#2f2f2f',
  dark: '#0e0e0e',
};

const darkCustomColours = {
  sideNavText: '#a0a3a5',
};

const lightCustomColours = {
  sideNavText: '#000',
};

const darkTheme = {
  label: 'dark',
  overrides: {
    MuiDrawer: {
      paper: {
        background: darkSecondaryColour.main,
      },
    },
    MuiButtonBase: {
      root: {
        color: primaryColour.main,
      },
    },
    MuiIcon:{
      colorPrimary:{
        color: primaryColour.main,
      }
    },
    MuiModal: {
      root: {
        zIndex: 1,
      }
    },
  },
  typography: {
    fontFamily:'"Poppins",sans-serif',
  },
  palette: {
    primary: primaryColour,
    secondary: darkSecondaryColour,
  },
  customColors: darkCustomColours,
  sizes: {
    topPanel: topPanelSize,
    openSideNav: openSideNavSize,
    collapsedSideNav: collapsedSideNavSize,
    listItemWidth: listItemWidthSize,
    listItemHeight: listItemHeightSize,
    expandButton: expandButtonSize,
    itemSeparation: itemSeparationSize,
    itemHeadingMargin: itemHeadingMarginSize,
  },
};

const lightTheme = {
  label: 'light',
  overrides: {
    MuiButtonBase: {
      root: {
        color: primaryColour.main,
      },
    },
    MuiMenuItem: {
      root: {
        '&:hover': {
          background: lightSecondaryColour.dark,
        },
      },
      selected: {
        backgroundColor: lightSecondaryColour.dark,
      }
    },
    MuiIcon:{
      colorPrimary:{
        color: primaryColour.main,
      }
    },
    MuiModal: {
      root: {
        zIndex: 1,
      }
    },
  },
  typography: {
    fontFamily:'"Poppins",sans-serif',
  },
  palette: {
    primary: primaryColour,
    secondary: lightSecondaryColour ,
  },
  customColors: lightCustomColours,
  sizes: {
    topPanel: topPanelSize,
    openSideNav: openSideNavSize,
    collapsedSideNav: collapsedSideNavSize,
    listItemWidth: listItemWidthSize,
    listItemHeight: listItemHeightSize,
    expandButton: expandButtonSize,
    itemSeparation: itemSeparationSize,
    itemHeadingMargin: itemHeadingMarginSize,
  },
};

export const AppContext = React.createContext();

export default class App extends Component {
  constructor(props) {
    super(props);

    this.signUserOut = () => {
      firebase.auth().signOut();
      this.setState({user: null, drawerOpen: true, theme: 'dark'});
    };

    this.toggleDrawer = () => {
      this.setState((prevState) => ({drawerOpen: !prevState.drawerOpen}), () => {
        firestore.collection('users').doc(this.state.user.uid).set({
          settings: {drawerOpen: this.state.drawerOpen}
        },{
          merge: true
        });
      });
    };

    this.setTheme = (value) => {
      this.setState({theme: value}, () => {
        firestore.collection('users').doc(this.state.user.uid).set({
          settings: {theme: value}
        },{
          merge: true
        });
      });
    };

    this.setClient = (uid, newClient) => {
      // Set new client for user
      firestore.collection('users').doc(uid).set({client: newClient}, {merge: true}).then(() => {
        // Clear current user config and refresh page to force remount and reload
        this.setState({user: null, drawerOpen: true, theme: 'dark', initialLoadComplete: false});
        window.location = '/';
      });
    };

    this.updatePublicMicroservices = () => {
      firestore.collection('settings').doc('publicMicroservices').get().then(publicMicroservicesSnapshot => {
        const publicMicroservices = publicMicroservicesSnapshot.get('microservices');
        this.setState({appMicroservices: publicMicroservices});
      });
    };

    this.state = {
      logUserOut: this.signUserOut,
      toggleDrawer: this.toggleDrawer,
      setTheme: this.setTheme,
      setClient: this.setClient,
      updatePublicMicroservices: this.updatePublicMicroservices,
      initialLoadComplete: false,
      loading: false,
      loadingText: '',
      error: '',
      errorText: '',
      drawerOpen: true,
      theme: 'dark',
      user: null,
      url: 'https://tableau.strategicinsights.co.za',
      // apiUrl: 'https://api.echo.strategicinsights.co.za:8080',
      apiUrl: 'https://europe-west1-tkg-tkm-echo-old.cloudfunctions.net',
      earliestSelectionDate: '2016-01-01',
      adminClaims: [],
      dimensions: {
        width: window.innerWidth,
        height: window.innerHeight,
        topPanel: topPanelSize,
        openSideNav: openSideNavSize,
        collapsedSideNav: collapsedSideNavSize,
        listItemWidth: listItemWidthSize,
        listItemHeight: listItemHeightSize,
        expandButton: expandButtonSize,
        itemSeparation: itemSeparationSize,
        itemHeadingMargin: itemHeadingMarginSize,
      },
      microservices: [],
      firestore,
    }
  };  

  handleResize = () => {
    let resizeTimer = undefined;
    clearTimeout(resizeTimer);
    resizeTimer = setTimeout(() => {
      this.setState({
        dimensions: {
            ...this.state.dimensions,
            width: window.innerWidth,
            height: window.innerHeight,
          },
      });
    }, 300);
  };

  getComponent = (name) => {
    if (name === 'LinkCardSelector') {
      return LinkCardSelector;
    } else if (name === 'UserManagement') {
      return UserManagement;
    } else if (name === 'ClientManagement') {
      return ClientManagement;
    } else if (name === 'AppManagement') {
      return AppManagement;
    } else if (name === 'MicroserviceManagement') {
      return MicroserviceManagement;
    } else if (name === 'TestResults') {
      return TestResults;
    } else {
      return Demo;
    }
  };

  getChildren = (adminChildren, userClaims) => {
    if (adminChildren.length > 0) {
      return adminChildren.map(adminChild => {
        if (userClaims[adminChild.type]) {
          return ({
            ...adminChild,
            component: this.getComponent(adminChild.componentName),
          });
        } else {
          return null;
        }
      }).filter(elem => elem != null); // Remove forbidden microservices
    } else {
      return adminChildren;
    }
  };

  // Filters microservices this user does not have permissions for and uses componentName to fetch the component
  getAdminComponents = (adminMicroservices, userClaims) => {
    return adminMicroservices.map(adminMS => {
      if (userClaims[adminMS.id] && userClaims[adminMS.type]) {
        return ({
          ...adminMS,
          component: this.getComponent(adminMS.componentName),
          children: this.getChildren(adminMS.children, userClaims),
        });
      } else {
        return null;
      }
    }).filter(elem => elem != null); // Remove forbidden microservices
  };

  componentDidMount() {
    window.addEventListener('resize', this.handleResize);
    // Generic function to handle errors from Firebase
    const handleError = err => {
      console.log('Encountered an error during login:');
      console.error(err);
      if (String(err).endsWith('Missing or insufficient permissions.')) {
        this.signUserOut();
      } else {
        this.setState({errorText: err, error: 403, loading: false, initialLoadComplete: true});
      }
    };
    firebase.auth().onAuthStateChanged((user) => {
      if (user) {
        // Auth was successful, update state with new loading status
        this.setState({loading: true, loadingText: 'Loading Admin Microservices', initialLoadComplete: true});
        //Get admin claims and check if user has any of them enabled
        firestore.collection('settings').doc('adminMicroservices').get().then(adminMicroservicesSnapshot => {
          const adminMicroservices = adminMicroservicesSnapshot.get('microservices');
          const adminClaims = adminMicroservicesSnapshot.get('adminClaims');
          user.getIdTokenResult(true).then((idTokenResult) => {
            // Confirm the user is an Admin by searching his claims for an admin microservice
            const userClaims = idTokenResult.claims;
            if (Object.keys(userClaims).filter(claim => adminClaims.includes(claim)).length > 0) {
              // User is admin, get userDoc to allow editing within admin console
              firestore.collection('users').doc(user.uid).get().then(userSnapshot => {
                const userDoc = userSnapshot.data();
                // User settings obtained, update state with new loading status
                this.setState({loading: true, loadingText: 'Loading Public Microservices'});
                // Filter admin microservices
                const reIndexedAdminMicroservices = this.getAdminComponents(adminMicroservices, userClaims);
                if (reIndexedAdminMicroservices.length === 0) {
                  console.log('User lacks admin permissions!');
                  this.signUserOut();
                  this.setState({user: null, drawerOpen: true, theme: 'dark', initialLoadComplete: true, loading: false});
                }
                // Load public microservices
                firestore.collection('settings').doc('publicMicroservices').get().then(publicMicroservicesSnapshot => {
                  const publicMicroservices = publicMicroservicesSnapshot.get('microservices');
                  this.setState({
                    user: {...user, ...userDoc},
                    drawerOpen: userDoc.settings.drawerOpen,
                    theme: userDoc.settings.theme,
                    microservices: reIndexedAdminMicroservices,
                    appMicroservices: publicMicroservices,
                    loading: false,
                    loadingText: '',
                    initialLoadComplete: true,
                    adminClaims,
                   });
                });
              }).catch(handleError);
            } else {
              // Authenticated user does not have admin access, log them out
              this.setState({initialLoadComplete: true, loading: false, loadingText: ''});
              this.signUserOut();
            }
          }).catch(handleError);
        }).catch(handleError);
      } else {
        // User is false, client has logged out or was logged out
        this.setState({user: null, drawerOpen: true, theme: 'dark', initialLoadComplete: true});
      }
    });
  };

  componentWillUnmount() {
    window.removeEventListener('resize', this.handleResize);
  };

  render() {
    const theme = createMuiTheme(this.state.theme === 'dark' ? darkTheme : lightTheme);
    theme.sizes.width = this.state.dimensions.width;
    theme.sizes.height = this.state.dimensions.height;
    if (!this.state.initialLoadComplete) {
      return <Loading text='Checking Your Login Status'/>;
    } else {
      return (
        <React.Fragment>
          <MuiThemeProvider theme={theme}>
            <AppContext.Provider value={this.state}>
              <Routes/>
            </AppContext.Provider>
          </MuiThemeProvider>
        </React.Fragment>
      );
    }
  };
};
