import React from 'react';
import moment from 'moment';
import {
  DiscreteColorLegend,
  HorizontalGridLines,
  makeVisFlexible,
  VerticalBarSeries,
  XAxis,
  XYPlot,
  YAxis,
  LabelSeries
} from 'react-vis';

const FlexibleXYPlot = makeVisFlexible(XYPlot);

const BarChart = (props) => {
  const {legend, legendItems, vertBarSeriesArr, colors, labelData} = props;

  const maxValue = Math.max(...[...([].concat(...(vertBarSeriesArr || []))).map(d => d.y)]);

  const yDomain = [0, 1.02 * maxValue];

  return (
    <React.Fragment>
      {legend && <div className="legend">
        <DiscreteColorLegend
          orientation='vertical'
          items={legendItems}
          className={'legend'}
        />
      </div>}
      <FlexibleXYPlot
        margin={{top: 20, bottom: 40, left: 50, right: 20}}
        yDomain={yDomain}
        xType="ordinal"
        animation
      >
        <HorizontalGridLines />
        <XAxis/>
        <YAxis
          title='Time (mm:ss)'
          tickFormat={(label) => moment(label).format('mm:ss')}
        />
        {vertBarSeriesArr && vertBarSeriesArr.map((vertBarSeries, index) => {
          return (<VerticalBarSeries
            data={vertBarSeries}
            fill={colors.vertBar[index]}
            stroke={colors.vertBar[index]}
          />);
        })}
        {labelData && labelData.map((labelDataSeries, index) => {
          return (<LabelSeries data={labelDataSeries} getLabel={d => d.x} />);
        })}
      </FlexibleXYPlot>
    </React.Fragment>
  );
};

export default BarChart;
