import React from 'react';
import PropTypes from 'prop-types';
import { Scrollbars } from 'react-custom-scrollbars';
import Typography from '@material-ui/core/Typography';
import MicroserviceDataPoint from './MicroserviceDataPoint';

// Component for the fourth step in client setup, non dashboard microservices
const MicroserviceConfig = props => {
  const {
    handleSkip,
    handleIsBigQuerySetup,
    handleUpdateMicroserviceData,
    handleMoveUp,
    handleMoveDown,
    handleRemoveDataPoint,
    handleAddDataPoint,
    selectedClient,
    formErrors,
    formTouched,
    dataEnabled,
    classes,
    minScrollbarSize,
  } = props;
  // Check to see if any non dashboard microservices that need configuration have been selected, otherwise skip
  // console.log('dataEnabled: ', dataEnabled);
  if (!dataEnabled) {
    handleSkip();
  }
  return (
    <div className={classes.msList}>
      <div className={classes.msListItem}>
        <Typography variant='title'>Microservice Config</Typography>
      </div>
      <Scrollbars
        autoHeight
        autoHeightMin={`${minScrollbarSize}px`}
        renderThumbVertical={props => <div className={classes.scrollbar}/>}
        renderThumbHorizontal={props => <div className={classes.noScrollbar}/>}
      >
        <div className={classes.msConfigSrollbarChild}>
          { // Map over the microservices in selectedClient and check for enabled microservices that have data inputs
            selectedClient.microservices.map((microservice, index) => {
              // console.log(`On microservice: ${microservice.title}`);
              const nonDashboardChildren = microservice.children.filter(child => !child.isDashboard);
              if (nonDashboardChildren.length && microservice.state === 'enabled') {
                // console.log(`Processing Non-Dashboard children for microservice: ${microservice.title}`);
                // console.log('nonDashboardChildren: ');
                // console.log(nonDashboardChildren);
                return nonDashboardChildren.map((child, childIndex) => {
                  return (
                    <MicroserviceDataPoint
                      handleUpdateMicroserviceData={handleUpdateMicroserviceData}
                      handleMoveUp={handleMoveUp}
                      handleMoveDown={handleMoveDown}
                      handleRemoveDataPoint={handleRemoveDataPoint}
                      handleAddDataPoint={handleAddDataPoint}
                      handleIsBigQuerySetup={handleIsBigQuerySetup}
                      isBigQuerySetup={selectedClient.isBigQuerySetup}
                      microservice={child}
                      msIndex={index}
                      isChild={true}
                      childIndex={childIndex}
                      formErrors={formErrors}
                      formTouched={formTouched}
                      classes={classes}
                    />
                  );
                })
              } else if (microservice.children.length === 0) {
                // console.log(`Processing Microservice ${microservice.title}`);
                return (
                  <MicroserviceDataPoint
                    handleUpdateMicroserviceData={handleUpdateMicroserviceData}
                    handleMoveUp={handleMoveUp}
                    handleMoveDown={handleMoveDown}
                    handleRemoveDataPoint={handleRemoveDataPoint}
                    handleAddDataPoint={handleAddDataPoint}
                    handleIsBigQuerySetup={handleIsBigQuerySetup}
                    isBigQuerySetup={selectedClient.isBigQuerySetup}
                    microservice={microservice}
                    msIndex={index}
                    isChild={false}
                    formErrors={formErrors}
                    formTouched={formTouched}
                    classes={classes}
                  />
                );
              } else {
                return null;
              }
            })
          }
        </div>
      </Scrollbars>
    </div>
  );
};

MicroserviceConfig.propTypes = {
  handleSkip: PropTypes.func.isRequired,
  handleIsBigQuerySetup: PropTypes.func.isRequired,
  handleUpdateMicroserviceData: PropTypes.func.isRequired,
  handleMoveUp: PropTypes.func.isRequired,
  handleMoveDown: PropTypes.func.isRequired,
  handleRemoveDataPoint: PropTypes.func.isRequired,
  handleAddDataPoint: PropTypes.func.isRequired,
  selectedClient: PropTypes.object.isRequired,
  formErrors: PropTypes.array.isRequired,
  formTouched: PropTypes.array.isRequired,
  dataEnabled: PropTypes.bool.isRequired,
  classes: PropTypes.object.isRequired,
  minScrollbarSize: PropTypes.number.isRequired,
};

export default MicroserviceConfig;