import React from 'react';
import PropTypes from 'prop-types';
import CircularProgress from '@material-ui/core/CircularProgress';
import { withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Modal from '@material-ui/core/Modal';
import Icon from '@material-ui/core/Icon';

const styles = theme => ({
  text: {
    marginRight: '20px',
  },
  paper: {
    position: 'absolute',
    width: theme.spacing.unit * 50,
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[5],
    padding: theme.spacing.unit * 4,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
  },
  modalIcon:{
    fontSize: '60px',
  }
});

const LoadingModal = (props) => {
  const {classes, handleClose, text, open, state, adjustHeight, adjustWidth, totalWidth, totalHeight} = props;
  const  getModalStyle = () => {
    const adjustLeft = (adjustWidth / totalWidth) * 50; // multiply by 50 (not 100) to get half of the adjust value
    const adjustTop = (adjustHeight / totalHeight) * 50;
    const top = 50 + adjustTop;
    const left = 50 + adjustLeft;
    return {
      top: `${top}%`,
      left: `${left}%`,
      transform: `translate(-${top}%, -${left}%)`,
    };
  };
  return (
    <Modal
      aria-labelledby="simple-modal-title"
      aria-describedby="simple-modal-description"
      open={open}
      onClose={handleClose}
    >
      <div style={getModalStyle()} className={classes.paper}>
        {state === 'loading' && <CircularProgress mode='indeterminate' size={50} thickness={5}/>}
        {state === 'success' && <Icon classes={{root: classes.modalIcon}} color='primary'>done</Icon>}
        {state === 'failure' && <Icon classes={{root: classes.modalIcon}} color='primary'>report</Icon>}
        <Typography variant="subheading" className={classes.text}>{text}</Typography>
      </div>
    </Modal>
  );
};

LoadingModal.defaultProps = {
  open: false,
  state: 'loading',
  text: '',
  adjustHeight: 0,
  adjustWidth: 0,
};

LoadingModal.propTypes = {
  handleClose: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
  state: PropTypes.string.isRequired,
  text: PropTypes.string,
  adjustHeight: PropTypes.number,
  adjustWidth: PropTypes.number,
  totalWidth: PropTypes.number,
  totalHeight: PropTypes.number,
};

export default withStyles(styles)(LoadingModal);
