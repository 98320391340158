import React from 'react';
import PropTypes from 'prop-types';
import { Scrollbars } from 'react-custom-scrollbars';
import Typography from '@material-ui/core/Typography';
import Icon from '@material-ui/core/Icon';

// Component for the last step in client setup allowing the user to review the new client
const Review = props => {
  const {selectedClient, minScrollbarSize, classes} = props;
  const enabledMicroservices = selectedClient.microservices.filter(elem => elem.state === 'enabled');
  const greyedOutMicroservices = selectedClient.microservices.filter(elem => elem.state === 'inApp');
  const configuredDashboards = [];
  selectedClient.microservices.forEach(elem => {
    if (elem.isDashboard && elem.state === 'enabled') {
      configuredDashboards.push(elem.title);
    } else if (elem.hasDashChildren) {
      elem.children.forEach(child => {
        if (child.data && child.data !== '') {
          configuredDashboards.push(child.title);
        }
      });
    }
  });
  return (
    <div className={classes.msList}>
      <div className={classes.msListItem}>
        <Typography variant='title'>Review</Typography>
      </div>
      <Scrollbars
        autoHeight
        autoHeightMin={`${minScrollbarSize}px`}
        renderThumbVertical={props => <div className={classes.scrollbar}/>}
        renderThumbHorizontal={props => <div className={classes.noScrollbar}/>}
      >
        <div className={classes.scrollbarChild}>
          <div className={classes.reviewListTitle}>
            <Typography variant='title' className={classes.reviewTitle}>Client Details:</Typography>
          </div>
          <div className={classes.reviewListItem}>
            <Typography variant='subheading'>Client Name: </Typography>
            <Typography variant='body1'>{selectedClient.name}</Typography>
          </div>
          <div className={classes.reviewListItem}>
            <Typography variant='subheading'>Main Contact Email: </Typography>
            <Typography variant='body1'>{selectedClient.mainContactEmail}</Typography>
          </div>
          {enabledMicroservices.length > 0 && <div className={classes.reviewListTitle}>
            <Typography variant='title' className={classes.reviewTitle}>Enabled Microservices:</Typography>
          </div>}
          {enabledMicroservices.map(elem => {
            return (
              <div className={classes.reviewListItem}>
                <Icon color={elem.disabled ? 'error' : 'primary'} classes={{root: classes.iconHome}}>{elem.icon}</Icon>
                <Typography variant='subheading'>{elem.title}</Typography>
              </div>
            );
          })}
          {greyedOutMicroservices.length > 0 && <div className={classes.reviewListTitle}>
            <Typography variant='title' className={classes.reviewTitle}>Demo Microservices:</Typography>
          </div>}
          {greyedOutMicroservices.map(elem => {
            return (
              <div className={classes.reviewListItem}>
                <Icon color={elem.disabled ? 'error' : 'primary'} classes={{root: classes.iconHome}}>{elem.icon}</Icon>
                <Typography variant='subheading'>{elem.title}</Typography>
              </div>
            );
          })}
          {configuredDashboards.length > 0 && <div className={classes.reviewListTitle}>
            <Typography variant='title' className={classes.reviewTitle}>Configured Dashboards:</Typography>
          </div>}
          {configuredDashboards.map(elem => {
            return (
              <div className={classes.reviewListItemNoIcon}>
                <Typography variant='subheading'>{elem}</Typography>
              </div>
            );
          })}
        </div>
      </Scrollbars>
    </div>
  );
};

Review.propTypes = {
  selectedClient: PropTypes.object.isRequired,
  minScrollbarSize: PropTypes.number.isRequired,
  classes: PropTypes.object.isRequired,
};

export default Review;