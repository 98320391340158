import React from 'react';
import PropTypes from 'prop-types';
import Icon from '@material-ui/core/Icon';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import Typography from '@material-ui/core/Typography';
import {withStyles} from '@material-ui/core/styles';
import { Scrollbars } from 'react-custom-scrollbars';

const styles = (theme) => ({
  settingsDiv: {
    display: 'flex',
    justifyContent: 'flex-start',
    flexDirection: 'row',
    alignItems: 'flex-start',
  },
  titleDiv: {
    display: 'flex',
    justifyContent: 'center',
    flexDirection: 'row',
    alignItems: 'center',
  },
  list: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'flex-start',
    height: '100%',
    overflowY: 'auto',
  },
  scrollbarChild: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'flex-start',
    height: '100%',
  },
  listItem: {
    height: theme.sizes.listItemHeight,
    width: '95%',
    whiteSpace: 'nowrap',
  },
  scrollbar: {
    backgroundColor: theme.palette.primary.main,
    opacity: '0.4',
  },
  panelIcon: {
    fontSize: '28px',
  },
});

const ListText = (props) => {
  const {classes, selectedIndex, items, title, dimensions, onListItemSelect, maxHeight, disableGutters} = props;
  let minScrollbarSize = dimensions.listItemHeight * items.length;
  const listHasIcons = items.reduce((acc, item) => {return (acc || (item.icon && item.icon !== ''))}, false);
  if (minScrollbarSize > maxHeight) {
    minScrollbarSize = maxHeight;
  }
  return (
    <List component='nav' className={classes.list}>
      <div className={classes.titleDiv}>
        <Typography variant='subheading'>{title}</Typography>
      </div>
      <Scrollbars
        autoHeight
        autoHeightMin={`${minScrollbarSize}px`}
        renderThumbVertical={props => <div {...props} className={classes.scrollbar}/>}
        renderThumbHorizontal={props => <div {...props} className={classes.scrollbar}/>}
      >
        <div className={classes.scrollbarChild}>
          {items.map((item, index) => {
            return (
              <ListItem
                button
                onClick={() => onListItemSelect(index)}
                className={classes.listItem}
                selected={selectedIndex === index}
                divider
              >
                {item.icon && <ListItemIcon>
                  <Icon color={item.disabled ? 'error' : 'primary'} classes={{root: classes.panelIcon}}>
                    {item.icon}
                  </Icon>
                </ListItemIcon>}
                <ListItemText
                  primaryTypographyProps={{color: item.disabled ? 'error' : 'primary'}}
                  primary={item.primary}
                  secondary={item.secondary}
                  disableGutters={disableGutters}
                  inset={listHasIcons && !!!item.icon}
                />
              </ListItem>
            );
          })}
        </div>
      </Scrollbars>
    </List>
  );
};

ListText.defaultProps = {
  selectedIndex: null,
  items: [],
  title: 'Select an item',
  dimensions: {listItemHeight: 60},
  parentHeight: 200,
  onListItemSelect: () => {},
};

ListText.propTypes = {
  selectedIndex: PropTypes.number,
  items: PropTypes.array.isRequired,
  title: PropTypes.string.isRequired,
  dimensions: PropTypes.object.isRequired,
  onListItemSelect: PropTypes.func,
};

export default withStyles(styles)(ListText);
